import { Component, HostBinding, OnInit } from '@angular/core';
import { MediaQueryControlService } from 'src/app/shared/services/media-query-control.service';
import { FlexDirection } from 'src/app/shared/types/flex-direction.type';
import { ImpactCardService } from 'src/app/services/impact/impact-card.service';
import { Metric, MetricRate } from 'src/app/services/impact/impact';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';
@Component({
    selector: 'app-impact-section',
    templateUrl: './impact-section.component.html',
    styleUrls: ['./impact-section.component.css'],
})
export class ImpactSectionComponent implements OnInit {
    clientName:string;
    impactCards: MetricRate[];
    overallImpact: number;
    cardIndex: number = 0;
    isDrawerOpen: boolean = false;
    isChartOpen: boolean = false;
    headerFlexStyle: string;
    loaded:boolean = false;
    moreThan4:boolean=false

    defaultStylesInPixels = {
        cardMinWidth: 260,
        gap: 20,
    }

    @HostBinding("style.--number-of-cards") currentNumberOfCards: number = 4;
    @HostBinding("style.--impact-section-width") impactSectionWidth: number|string;
    @HostBinding("style.--impact-section-gap") impactSectionGap: number|string = `${this.defaultStylesInPixels.gap}px`;

    constructor(public mediaQueryControl: MediaQueryControlService,
        private impactCardService: ImpactCardService,
        private route: ActivatedRoute,
        private gaService: GoogleAnalyticsService) {
        this.clientName = this.route.snapshot.paramMap.get('clientName') || ''
        this.getImpactSectionWidth();
        this.getHeaderFlexStyle();
    }

    ngOnInit(): void {
        this.getImpactCards();
        this.getOverallImpact();
    }

    getImpactCards(): void {
        this.impactCardService.getImpactCards(this.clientName).subscribe(data => {
            this.loaded = true;
            if(data){
                this.impactCards = data.metricList.slice(0,4);

                if(this.impactCards.length>2) this.moreThan4=true
            }else{
                console.log('Impact cards', 'nodata');
            }
        });
    }

    getOverallImpact():void{
        this.impactCardService.getOverallImpact(this.clientName).subscribe(data => {
            this.overallImpact = data;
        })
    }

    openDrawer(): void {
        this.isDrawerOpen = true;
    }
    closeDrawer(): void {
        this.isDrawerOpen = false;
    }
    onCardClick(index: number, pkMetricID:number) {
        this.isChartOpen =
            !this.isChartOpen || this.cardIndex !== index ? true : false;
        this.cardIndex = index;
        this.gaService.contentEventEmitter('impactCard', 'Metric_'+pkMetricID);
    }

    getImpactMetric(cardIndex: number) : Metric | any{
        if(this.impactCards && this.impactCards[cardIndex]){
            return this.impactCards[cardIndex].metric;
        }
    }

    private getImpactSectionWidth() {
        const width =
            this.defaultStylesInPixels.cardMinWidth * this.currentNumberOfCards
            +
            this.defaultStylesInPixels.gap * (this.currentNumberOfCards - 1);
        this.impactSectionWidth = `${width}px`;
    }

    private getHeaderFlexStyle() {
        this.mediaQueryControl.layout.subscribe(layout => {
            const isMobile = layout == "mobile";
            this.headerFlexStyle = isMobile
                ? "flex-column-reverse"
                : "flex-center space-between";
        });
    }
}
