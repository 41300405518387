import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-info-drawer',
    templateUrl: './info-drawer.component.html',
    styleUrls: ['./info-drawer.component.css'],
    animations: [
        trigger('overlayState', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('.3s ease', style({ opacity: 0.5 })),
            ]),
            transition(':leave', [
                style({ opacity: 0.5 }),
                animate('.3s ease', style({ opacity: 0 })),
            ]),
        ]),
        trigger('drawerState', [
            transition(':enter', [
                style({ right: 'calc(-33vw - 80px)' }),
                animate('.3s ease-out', style({ right: 0 })),
            ]),
            transition(':leave', [
                style({ right: 0 }),
                animate('.3s ease-in', style({ right: 'calc(-33vw - 80px)' })),
            ]),
        ]),
    ],
})
export class InfoDrawerComponent implements OnInit {
    @Input() title: string = '';
    @Input() open: boolean = false;
    @Output() closeDrawer = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    handleCloseDrawer(): void {
        this.closeDrawer.emit();
    }
}
