<app-main-layout>
    <app-nav-menu [activeIndex]="0"></app-nav-menu>
    <div class="overview__impact">
        <app-impact-section></app-impact-section>
    </div>
    <div class="overview__alert">
        <app-top-alert-section></app-top-alert-section>
    </div>
    <div class="overview__alertlist">
        <app-alert-section></app-alert-section>
    </div>
</app-main-layout>
