import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { of, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { UserDataService } from '../user-data/user-data.service';



@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private tokenUrl = `https://${environment.b2cDomain}.b2clogin.com/${environment.b2cDomain}.onmicrosoft.com/oauth2/v2.0/token`;

  httpOptions = {
    params: { p: 'b2c_1a_signup_signin' },
    headers: new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
  }
  constructor(private http: HttpClient,
    private authSvc: MsalService) {
  }

  async getAuthenticationFlow(): Promise<void> {
    if (this.checkTokenValid()) {
      this.addTokenOnSessionStorage();
      return;
    }

    await this.refreshToken();
  }

  private checkTokenValid(): boolean {
    for (var key in sessionStorage) {
      if (key.includes(environment.sessionStorageInfo) && JSON.parse(sessionStorage.getItem(key)!).authorityType != null) {
        var tokenClaims = JSON.parse(sessionStorage.getItem(key)!).idTokenClaims;
        return tokenClaims.exp > Math.floor(Date.now() / 1000);
      }
    }
    return false;
  }

  private addTokenOnSessionStorage() {
    for (var key in sessionStorage) {
      if (key.includes(environment.sessionStorageTokenKey)) {
        var token = JSON.parse(sessionStorage.getItem(key)!).secret;
        var userId = JSON.parse(sessionStorage.getItem(key)!).homeAccountId;

        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('token', token);
        return;
      }
    }
  }

  private async refreshToken() {
    for (var key in sessionStorage) {
      if (key.includes(environment.sessionRefreshTokenInfo)) {
        var refreshToken = JSON.parse(sessionStorage.getItem(key)!).secret;
        var data = await this.getRefreshToken(refreshToken);
        this.adjustToken(data);
      }
    }
  }

  private adjustToken(data: any) {
    for (var key in sessionStorage) {
      if (key.includes(environment.sessionStorageTokenKey)) {
        var sessionStorageToken = JSON.parse(sessionStorage.getItem(key)!);
        sessionStorageToken.secret = data.id_token;

        sessionStorage.setItem(key, JSON.stringify(sessionStorageToken));
      }

      if (key.includes(environment.sessionStorageInfo) && JSON.parse(sessionStorage.getItem(key)!).authorityType != null) {
        var sessionStorageInfo = JSON.parse(sessionStorage.getItem(key)!);
        sessionStorageInfo.idTokenClaims.exp += data.id_token_expires_in;

        sessionStorage.setItem(key, JSON.stringify(sessionStorageInfo));
      }

      if (key.includes(environment.sessionRefreshTokenInfo)) {
        var sessionRefreshTokenInfo = JSON.parse(sessionStorage.getItem(key)!);
        sessionRefreshTokenInfo.secret += data.refresh_token;

        sessionStorage.setItem(key, JSON.stringify(sessionRefreshTokenInfo));
      }
    }

    this.addTokenOnSessionStorage();
  }

  getRefreshToken(refreshToken: any): Promise<any> {
    const body = new HttpParams()
      .set('client_id', refreshToken.clientId)
      .set('scope', 'openid')
      .set('refresh_token', refreshToken)
      .set('grant_type', 'refresh_token');

    return this.http.post(`${this.tokenUrl}`, body, this.httpOptions).toPromise<any>();
  }


  logout() {
    var date = new Date();
    localStorage.setItem('logout', date.toString());
    this.authSvc.logout();
  }

  logoutOtherTabs() {
    this.authSvc.logout();
    localStorage.clear();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a AlertService message with the MessageService */
  private log(message: string) {
    console.log(`AlertService: ${message}`);
  }

}

