<section class="details-section">
    <h1 class="alert-text" *ngIf="textInformation">Our analysis indicates a problem with your {{textInformation.categoryName}} {{textInformation.typeOfAlert}}. We project a loss of <span style="color:#ec4a36">{{textInformation.monthlyImpact | datFormat:"monthlyImpactUSD"}}</span> in good transactions next month if this continues.</h1>
    <div class="details-section-tooltip">
        <button class="flex-center" (click)="openDrawer()">
            Transaction profile&nbsp;<img src="assets/icons/question.svg" alt="" />
        </button>
    </div>
    <app-loading-animation *ngIf="!profileCard"></app-loading-animation>
    <div *ngIf="profileCard" class="alert-card-row" >
        <ng-container *ngIf="profileCard">
            <app-alert-details-card *ngIf="profileCard.rate"
             [profileCardData]="profileCard.rate"
             [title]="this.textInformation.typeOfAlert">
            </app-alert-details-card>
            <app-alert-details-card *ngIf="profileCard.expectedRate"
            [profileCardData]="profileCard.expectedRate"
            [title]="this.textInformation.typeOfAlert.includes('approval') ? 'Your expected approval rate' : 'Your expected rate'">
            </app-alert-details-card>
            <app-alert-details-card *ngIf="profileCard.comparisonTransactionRate"
            [profileCardData]="profileCard.comparisonTransactionRate"
            [title]="'vs transaction profile'">
            </app-alert-details-card>
        </ng-container>

    </div>
    <app-alert-details-approval-rate-section></app-alert-details-approval-rate-section>
    <div class="details-section__padding">
        <app-alert-details-recommendations
            [recommendations]="recommendations"
            [alertId]="alertId"
            tooltipText="We'll provide the best suggestions to increase performance based off the alert shown"
        ></app-alert-details-recommendations>
    </div>


    <app-alert-details-back-to-top-buttom></app-alert-details-back-to-top-buttom>
</section>
<section class="details-decline-section">
    <app-alert-details-decline-section></app-alert-details-decline-section>
</section>

<app-info-drawer *ngIf="profileCard"
    [open]="isDrawerOpen"
    (closeDrawer)="closeDrawer()"
    title="Alert Information"
    >{{profileCard.scenarioDescription}}
</app-info-drawer>
