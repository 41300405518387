import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BodyAlert } from 'src/app/services/alerts/alert';
import { Filter, FiltersData } from 'src/app/services/filters/filters-data';
import { FiltersDataService } from 'src/app/services/filters/filters-data.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';
import { FADE_SLIDE_IN_AND_OUT } from 'src/app/shared/animations/fade-slide-in-and-out.animation';
import { ToggleEvent } from 'src/app/shared/toggle-button/toggle-button.component';
import { IOverlayComponent } from '../interfaces/overlay-component.interface';
import { FilterPanelService } from '../services/filter-panel.service';
import { OverlayService } from '../services/overlay.service';

@Component({
  selector: 'app-filters-panel',
  templateUrl: './filters-panel.component.html',
  styleUrls: ['./filters-panel.component.css'],
  animations: [
    FADE_SLIDE_IN_AND_OUT
  ]
})
export class FiltersPanelComponent implements OnInit, IOverlayComponent {

  filterData:FiltersData|any;
  filterSelection: number[];
  incomingData: any|
                    { filterData:FiltersData,
                      incomingSchema:BodyAlert };
  filterSchema:BodyAlert;

  animationDone = new BehaviorSubject<boolean>(false);
  isVisible: boolean;

  currentActiveFilter: {[key: string]: number[]} = {
    typeAlert:[],
    network:[],
    currency:[],
    issuer:[],
    cardType:[],
    date:[],
    status:[]
  };

  constructor (private overlayService: OverlayService,
               private fPanelService: FilterPanelService,
               private gaService: GoogleAnalyticsService){
    this.isVisible = true;
  }

  ngOnInit(): void {
    this.getFilterData();

  }

  //INI - Sync & preproc
  getFilterData(){
    this.fPanelService.filtersDataInfo.subscribe(
      (data)=>{
        this.incomingData = data;
        this.filterData = this.incomingData.filterData;
        this.filterSchema = this.incomingData.incomingSchema;
        this.currentActiveFilter['typeAlert'] = this.filterSchema.MetricTypes?<number[]>this.filterSchema.MetricTypes:<number[]>[];
        this.currentActiveFilter['network'] = this.filterSchema.NetworkIDs?<number[]>this.filterSchema.NetworkIDs:<number[]>[];
        this.currentActiveFilter['currency'] = this.filterSchema.CurrencyIDs?<number[]>this.filterSchema.CurrencyIDs:<number[]>[];
        this.currentActiveFilter['issuer'] = this.filterSchema.IssuerIDs?<number[]>this.filterSchema.IssuerIDs:<number[]>[];
        this.currentActiveFilter['cardType'] = this.filterSchema.CardTypeIDs?<number[]>this.filterSchema.CardTypeIDs:<number[]>[];
        this.currentActiveFilter['date'] = this.filterSchema.MetricPeriod?<number[]>[this.filterSchema.MetricPeriod]:[];
        this.currentActiveFilter['status'] = this.filterSchema.Status?<number[]>[this.filterSchema.Status]:[];
      }
    );
  }

  sendInputData(){
    //this.fPanelService.sendInputData(this.currentActiveFilter);
    this.fPanelService.sendInputData(this.filterSchema);

  }
  //END - Sync & preproc

  public notifyAnimationDone(event: any) {
    if(event.toState == "void") {
      this.animationDone.next(true);
    }
  }

  public onFilterToggled(event: ToggleEvent, section: string) {
    if (event.itemId == -1) {
      this.currentActiveFilter[section] = [];
    }
    else if (this.currentActiveFilter[section].includes(event.itemId)) {
      const indexToRemove = this.currentActiveFilter[section].indexOf(event.itemId);
      this.currentActiveFilter[section].splice(indexToRemove, 1);
    } else {
      if(section == 'status' || section == 'date'){
        this.currentActiveFilter[section] = [event.itemId];
      }else{
        this.currentActiveFilter[section].push(event.itemId);
      }
    }
    this.addToSchema(section);
  }

  private addToSchema(section:string){
    switch(section){
      case "typeAlert": this.filterSchema.MetricTypes=this.currentActiveFilter[section];
                        break;
      case "network":  this.filterSchema.NetworkIDs=this.currentActiveFilter[section];
                        break;
      case "currency":  this.filterSchema.CurrencyIDs=this.currentActiveFilter[section];
                        break;
      case "issuer":    this.filterSchema.IssuerIDs=this.currentActiveFilter[section];
                        break;
      case "cardType":  this.filterSchema.CardTypeIDs=this.currentActiveFilter[section];
                        break;
      case "date":      if(this.currentActiveFilter[section].length > 0){
                          this.filterSchema.MetricPeriod = this.currentActiveFilter[section][0]
                        }else{
                          this.filterSchema.MetricPeriod = null;
                        }
                        break;
      case "status":    if(this.currentActiveFilter[section].length > 0){
                          this.filterSchema.Status = this.currentActiveFilter[section][0]
                        }else{
                          this.filterSchema.Status = null;
                        }
                        break;
    }
  }

  public checkActive(id:number, section:string):boolean{
    if(this.currentActiveFilter[section]){
      if(this.currentActiveFilter[section].length > 0){
        return this.currentActiveFilter[section].includes(id);
      }else if(id == -1){
          return true;
      }else{
        return false;
      }
    }return false;
  }

  public cancelFilter() {
    this.dismissOverlay();
  }

  public applyFilters() {
    this.sendInputData();
    this.gaService.contentEventEmitter('filterPanel', 'Mobile_Apply');
    this.dismissOverlay();
  }

  private dismissOverlay() {
    this.overlayService.close();
  }



}
