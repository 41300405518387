import { Component, OnInit } from '@angular/core';
import { MediaQueryControlService } from 'src/app/shared/services/media-query-control.service';
import { FlexDirection } from 'src/app/shared/types/flex-direction.type';

import { AlertsService } from 'src/app/services/alerts/alerts.service';
import { Alert } from 'src/app/services/alerts/alert';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-top-alert-section',
  templateUrl: './top-alert-section.component.html',
  styleUrls: ['./top-alert-section.component.css']
})
export class TopAlertSectionComponent implements OnInit {
  topAlerts: Alert[];
  sectionFlexDirection: FlexDirection;
  loaded: boolean = false;
    clientName: string;

  constructor(private alertService: AlertsService,
    public mediaQueryControl: MediaQueryControlService,
    private route: ActivatedRoute) {
    this.getSectionFlexDirectionAccordingToLayout();
  }

  async ngOnInit(): Promise<void> {
    this.clientName = this.route.snapshot.paramMap.get('clientName') || ''
    this.getTopAlerts();
  }


  getTopAlerts(): void {
    this.alertService.getTopAlerts(this.clientName).subscribe(data => {
      this.loaded = true;
      this.topAlerts = data.alerts;
    }
    );

  }


  private getSectionFlexDirectionAccordingToLayout() {
    this.mediaQueryControl.layout.subscribe(layout => {
      console.log("INCOMING LAYOUT!", layout);
      const direction = layout == "mobile"
        ? "column"
        : "row";
      this.sectionFlexDirection = {
        'flex-direction': direction
      };
    });
  }
}
