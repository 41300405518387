import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { profileCard, recommendation, textInformation, tooltipInformation } from 'src/app/services/alert-details/alert-detail';
import { ActivatedRoute } from '@angular/router';
import { AlertDetailService } from 'src/app/services/alert-details/alert-detail.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';

@Component({
    selector: 'app-alert-details-section',
    templateUrl: './alert-details-section.component.html',
    styleUrls: ['./alert-details-section.component.css']
})
export class AlertDetailsSectionComponent implements OnInit {

    defaultStylesInPixels = {
        cardMinWidth: 260,
        gap: 20,
    }

    profileCard: profileCard;
    @Input() textInformation: textInformation;
    tooltipInformation: tooltipInformation;
    recommendations: recommendation[];
    alertId: string | null = this.route.snapshot.paramMap.get('id');

    isDrawerOpen: boolean = false;
    isChartOpen: boolean = false;

    @HostBinding("style.--number-of-cards") currentNumberOfCards: number = 3;
    @HostBinding("style.--impact-section-width") impactSectionWidth: number | string;
    @HostBinding("style.--impact-section-gap") impactSectionGap: number | string = `${this.defaultStylesInPixels.gap}px`;

    constructor(private alertDetailService: AlertDetailService, 
                private route: ActivatedRoute,
                private gaService: GoogleAnalyticsService) {
    }

    ngOnInit(): void {
        this.getProfileCards();
        this.getRecommendation();
    }

    getProfileCards(): void {
        this.alertDetailService.getProfileCards(this.route.snapshot.paramMap.get('id')).subscribe((data: any) =>
        {
            this.profileCard = data;
        })
           
        
    }

    getTooltipInformation(): void {
        this.tooltipInformation = this.alertDetailService.getTooltipInformation(this.route.snapshot.paramMap.get('id'));
    }

    getRecommendation(): void {
        this.alertDetailService.getRecommendations(this.route.snapshot.paramMap.get('id')).subscribe((data: any) =>
            this.recommendations = data.recommendations
        )
    }

    openDrawer(): void {
        this.isDrawerOpen = true;
        this.gaService.contentEventEmitter('detailDrawer', 'AlertInformation');
    }
    closeDrawer(): void {
        this.isDrawerOpen = false;
    }


}
