import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AlertPageComponent } from './pages/alert-page/alert-page.component';
import { NoAccessPageComponent } from './pages/no-access-page/no-access-page.component';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';
import { OverviewPageComponent } from './pages/overview-page/overview-page.component';
import { NoAccessResolver } from './services/guard/no-access.resolver';

const appRoutes: Routes = [
    {
        path: '',
        children: [
            { path: '', redirectTo: '/overview', pathMatch: 'full' },
            { path: 'overview', component: OverviewPageComponent, resolve: { childData: NoAccessResolver } },
            { path: ':clientName/overview', component: OverviewPageComponent, resolve: { childData: NoAccessResolver } },
            { path: 'notifications', component: NotificationsPageComponent, resolve: { childData: NoAccessResolver } },
            { path: ':clientName/notifications', component: NotificationsPageComponent, resolve: { childData: NoAccessResolver } },
            { path: 'alert/:id', component: AlertPageComponent, resolve: { childData: NoAccessResolver } },
            { path: ':clientName/alert/:id', component: AlertPageComponent, resolve: { childData: NoAccessResolver } }
        ],
        canActivate: [MsalGuard]
    },
    {
        path: 'no-access',
        component: NoAccessPageComponent
    },
    {
        path: '',
        component: MsalGuard
    },
    {
        path: '**',
        component: NoAccessPageComponent
    }

];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {
        initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
    })],
    exports: [RouterModule],
    providers: [NoAccessResolver]
})
export class AppRoutingModule { }
