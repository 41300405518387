import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChartData } from './ChartData';

@Injectable({
  providedIn: 'root'
})
export class ImpactChartService {
  //ToDo: build chain with parameters
  private urlMetricCharts = `${environment.apiURL}/metriccharts`;
  
  httpOptions = {
          headers: new HttpHeaders(
                        { 'Content-Type': 'application/json',
                          'Authorization': sessionStorage.getItem('token')!,
                          'Ocp-Apim-Subscription-Key': environment.subscriptionKey }
          )
          //INI - ToDo: remove again after API change
          ,
          params: { code: environment.authenticationCodeAPIM },
          //END - ToDo
  }

  constructor( private http: HttpClient ) { 

  }

  getChartApiData(clientName:string|any, impactType:number|any){
    return this.http.get<any>(`${this.urlMetricCharts}/${clientName}/${impactType}`,  this.httpOptions)
      .pipe(
        tap(_ => console.log('ImpactChartService', 'fetched impactChartData')),
        catchError(this.handleError<any>('getChartApiData', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log('ImpactChartService',`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  
}
