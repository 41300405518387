import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { IOverlayComponent } from '../interfaces/overlay-component.interface';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  private componentRef: ComponentRef<IOverlayComponent>;
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay) { }

  public open(componentType: ComponentType<IOverlayComponent>) {
    const overlayRef = this.overlay.create(this.getOverlayConfig());
    const userProfilePortal = new ComponentPortal(componentType);
    const componentRef = overlayRef.attach(userProfilePortal);
    overlayRef.backdropClick().subscribe(() => {
      this.close();
    });
    
    this.overlayRef = overlayRef;
    this.componentRef = componentRef;
  }

  public close() {
    this.componentRef.instance.animationDone.subscribe(finished => {
      if (finished) {
        this.componentRef.destroy();
        this.overlayRef.dispose();
      }
    });
    this.componentRef.instance.isVisible = false;
  }

  private getOverlayConfig() {
    return {
      height: '75vh',
      width: '100vw',
      hasBackdrop: true
    } as OverlayConfig;
  }
}
