<a class="top-alert_routerLink" [routerLink]="['/'+currentClient,'alert',topAlert.pK_AlertID]">
    <div class="top-alert">
        <div class="top-alert__header flex-center space-between">
            <div class="top-alert__header__alert flex-center">
                <img *ngIf="topAlert.showThresholdAlert" src="assets/icons/alert.svg" alt="alert icon" />
                <span>{{topAlert.insertedDate | datFormat:"impactDate" }}</span>
            </div>
            <div class="top-alert__header__info flex-center">
                <img src="{{imageSRC}}"  (error)="imageSRC = 'assets/logos/no-image.png'" alt="credit card logo" />
            </div>
        </div>
        <div class="top-alert__content">
            <div
                class="top-alert__content__projected-loss flex-center space-between"
            >
                <h3>{{ topAlert.monthlyImpact  | datFormat:"topMonthlyImpactUSD"  }}</h3>
                <div class="error">
                    <span>{{ topAlert.vsCluster | datFormat:"vsCluster" }}</span>
                    <img src="assets/icons/arrow-down.svg" alt="down arrow icon" />
                </div>
            </div>
            <div
                class="top-alert__content__transaction-profile flex-center space-between"
            >
                <span>Projected loss in good transactions</span>
                <span>vs transaction profile</span>
            </div>
        </div>
        <div class="top-alert__bottom">
            <span>View details</span>
            <img src="assets/icons/arrow-right.svg" alt="right arrow icon" />
        </div>
    </div>
</a>
