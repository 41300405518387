import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from 'src/app/services/account/account.service';
import { UserData } from 'src/app/services/user-data/user-data';
import { UserDataService } from 'src/app/services/user-data/user-data.service';
import { FADE_SLIDE_IN_AND_OUT } from 'src/app/shared/animations/fade-slide-in-and-out.animation';
import { IOverlayComponent } from '../interfaces/overlay-component.interface';
import { OverlayService } from '../services/overlay.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css'],
  animations: [
    FADE_SLIDE_IN_AND_OUT
  ]
})
export class MobileMenuComponent implements IOverlayComponent {

  activeIndex: number = 0;
  isVisible: boolean;
  animationDone = new BehaviorSubject<boolean>(false);

  currentUserData: UserData;
  lastRefreshed: string;
  currentClient: string;
    logoSRC: string;

  constructor(private accountService: AccountService,
    public userDataService: UserDataService,
    private mobileMenuOverlay: OverlayService,
    private router: Router) {
    this.isVisible = true;
  }

  ngOnInit(): void {
    this.getUserData();
    this.currentClient =window.location.href.split('/')[3]
    this.logoSRC= 'assets/logos/Clients/'+ this.currentClient + ".png"
  }

  getUserData(): void {
    this.userDataService.getUserData().subscribe(data => {
      this.currentUserData = data;
      this.getLastRefreshed();
    });
  }

  public notifyAnimationDone(event: any) {
    if (event.toState == "void") {
      this.animationDone.next(true);
    }
  }

  public closeMobileMenu() {
    this.mobileMenuOverlay.close();
  }

  logout() {
    this.accountService.logout();
  }

  getLastRefreshed() {
    for (var i = 0; i < this.currentUserData.clients.length; i++) {
      if (this.currentClient == this.currentUserData.clients[i].clientName) {
        this.lastRefreshed = this.currentUserData.clients[i].lastRefreshedDate
      }
    }
  }

  onOptionsSelected(value: string) {

    if (window.location.href.includes('alert')){
        this.router.navigate([value, 'overview'])
     }else {
        var newUrl = this.router.url.replace(this.currentClient, value)
        this.router.navigateByUrl(newUrl).then(() => {
            window.location.reload();
          });
     }
  }
}
