<nav>
    <div class="nav-menu">
        <ng-container *ngIf="(mediaQueryControl.layout | async) != 'mobile'">
            <div
                [class.active]="activeIndex === 0"
                class="nav-menu__box"
                [routerLink]="['/'+currentClient,'overview']"
            >
                <svg width="26" height="28" viewBox="0 0 26 28">
                    <path
                        d="M25.085 10.3165L14.5699 0.786358C13.6601 -0.0570411 12.3101 -0.0570411 11.399 0.786358L0.883861 10.3165C0.664387 10.5349 0.538086 10.8169 0.538086 11.1294V25.5026C0.538086 26.7842 1.60479 27.8461 2.89222 27.8461H8.29198C9.57941 27.8461 10.6461 26.7842 10.6461 25.5026V18.9409C10.6461 18.847 10.7086 18.7847 10.8031 18.7847H15.1974C15.2919 18.7847 15.3544 18.847 15.3544 18.9409V25.5026C15.3544 26.7842 16.4211 27.8461 17.7085 27.8461H23.107C24.3945 27.8461 25.4612 26.7842 25.4612 25.5026V11.1294C25.4612 10.7852 25.3361 10.5044 25.0847 10.3165H25.085ZM15.1976 16.5976H10.8032C9.51581 16.5976 8.44911 17.6595 8.44911 18.9411V25.5028C8.44911 25.5968 8.38658 25.659 8.29217 25.659H2.89367C2.79925 25.659 2.73672 25.5968 2.73672 25.5028V11.5982L12.8755 2.41241C12.938 2.35016 13.0325 2.35016 13.095 2.41241L23.2338 11.6301V25.5968C23.2019 25.6286 23.1712 25.6591 23.1087 25.6591H17.709C17.6145 25.6591 17.552 25.5968 17.552 25.5028V18.9411C17.552 17.6595 16.4853 16.5977 15.1979 16.5977L15.1976 16.5976Z"
                    />
                </svg>
                <span>Overview</span>
            </div>
            <div class="nav-menu__separator"></div>
            <div
                [class.active]="activeIndex === 1"
                class="nav-menu__box"
                [routerLink]="['/'+currentClient,'notifications']"
            >
                <svg width="24" height="27" viewBox="0 0 24 27">
                    <path
                        d="M12 0.538574C13.7928 0.538574 15.2574 2.00785 15.3407 3.85082C18.4643 5.225 20.569 8.43184 20.569 12.0619V17.0712C21.9699 17.1833 23.0769 18.4029 23.0769 19.8892C23.0769 21.4485 21.8572 22.7156 20.3551 22.7156H3.64495C2.14113 22.7156 0.923096 21.4501 0.923096 19.8892C0.923096 18.4029 2.02848 17.1833 3.43106 17.0712V12.0619C3.43106 8.43027 5.53574 5.22491 8.65933 3.85082C8.7426 2.00785 10.2088 0.538574 12 0.538574ZM10.0194 5.64096C7.36125 6.52764 5.52099 9.10949 5.52099 12.0618V17.2798C5.52099 18.3601 4.67848 19.2366 3.64164 19.2366C3.2955 19.2366 3.01302 19.5305 3.01302 19.8889C3.01302 20.249 3.2955 20.5411 3.64491 20.5411H20.355C20.7045 20.5411 20.9869 20.2473 20.9869 19.8889C20.9869 19.5305 20.7045 19.2366 20.3583 19.2366C19.3198 19.2366 18.479 18.3618 18.479 17.2798V12.0618C18.479 9.10962 16.6388 6.5276 13.9805 5.64096L13.2539 5.39976V4.01372C13.2539 3.29693 12.6922 2.71259 11.9999 2.71259C11.3093 2.71259 10.746 3.29693 10.746 4.01372V5.39976L10.0194 5.64096ZM8.02908 22.7153H10.119C10.119 23.7956 10.9616 24.6721 12 24.6721C13.0385 24.6721 13.881 23.7956 13.881 22.7153H15.971C15.971 24.9965 14.1929 26.8463 12 26.8463C9.80718 26.8463 8.02908 24.9965 8.02908 22.7153Z"
                    />
                </svg>

                <span>Notifications</span>
            </div>
            <div class="nav-menu__separator" style="display:none"></div>
            <div
                [class.active]="activeIndex === 2"
                class="nav-menu__box"
                style="display:none"
                [routerLink]="['/report']"
            >
                <svg width="24" height="28" viewBox="0 0 24 28">
                    <path
                        d="M23.16 6.82248C23.1065 6.68467 23.0264 6.5581 22.9237 6.45125L17.2987 0.82625C17.1919 0.723592 17.0653 0.643438 16.9275 0.59C16.7925 0.532342 16.6477 0.501408 16.5 0.5H4.125C2.26171 0.5 0.75 2.01171 0.75 3.875V24.125C0.75 25.9883 2.26171 27.5 4.125 27.5H19.875C21.7383 27.5 23.25 25.9883 23.25 24.125V7.25C23.2486 7.10234 23.2177 6.9575 23.16 6.8225L23.16 6.82248ZM17.625 4.33625L19.4138 6.12502H17.625V4.33625ZM21 24.1251C21 24.4232 20.8819 24.7101 20.6709 24.921C20.46 25.132 20.1731 25.2501 19.875 25.2501H4.125C3.50342 25.2501 3 24.7467 3 24.1251V3.87508C3 3.2535 3.50342 2.75008 4.125 2.75008H15.375V6.12508C15.375 6.72131 15.6127 7.29367 16.0345 7.71556C16.4564 8.13744 17.0287 8.37508 17.625 8.37508H21L21 24.1251Z"
                    />
                </svg>

                <span>Report Builder</span>
            </div>
        </ng-container>
        <div class="nav-menu__buttons">
            <button (click)="openModal('shared-modal')">
                <svg width="13" height="15" viewBox="0 0 13 15">
                    <path
                        d="M9.625 9.5C8.99609 9.5 8.42188 9.71875 7.98438 10.0742L5.16797 8.32422C5.22266 8.16016 5.22266 7.96875 5.22266 7.75C5.22266 7.55859 5.22266 7.36719 5.16797 7.20312L7.98438 5.45312C8.42188 5.80859 8.99609 6 9.625 6C11.0742 6 12.25 4.85156 12.25 3.375C12.25 1.92578 11.0742 0.75 9.625 0.75C8.14844 0.75 7 1.92578 7 3.375C7 3.59375 7 3.78516 7.05469 3.94922L4.23828 5.69922C3.80078 5.34375 3.22656 5.125 2.625 5.125C1.14844 5.125 0 6.30078 0 7.75C0 9.22656 1.14844 10.375 2.625 10.375C3.22656 10.375 3.80078 10.1836 4.23828 9.82812L7.05469 11.5781C7 11.7422 7 11.9336 7 12.1523V12.125C7 13.6016 8.14844 14.75 9.625 14.75C11.0742 14.75 12.25 13.6016 12.25 12.125C12.25 10.6758 11.0742 9.5 9.625 9.5Z"
                        fill="#212121"
                    />
                </svg>
            </button>
            <button style="display:none">
                <svg width="13" height="16" viewBox="0 0 13 16">
                    <path
                        d="M7.4375 4.85938V0.875H1.57812C1.16797 0.875 0.875 1.19727 0.875 1.57812V15.1719C0.875 15.582 1.16797 15.875 1.57812 15.875H11.4219C11.8027 15.875 12.125 15.582 12.125 15.1719V5.5625H8.14062C7.73047 5.5625 7.4375 5.26953 7.4375 4.85938ZM9.66406 11.0703L6.85156 13.8828C6.64648 14.0586 6.32422 14.0586 6.11914 13.8828L3.30664 11.0703C3.01367 10.7773 3.21875 10.25 3.62891 10.25H5.5625V7.90625C5.5625 7.67188 5.76758 7.4375 6.03125 7.4375H6.96875C7.20312 7.4375 7.4375 7.67188 7.4375 7.90625V10.25H9.3418C9.75195 10.25 9.95703 10.7773 9.66406 11.0703ZM11.9199 3.95117L9.04883 1.08008C8.90234 0.962891 8.72656 0.875 8.55078 0.875H8.375V4.625H12.125V4.44922C12.125 4.27344 12.0371 4.09766 11.9199 3.95117Z"
                        fill="#212121"
                    />
                </svg>
            </button>
        </div>
    </div>
</nav>

<app-modal id="shared-modal">
    <p class="title">Share this page</p>
    <div class="email-container">
        <p class="email">
            Send this information to:

        </p>
        <input class="e-input" type="email" [(ngModel)]="email" placeholder="{{placeholderText}}" ngModel [email]="true" />
    </div>

    <div class="button-container">
        <button class="send-button" (click)="sendModal('shared-modal');">Confirm</button>
        <button class="dismiss-button" (click)="closeModal('shared-modal');">Dismiss</button>
    </div>
</app-modal>

<app-modal id="shared-response">
    <p class="title">Response</p>
    <div class="email-container">
        <p class="e-info">
            Info was sent to the <span>{{responseEmail}}</span> destination.
        </p>
    </div>
    <div class="button-container">
        <button class="dismiss-button" (click)="closeModal('shared-response');">OK</button>
    </div>
</app-modal>

<app-modal id="shared-error">
    <p class="title">Error</p>
    <div class="email-container">
        <p class="e-info">
            Info was not sent to the <span>{{responseEmail}}</span> destination.
            Error information: <span>{{sharingError}}</span>
        </p>
    </div>
    <div class="button-container">
        <a href="mailto:quantafi_test@cmspi.com?cc=quantafi_test@cmspi.com&bcc=quantafi_test@cmspi.com&subject=Contact%20us%20-%20Sharing%20Error">
            <button class="send-button" (click)="closeModal('shared-error');">Contact CMSPI</button>
        </a>
        <button class="dismiss-button" (click)="closeModal('shared-error');">OK</button>
    </div>
</app-modal>
