import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    LayoutModule,
    OverlayModule
  ],
  exports: [
    LayoutModule,
    OverlayModule
  ]
})
export class AngularLibrariesModule { }
