import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Filter } from 'src/app/services/filters/filters-data';
import { MediaQueryControlService } from 'src/app/shared/services/media-query-control.service';

@Component({
  selector: 'app-alert-section',
  templateUrl: './alert-section.component.html',
  styleUrls: ['./alert-section.component.css']
})
export class AlertSectionComponent implements OnInit {

  statusFilterData:Filter[]=[
    { "id": -1, "description": "All" },
    { "id": 1,  "description": "Negative" },
    { "id": 2,  "description": "Positive" }
  ];
  filterStatus:number|null;


  @HostBinding("style.--alert-section-header-margin") alertSectionHeaderMargin: string;
    currentClient:string | null
  constructor(
      mediaQueryControl: MediaQueryControlService,
      private route: ActivatedRoute,
    ) {

    mediaQueryControl.layout.subscribe(layout => {
      const isMobile = layout == 'mobile';
      this.alertSectionHeaderMargin = isMobile
        ? "50px 0px 20px 0px"
        : "50px 0px 0px 0px"
    });
  }

  updateFilters(filterValues:number[]){
    if(filterValues){
      if(filterValues.length>0) this.filterStatus=filterValues[0];
      else this.filterStatus = null;
    }
  }
    

  ngOnInit(): void {
    this.currentClient=this.route.snapshot.paramMap.get('clientName')
  }

}
