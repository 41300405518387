import { trigger, transition, style, animate } from "@angular/animations";

export const FADE_SLIDE_IN_AND_OUT = 
    trigger('fadeSlideInOut', [
        transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-75vh)' }),
        animate('600ms cubic-bezier(.28,.91,.84,.98)', style({ opacity: 1, transform: 'translateY(0)' })),
        ]),
        transition(':leave', [
        animate('600ms cubic-bezier(.28,.91,.84,.98)', style({ opacity: 0, transform: 'translateY(-75vh)' })),
        ]),
    ]);