import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { absSplitMetric, kpiAbsSplit, kpiCard, kpiSplit, profileCard, recommendation, textInformation, tooltipInformation } from './alert-detail';

@Injectable({
  providedIn: 'root'
})
export class AlertDetailService {

  private apiUrl = environment.apiURL;
  private profileCardsUrl = 'getspecificalertdetails';
  private alertsDetailsChartUrl = 'alertdetailchart';
  private recommendationByAlertUrl = 'getrecommendationsbyalert'
  private kpiCardsUrl = 'getkpicards'
  private textInformationUrl = 'gettextinformation'
  private declineChartDetailsUrl = 'getdeclinechartdetails'
  private declineChartResponseDetailsUrl = 'GetDeclineChartResponseDetails'
  //private alertId = '67557282-513e-6ed5-86ea-7dd74e5b9a81'// only for dev porposes

  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')!,
        'Ocp-Apim-Subscription-Key': environment.subscriptionKey
      }
    )
    //INI - ToDo: remove again after API change
    ,
    params: { code: environment.authenticationCodeAPIM },
    //END - ToDo
  }

  constructor(private http: HttpClient) {
  }

  getProfileCards(alertId: string | null): Observable<profileCard> {
    return this.http.get<any>(`${this.apiUrl}/${this.profileCardsUrl}/${alertId}`, this.httpOptions)
      .pipe(
        tap(_ => console.log('AlertDetailService', 'Profile cards')),
        catchError(this.handleError<any>('getAlertDetailsChart', []))
      );
  }

  getTextInformation(alertId: string | null): Observable<textInformation> {
    return this.http.get<any>(`${this.apiUrl}/${this.textInformationUrl}/${alertId}`, this.httpOptions)
      .pipe(
        tap(_ => console.log('AlertDetailService', 'Text Information')),
        catchError(this.handleError<any>('getTextInformation', []))
      );
  }

  getTooltipInformation(alertId: any): tooltipInformation {
    var mock: tooltipInformation = { title: 'Test', scenarioDescription: 'Scenario description test' };
    return mock
  }

  getRecommendations(alertId: string | null): Observable<recommendation[]> {
    return this.http.get<any>(`${this.apiUrl}/${this.recommendationByAlertUrl}/${alertId}`, this.httpOptions)
      .pipe(
        tap(_ => console.log('AlertDetailService', 'Recommendations')),
        catchError(this.handleError<any>('getRecommendations', []))
      );

  }

  getAlertDetailsChart(alertId: string | null): any {
    return this.http.get<any>(`${this.apiUrl}/${this.alertsDetailsChartUrl}/${alertId}`, this.httpOptions)
      .pipe(
        tap(_ => console.log('AlertDetailService', 'Alerts Details charts')),
        catchError(this.handleError<any>('getAlertDetailsChart', []))
      );
  }

  getKpiCards(alertId: string | null): Observable<kpiCard[]> {
    return this.http.get<any>(`${this.apiUrl}/${this.kpiCardsUrl}/${alertId}`, this.httpOptions)
      .pipe(
        tap(_ => console.log('AlertDetailService', 'Alerts KPI cards')),
        catchError(this.handleError<any>('getKpiCards', []))
      );
  }

  getDeclineChartDetails(alertId: string | null): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${this.declineChartDetailsUrl}/${alertId}`, this.httpOptions)
      .pipe(
        tap(_ => console.log('AlertDetailService', 'Alerts Decline Chart Details')),
        catchError(this.handleError<any>('getDeclineChartDetails', []))
      );
  }

  getDeclineChartResponseDetails(alertId: string | null): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${this.declineChartResponseDetailsUrl}/${alertId}/`, this.httpOptions)
      .pipe(
        tap(_ => console.log('AlertDetailService', 'Alerts Decline Chart Details')),
        catchError(this.handleError<any>('getDeclineChartDetails', []))
      );
  }

  getKpiSplit(alertId: string | null) {
    var mock: kpiSplit;
    var spLabels = ['Call', 'Information error', 'Insufficient funds'];
    var spClient = [43, 25, 16];
    var spProfile = [40, 21, 15];
    mock = {
      metricTypes: spLabels,
      clientSplit: spClient,
      profileSplit: spProfile
    }
    return mock;
  }

  getKpiAbsSplit(alertId: string | null) {
    var mock: kpiAbsSplit;
    var w1: absSplitMetric = {
      metricType: ['Call', 'Information error', 'Insufficient funds'],
      data: [12, 31, 29]
    };
    var w2: absSplitMetric = {
      metricType: ['Call', 'Information error', 'Insufficient funds'],
      data: [8, 21, 29]
    };
    var w3: absSplitMetric = {
      metricType: ['Call', 'Information error', 'Insufficient funds'],
      data: [12, 29, 34]
    };
    var w4: absSplitMetric = {
      metricType: ['Call', 'Information error', 'Insufficient funds'],
      data: [24, 62, 58]
    };
    mock = {
      dateLabels: ['19/07/21', '26/07/21', '02/08/21', '09/08/21'],
      data: [w1, w2, w3, w4]
    }
    return mock;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a AlertService message with the MessageService */
  private log(message: string) {
    console.log(`AlertService: ${message}`);
  }

}
