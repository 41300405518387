export interface Alert {
    pK_AlertID: number
    metricType: string
    network: string
    currency: string
    issuer: string
    cardType: string
    insertedDate: string
    benchmarkingEndDate: string
    monthlyImpact: number
    vsCluster: number
    pinned: boolean
    showThresholdAlert: boolean
}

export interface SortView {
    SortType: string
    SortOrder: string
}

export enum SortTypes {
    type = "type",
    category = "category",
    date = "date",
    monthlyImpact = "monthly impact",
    vsCluster = "vs cluster"
}

export enum SortOrders {
    asc = "ASC",
    desc = "DESC"
}

export class BodyAlert {
    constructor(
        public Page: number | null = 1,
        public SortView: SortView | null = null,
        public MetricTypes: number[] | null = null,
        public NetworkIDs: number[] | null = null,
        public CurrencyIDs: number[] | null = null,
        public IssuerIDs: number[] | null = null,
        public CardTypeIDs: number[] | null = null,
        public MetricPeriod: number | null = null,
        public Status: number | null = null
    ) { }
}

export class BodyPinned {
    constructor(
        public SortView: SortView | null = null,
        public MetricTypes: number[] | null = null,
        public NetworkIDs: number[] | null = null,
        public CurrencyIDs: number[] | null = null,
        public IssuerIDs: number[] | null = null,
        public CardTypeIDs: number[] | null = null,
        public MetricPeriod: number | null = null,
        public Status: number | null = null
    ) { }
}

export class BodyPin {
    constructor(
        public ClientName: string | null = '',
        public Pin: number | null = null,
        public Unpin: number | null = null,
    ) { }
}