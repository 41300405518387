<div class="profile__picture">
    <input
        class="dropdown"
        type="checkbox"
        [id]="dropdownId"
        name="dropdown"
        #dropdownCheckbox
    />
    <label class="for-dropdown" [for]="dropdownId">
        {{ prefixedText }}
    </label>
    <div class="dropdown-container__dropdown-section">
        <a
            *ngFor="let value of values"
            (click)="handleValueClick(value)"
            [class.active]="selectedValue === value"
            >{{ value }}</a
        >
    </div>
</div>
