<section class="impact-section">
    <div class="impact-section__title" [ngClass]="headerFlexStyle">
        <h4>Overall monthly impact <span>${{overallImpact | datFormat:"monthlyImpactUSD"}}</span></h4>
        <button class="flex-center" (click)="openDrawer()">
            Transaction profile <img src="assets/icons/question.svg" alt="" />
        </button>
    </div>
    <app-loading-animation *ngIf="!impactCards && !loaded"></app-loading-animation>
    <div class="no-data" *ngIf="impactCards==null && loaded"><h1>No data</h1></div>
    <div *ngIf="impactCards" class="impact-section__impact-row flex-center">

        <ng-container *ngFor="let impactCard of impactCards; index as i">
            <app-impact-card
                (click)="onCardClick(i, impactCard.metric.pK_MetricID)"
                [impactCard]="impactCard"
                [position]="i"
                [cardIndex]="cardIndex"
                [show]="isChartOpen"
            ></app-impact-card>
            <div style="width: 100%;" *ngIf="moreThan4 && i==1 && (mediaQueryControl.layout | async) == 'tablet'">
                <app-impact-chart-section
                    [show]="isChartOpen"
                    [cardIndex]="cardIndex"
                    [metricInfo]="getImpactMetric(cardIndex)"
                ></app-impact-chart-section>
            </div>

        </ng-container>
    </div>
    <app-impact-chart-section *ngIf="!moreThan4 || (mediaQueryControl.layout | async) != 'tablet'"
        [show]="isChartOpen"
        [cardIndex]="cardIndex"
        [metricInfo]="getImpactMetric(cardIndex)"
    ></app-impact-chart-section>
</section>

<app-info-drawer
    [open]="isDrawerOpen"
    (closeDrawer)="closeDrawer()"
    title="How do we generate your transaction profile?"
    >CMSPI has used its vast transaction database and unique machine learning
    algorithms to create a clone of your transaction profile. This means we can
    compare your performance at a transaction level and identify opportunities
    to improve performance.
</app-info-drawer>
