import { Component, HostBinding, OnInit } from '@angular/core';
import { MediaQueryControlService } from 'src/app/shared/services/media-query-control.service';
import { FlexDirection } from 'src/app/shared/types/flex-direction.type';
import { AlertDetailService } from 'src/app/services/alert-details/alert-detail.service';
import { kpiCard } from 'src/app/services/alert-details/alert-detail';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-alert-details-decline-section',
  templateUrl: './alert-details-decline-section.component.html',
  styleUrls: ['./alert-details-decline-section.component.css']
})
export class AlertDetailsDeclineSectionComponent implements OnInit {
  overallImpact: number = 2.1;
  cardIndex: number = 0;
  isDrawerOpen: boolean = false;
  isChartOpen: boolean = false;
  headerFlexStyle: string;
  kpiCards:kpiCard[];
  qCards:number;
  loaded:boolean = false;
  responsesIds:any[]=[]
  declineChartDetails:any
  declineChartResponseDetails:any


  defaultStylesInPixels = {
      cardMinWidth: 260,
      gap: 20,
  }

  @HostBinding("style.--number-of-cards") currentNumberOfCards: number = 4;
  @HostBinding("style.--impact-section-width") impactSectionWidth: number|string;
  @HostBinding("style.--impact-section-gap") impactSectionGap: number|string = `${this.defaultStylesInPixels.gap}px`;

  constructor(private mediaQueryControl: MediaQueryControlService, 
              private alertDetailService:AlertDetailService, 
              private route: ActivatedRoute,
              private gaService: GoogleAnalyticsService) {
      this.getImpactSectionWidth();
      this.getHeaderFlexStyle();
  }

  ngOnInit(): void {
      this.getKpiCards();

  }

  openDrawer(): void {
      this.isDrawerOpen = true;
  }
  closeDrawer(): void {
      this.isDrawerOpen = false;
  }
  onCardClick(index: number, cardMsg:string) {
      this.isChartOpen =
          !this.isChartOpen || this.cardIndex !== index ? true : false;
      this.cardIndex = index;
      this.gaService.contentEventEmitter('detailsDeclineCard', cardMsg.replace(' ', '_'));
  }

  getKpiCards(){
    this.alertDetailService.getKpiCards(this.route.snapshot.paramMap.get('id')).subscribe((data: any) =>{
        this.getDeclineChartDetails();
        this.getDeclineChartResponseDetails();
        this.loaded = true;
        this.kpiCards = data.cards
        if(data!=null){
            if (data.cards.length<=3){
                this.qCards=3
            } else {
                this.qCards=data.cards.length
            }
        }



    })
  }

  getDeclineChartDetails(){
    this.alertDetailService.getDeclineChartDetails(this.route.snapshot.paramMap.get('id')).subscribe((data: any) =>{
        this.declineChartDetails=data
    })
  }
  getDeclineChartResponseDetails(){
    this.alertDetailService.getDeclineChartResponseDetails(this.route.snapshot.paramMap.get('id')).subscribe((data: any) =>{
        this.declineChartResponseDetails=data

    })
  }

  private getImpactSectionWidth() {
      const width =
          this.defaultStylesInPixels.cardMinWidth * this.currentNumberOfCards
          +
          this.defaultStylesInPixels.gap * (this.currentNumberOfCards - 1);
      this.impactSectionWidth = `${width}px`;
  }

  private getHeaderFlexStyle() {
      this.mediaQueryControl.layout.subscribe(layout => {
          const isMobile = layout == "mobile";
          this.headerFlexStyle = isMobile
              ? "flex-column-reverse"
              : "flex-center space-between";
      });
  }
}
