<header>
    <ng-container
        *ngIf="
            (mediaQueryControl.layout | async) == 'desktop';
            else tabletHeader
        "
    >
        <div class="logo flex-center">
            <img class="logo__picture" src="assets/quantafi-logo.png" alt="CMSPI logo" />
            <div class="logo__separator"></div>
            <img class="logo__client" src="{{logoSRC}}"  (error)="logoSRC = 'assets/logos/no-image.png'" alt="John Lewis logo" />
        </div>
        <div *ngIf="currentUserData">
            <h3 class="title flex-center">Welcome, {{currentUserData.name}}!</h3>
        </div>
        <div class="profile flex-center">
            <div class="profile__select-client" *ngIf="currentUserData && currentUserData.clients.length > 1">
                <select class="minimal" #mySelect (change)='onOptionsSelected(mySelect.value)'>
                    <option *ngFor="let client of currentUserData.clients"
                    [value]="client.clientName"
                    [selected]="client.clientName == currentClient">{{client.clientName}}</option>
                </select>
            </div>
            <div class="profile__refresh-message">
                <span *ngIf="currentUserData">Last refreshed: {{lastRefreshed | datFormat:"refreshDate"}} </span>
            </div>
            <div>
                <app-profile-button *ngIf="currentUserData"
                    class="profile__picture"
                    dropdownId="profile-button"
                    prefixedText={{currentUserData.name.charAt(0)}}{{currentUserData.surname.charAt(0)}}
                ></app-profile-button>
            </div>
        </div>


    </ng-container>

    <ng-template #tabletHeader>
        <ng-container *ngIf="
        (mediaQueryControl.layout | async) == 'tablet';
        else mobileHeader
    ">
            <!-- Tablet -->
        <div class="tablet-header">
            <div class="tablet-header_row">
                <div class="logo flex-center ">
                    <img class="logo__picture" src="assets/quantafi-logo.png" alt="CMSPI logo" />
                    <div class="logo__separator"></div>
                    <img class="logo__client" src="{{logoSRC}}"  (error)="logoSRC = 'assets/logos/no-image.png'" alt="John Lewis logo" />
                </div>
                <div class="profile flex-center">

                    <div class="profile__refresh-message">
                        <span *ngIf="currentUserData">Last refreshed: {{lastRefreshed | datFormat:"refreshDate"}} </span>
                    </div>
                    <div>
                        <app-profile-button *ngIf="currentUserData"
                            class="profile__picture"
                            dropdownId="profile-button"
                            prefixedText={{currentUserData.name.charAt(0)}}{{currentUserData.surname.charAt(0)}}
                        ></app-profile-button>
                    </div>
                </div>
            </div>
            <div class="tablet-header_row center">
                <div *ngIf="currentUserData">
                    <h3 class="title flex-center">Welcome, {{currentUserData.name}}!</h3>
                </div>
            </div>
            <div class="tablet-header_row center">
                <div class="profile__select-client center" *ngIf="currentUserData && currentUserData.clients.length > 1">
                    <select class="minimal" #mySelect (change)='onOptionsSelected(mySelect.value)'>
                        <option *ngFor="let client of currentUserData.clients"
                        [value]="client.clientName"
                        [selected]="client.clientName == currentClient">{{client.clientName}}</option>
                    </select>
                </div>

            </div>
        </div>
        </ng-container>

        <ng-template #mobileHeader>
            <img class="logo__picture" src="assets/quantafi-logo.png" alt="CMSPI logo" />
            <div class="logo__separator"></div>
            <img class="logo__client" src="{{logoSRC}}"  (error)="logoSRC = 'assets/logos/no-image.png'" alt="John Lewis logo" />
            <div class="header_button-container">
                <button class="header__open-menu-button" (click)="openMobileMenu()">
                    <img src="assets/icons/menu.svg" alt="sort icon" />
                </button>
            </div>
        </ng-template>
    </ng-template>


</header>
