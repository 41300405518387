import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-alert-details-card',
  templateUrl: './alert-details-card.component.html',
  styleUrls: ['./alert-details-card.component.css']
})
export class AlertDetailsCardComponent implements OnInit {
    @Input() profileCardData: number;
    @Input() title: string;

    constructor() {}

    ngOnInit(): void {}
}
