import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account/account.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.css']
})
export class NotificationsPageComponent implements OnInit {
    constructor(
        private accountService: AccountService,
        public userDataService: UserDataService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    async ngOnInit(): Promise<void> {
        await this.accountService.getAuthenticationFlow();
        if (!this.route.snapshot.paramMap.get('clientName')){
            this.getUserData()

        }
    }

    getUserData(): void {
        this.userDataService.getUserData().subscribe(data => {
            this.router.navigate([data.clients[0].clientName+'/notifications']);
        });
    }
}
