<ng-container *ngIf="(mediaQueryControl.layout | async) == 'desktop'">
    <div class="alert-notifications-header flex-center" *ngIf="dataFilters">
        <app-alert-filter-dropdown *ngIf="dataFilters.typeAlert"
            dropdownId="type-of-alert"
            prefixedText="Type of alert:"
            [filterData]="dataFilters.typeAlert"
            (filterSelect)="updateFilters('typeAlert', $event)"
        ></app-alert-filter-dropdown>
        <app-alert-filter-dropdown *ngIf="dataFilters.network"
            dropdownId="network"
            prefixedText="Network:"
            [filterData]="dataFilters.network"
            (filterSelect)="updateFilters('network', $event)"
        ></app-alert-filter-dropdown>
        <app-alert-filter-dropdown *ngIf="dataFilters.currency"
            dropdownId="currency"
            prefixedText="Currency:"
            [filterData]="dataFilters.currency"
            (filterSelect)="updateFilters('currency', $event)"
        ></app-alert-filter-dropdown>
        <app-alert-filter-dropdown *ngIf="dataFilters.issuer"
            dropdownId="issuer"
            prefixedText="Issuer:"
            [filterData]="dataFilters.issuer"
            (filterSelect)="updateFilters('issuer', $event)"
        ></app-alert-filter-dropdown>
        <app-alert-filter-dropdown *ngIf="dataFilters.cardType"
            dropdownId="cardType"
            prefixedText="Card Type:"
            [filterData]="dataFilters.cardType"
            (filterSelect)="updateFilters('cardType', $event)"
        ></app-alert-filter-dropdown>
        <app-alert-filter-dropdown *ngIf="dataFilters.date"
            dropdownId="date"
            prefixedText="Date:"
            [filterData]="dataFilters.date"
            (filterSelect)="updateFilters('date', $event)"
        ></app-alert-filter-dropdown>
        <app-alert-filter-dropdown *ngIf="dataFilters.status"
            dropdownId="status"
            prefixedText="Status:"
            [filterData]="dataFilters.status"
            (filterSelect)="updateFilters('status', $event)"
        ></app-alert-filter-dropdown>
    </div>
</ng-container>
<ng-container *ngIf="(mediaQueryControl.layout | async) == 'mobile'">
    <button class="filters__button" (click)="openFiltersPanelOverlay()">
        Filters
    </button>
</ng-container>
<app-alert-list
    [filterSchema]="filterSchema"
></app-alert-list>
