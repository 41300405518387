export const lineConfig: any = {
    type: 'line',
    data: {
        datasets: [
            {
                backgroundColor: '#210d26',
                borderColor: '#210d26',
                borderDash: [4, 5],
                data: [85, 78, 80, 83, 78, 83, 78, 70, 65, 68],
            },
            {
                backgroundColor: '#004F5F',
                borderColor: function(context:any) {
                    const chart = context.chart;
                    const {ctx, chartArea} = chart;

                    if (!chartArea) {
                      // This case happens on initial chart load
                      return;
                    }
                    return getGradient(ctx, chartArea);
                },
                data: [81, 79, 83, 85, 82, 80, 79, 65, 43, 22],

            },
        ],
    },
    options: {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
            point: {
                pointStyle: 'line',
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    padding: { bottom: 20 },
                    text: 'Gross approval rate',
                },
                min: 0,
                max: 100,
                grid: {
                    borderWidth: 1,
                    borderColor: 'black',
                },
                ticks: {
                    count: 5,
                    stepSize: 20,
                    callback: function (value: any, index: any, ticks: any) {
                        return value.toString().substring(0, 5) + '%';
                    },
                },
            },
            x: {
                title: {
                    display: true,
                    padding: { top: 10 },
                    text: '2020',
                },
                labels: [
                    'Jan 20',
                    'Feb 20',
                    'Mar 20',
                    'Apr 20',
                    'May 20',
                    'Jun 20',
                    'Jul 20',
                    'Aug 20',
                    'Sep 20',
                    'Oct 20',
                ],
                grid: {
                    display: false,
                    borderWidth: 1,
                    borderColor: 'black',
                },
                offset: true,
            },
        },
    },
};


let width:any, height:any, gradient:any;
function getGradient(ctx: any, chartArea: any) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      height = chartHeight;
      var dataA= lineConfig.data.datasets[0].data
      var dataB=lineConfig.data.datasets[1].data
      var diff = dataB.map(function (num:any, idx: any) {
        return num - dataA[idx];
      });
      //ctx.createLinearGradient(X0, Y0, X1, Y1); Para hacer lo que buscamos deberiamos dejar y0 e y1 como 0 y las X usar chartArea.left y chartArea.right
      //gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);

      //TODO improve logic of red gradient (Maybe done)
      var divisor=1/dataA.length
      var currentTram=0
      var difference=-15
      var keepRed=false
      for(var i=0;i<dataA.length;i++){
          if(diff[i]<difference){
            gradient.addColorStop(currentTram,'#FF6384')
            keepRed=true
          } else if (keepRed) {
            gradient.addColorStop(currentTram-divisor/2,'#FF6384')
            gradient.addColorStop(currentTram,'#00aab9')
            keepRed=false
          } else {
            gradient.addColorStop(currentTram,'#00aab9')

          }
          currentTram+=divisor;

      }
      if(diff[diff.length-1]<difference){
        gradient.addColorStop(1,'#FF6384')
      } else {
        gradient.addColorStop(1,'#00aab9')

      }


    //   gradient.addColorStop(0.5, '#00aab9');
    // gradient.addColorStop(0.6, '#FF6384');
    // gradient.addColorStop(0.7, '#00aab9');
        //gradient.addColorStop(1, '#00aab9');

    }

    return gradient;
}

