<div class="chart-container">
    <canvas id="chart-canvas-approval-rate"></canvas>
</div>

<div class="chart-legend">
    <div class="chart-legend__stats">
        <div *ngIf="alertsDetailChart[chartPeriod-1].impactValueData/1000000 > 1" class="chart-legend__stats__number">-${{alertsDetailChart[chartPeriod-1].impactValueData/1000000 | number : '1.2-2'}}m/{{periodLabel}}</div>
        <div *ngIf="alertsDetailChart[chartPeriod-1].impactValueData/1000000 <= 1" class="chart-legend__stats__number">-${{alertsDetailChart[chartPeriod-1].impactValueData/1000 | number : '1.3-3'}}k/{{periodLabel}}</div>
        <div class="chart-legend__stats__label">Impact vs projection</div>
    </div>
    <div class="chart-legend__labels">
        <div class="label">
            <div [ngSwitch]="alertsDetailChart[chartPeriod-1].type">
                <div *ngSwitchCase="'1'">Gross approval rate</div>
                <div *ngSwitchCase="'2'">Net approval rate</div>
                <div *ngSwitchCase="'3'">Chargeback rate</div>
                <div *ngSwitchCase="'4'">Fraud rate</div>
            </div>
            <div class="line-a"></div>
        </div>

        <div class="label">
            <div [ngSwitch]="alertsDetailChart[chartPeriod-1].type">
                <div *ngSwitchCase="'1'">Drop in gross approval rate</div>
                <div *ngSwitchCase="'2'">Drop in net approval rate</div>
                <div *ngSwitchCase="'3'">Drop in chargeback rate</div>
                <div *ngSwitchCase="'4'">Drop in fraud rate</div>
            </div>
            <div class="line-b"></div>
        </div>

        <div class="label"><div>
            Expected
        </div>
        <div class="line-c"></div>
    </div>


    </div>
</div>
