import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';
import { UserData } from 'src/app/services/user-data/user-data';
import { UserDataService } from 'src/app/services/user-data/user-data.service';
import { MediaQueryControlService } from 'src/app/shared/services/media-query-control.service';
import { MobileMenuComponent } from '../mobile-menu/mobile-menu.component';
import { OverlayService } from '../services/overlay.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @HostBinding("style.--header-padding") headerPadding: number | string;
  @HostBinding("style.--title-color") titleColor: string;
  @HostBinding("style.--title-justification") titleJustification: string;
  @HostBinding("style.--logo-size") logoSize: string;

  logoSRC:string
  currentUserData: UserData;
  lastRefreshed: string;
  currentClient = this.route.snapshot.paramMap.get('clientName') || ''

  constructor(
    public userDataService: UserDataService,
    public mediaQueryControl: MediaQueryControlService,
    public overlayService: OverlayService,
    private route: ActivatedRoute,
    private router: Router,
    private gaService: GoogleAnalyticsService) {
    this.getHeaderStylesAccordingToLayout(mediaQueryControl);
  }

  ngOnInit(): void {
    this.getUserData();
    this.logoSRC= 'assets/logos/Clients/'+ this.currentClient + ".png"
    this.route.params.subscribe(params => {
        this.currentClient = params['clientName'];
    });

  }

  getUserData(): void {
    this.userDataService.getUserData().subscribe(data => {
      this.currentUserData = data;
      this.getLastRefreshed();
    });
  }

  public openMobileMenu() {
    this.overlayService.open(MobileMenuComponent);
    this.gaService.contentEventEmitter('mobileMenu', 'Access');
  }

  private getHeaderStylesAccordingToLayout(mediaQueryControl: MediaQueryControlService) {
    mediaQueryControl.layout.subscribe(layout => {
      const isMobile = layout == "mobile";
      this.headerPadding = isMobile
        ? "20px"
        : "32px 40px";

      this.titleColor = isMobile
        ? "#011d9b"
        : "#052b4e";

      this.logoSize = isMobile
        ? "100px"
        : "120px";

      this.titleJustification = isMobile
        ? "flex-start"
        : "center";
    });
  }

  getLastRefreshed() {
    for(var i = 0; i < this.currentUserData.clients.length; i++){
      if (this.route.snapshot.paramMap.get('clientName') == this.currentUserData.clients[i].clientName) {
        this.lastRefreshed = this.currentUserData.clients[i].lastRefreshedDate
      }
    }
  }

  onOptionsSelected(value: string) {


     if (window.location.href.includes('alert')){
        this.router.navigate([value, 'overview'])
     }else {
        var newUrl = this.router.url.replace(this.currentClient, value)
        this.router.navigateByUrl(newUrl).then(() => {
            window.location.reload();
          });
     }

  }
}
