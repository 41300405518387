<section class="top-alerts-section">
    <h4>Top alerts</h4>
    <app-loading-animation *ngIf="!topAlerts && !loaded"></app-loading-animation>
    <app-loading-animation *ngIf="!topAlerts && !loaded"></app-loading-animation>
    <div class="no-data" *ngIf="topAlerts==null && loaded"><h1>No data</h1></div>
    <div
        *ngIf="topAlerts"
        class="top-alerts-section__top-alerts-row"

    >
        <ng-container *ngFor="let topAlert of topAlerts">
            <app-top-alert-card
                [topAlert]="topAlert"
            >
            </app-top-alert-card>
        </ng-container>
    </div>
</section>
