import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FiltersPanelComponent } from 'src/app/features/filters-panel/filters-panel.component';
import { FilterPanelService } from 'src/app/features/services/filter-panel.service';
import { OverlayService } from 'src/app/features/services/overlay.service';
import { BodyAlert } from 'src/app/services/alerts/alert';
import { Filter, FiltersData } from 'src/app/services/filters/filters-data';
import { FiltersDataService } from 'src/app/services/filters/filters-data.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';
import { MediaQueryControlService } from 'src/app/shared/services/media-query-control.service';

@Component({
  selector: 'app-alert-notifications',
  templateUrl: './alert-notifications.component.html',
  styleUrls: ['./alert-notifications.component.css']
})
export class AlertNotificationsComponent implements OnInit {

  //Filters data
  dataFilters:FiltersData|any;
  filterSchema:BodyAlert;
  selectedFilters:BodyAlert;
  resetFilter: Filter = { id:-1, description:'All'};
  private clientName:string;

  constructor(
    private filterService:FiltersDataService,
    public mediaQueryControl: MediaQueryControlService,
    private overlayService: OverlayService,
    private fPanelService: FilterPanelService,
    private route: ActivatedRoute,
    private gaService: GoogleAnalyticsService
    ) { }

  ngOnInit(): void {
    this.selectedFilters = new BodyAlert();
    this.clientName = this.route.snapshot.paramMap.get('clientName') || '';
    this.getFilters();
    // this.getSyncFPanel();
  }

  getFilters():void{
    this.filterService.getFilters(this.clientName).subscribe(data => {
      this.dataFilters = data;
      if(this.dataFilters.cardType) this.dataFilters.cardType.unshift(this.resetFilter);
      if(this.dataFilters.currency) this.dataFilters.currency.unshift(this.resetFilter);
      if(this.dataFilters.date) this.dataFilters.date.unshift(this.resetFilter);
      if(this.dataFilters.issuer) this.dataFilters.issuer.unshift(this.resetFilter);
      if(this.dataFilters.network) this.dataFilters.network.unshift(this.resetFilter);
      if(this.dataFilters.status) this.dataFilters.status.unshift(this.resetFilter);
      if(this.dataFilters.typeAlert) this.dataFilters.typeAlert.unshift(this.resetFilter); //Pending from DB
    })
  }

  updateFilters(filterType:string, filterValues:number[]){
    var inputValue = filterValues.length>0?filterValues:null;
    switch(filterType){
      case "typeAlert": this.selectedFilters.MetricTypes=inputValue;
                        break;
      case "network":  this.selectedFilters.NetworkIDs=inputValue;
                        break;
      case "currency":  this.selectedFilters.CurrencyIDs=inputValue;
                        break;
      case "issuer":    this.selectedFilters.IssuerIDs=inputValue;
                        break;
      case "cardType":  this.selectedFilters.CardTypeIDs=inputValue;
                        break;
      case "date":      if(filterValues.length > 0){
                          this.selectedFilters.MetricPeriod = filterValues[0]
                        }else{
                          this.selectedFilters.MetricPeriod = null;
                        }
                        break;
      case "status":    if(filterValues.length > 0){
                          this.selectedFilters.Status = filterValues[0]
                        }else{
                          this.selectedFilters.Status = null;
                        }
                        break;
    }
    this.filterSchema = this.copySchema(this.selectedFilters);
  }

  private copySchema(oldSchema:BodyAlert):BodyAlert{
    var newSchema:BodyAlert = new BodyAlert();
    newSchema.CardTypeIDs = oldSchema.CardTypeIDs;
    newSchema.CurrencyIDs = oldSchema.CurrencyIDs;
    newSchema.IssuerIDs = oldSchema.IssuerIDs;
    newSchema.MetricPeriod = oldSchema.MetricPeriod;
    newSchema.MetricTypes = oldSchema.MetricTypes;
    newSchema.NetworkIDs = oldSchema.NetworkIDs;
    newSchema.Status = oldSchema.Status;
    return newSchema;
  }

  openFiltersPanelOverlay() {
    var filterPanelData:
      { filterData:FiltersData, incomingSchema:BodyAlert} =
      { filterData:this.dataFilters, incomingSchema:this.selectedFilters}
    this.fPanelService.sendFilterData(filterPanelData);
    this.getSyncFPanel();
    this.overlayService.open(FiltersPanelComponent);
    this.gaService.contentEventEmitter('filterPanel', 'Mobile');
  }
  
  getSyncFPanel(){
    this.fPanelService.inputDataInfo.subscribe(
      (data)=>{
        this.mobileInputUpdate(data);
      }
      //Add list call/update filters here
    );
  }

  mobileInputUpdate(data:BodyAlert|any){
    var mobileSchema:BodyAlert = this.copySchema(<BodyAlert>data);
    this.filterSchema = mobileSchema;    
  }

}
