import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account/account.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-overview-page',
    templateUrl: './overview-page.component.html',
    styleUrls: ['./overview-page.component.css'],
})
export class OverviewPageComponent implements OnInit {
    constructor(
        private accountService: AccountService,
        public userDataService: UserDataService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    async ngOnInit(): Promise<void> {
        await this.accountService.getAuthenticationFlow();
        if (!this.route.snapshot.paramMap.get('clientName')){
            this.getUserData()

        }
    }

    getUserData(): void {
        this.userDataService.getUserData().subscribe(data => {
            this.router.navigate([data.clients[0].clientName+'/overview']);
        });
    }
}
