<main>
    <div class="header">
        <div class="logo flex-center">
            <img class="logo__picture" src="assets/quantafi-logo.png" alt="CMSPI logo" />
        </div>
    </div>
    <div class="container">
        <div class="no-access">
            <div class="no-access__locked-icon flex-center">
                    <img src="assets/icons/locked.svg" alt="locked icon" />
            </div>
            <div class="no-access__title">
                    Access Restricted
            </div>
            <div class="no-access__content flex-center">
                You don’t have permission to view this page or this page is currently unavailable. Please contact the owner and ask access to this link or sign in to your account to be able to access.
            </div>             
            <div class="no-access__contact">
                <a href="">Request Access</a>
            </div>
        </div>        
    </div>    
</main>
