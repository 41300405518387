<div class="dropdown-container">
    <input
        [id]="dropdownId"
        class="dropdown"
        type="checkbox"
        name="dropdown"
        #dropdownCheckbox
    />
    <label class="for-dropdown" [for]="dropdownId">
        {{ dropdownText }}
        <svg width="16" height="11" viewBox="0 0 16 11">
            <path
                d="M0.4001 2.7779L8.0001 10.3779L15.6001 2.7779L13.4433 0.621582L8.0001 6.0659L2.5569 0.621581L0.4001 2.7779Z"
                fill="#004F5F"
            />
        </svg>
    </label>
    <div class="dropdown-container__dropdown-section">
        <a
            *ngFor="let filter of filterData"
            (click)="handleSelect(filter)"
            [class.active]="checkActive(filter.id)"
            >{{ filter.description }}</a
        >
    </div>
</div>