<div class="alert-section-header flex-center space-between">
    <h4>Recent alerts</h4>
    <app-alert-filter-dropdown
            dropdownId="status"
            prefixedText="Status:"
            [filterData]="statusFilterData"
            (filterSelect)="updateFilters($event)"
    ></app-alert-filter-dropdown>
</div>
<app-alert-list
    [overviewStatus]="filterStatus"
></app-alert-list>
<div class="alert-section-button" [routerLink]="['/'+currentClient,'notifications']">
    <button>View all alert notifications</button>
</div>
