import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MediaQueryControlService } from 'src/app/shared/services/media-query-control.service';
import { Alert, BodyAlert, BodyPin, BodyPinned, SortOrders, SortTypes, SortView } from 'src/app/services/alerts/alert';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from 'src/app/services/alerts/alerts.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';

@Component({
    selector: 'app-alert-list',
    templateUrl: './alert-list.component.html',
    styleUrls: ['./alert-list.component.css'],
})
export class AlertListComponent implements OnInit {

    //NEW FILTER SELECTOR
    @Input() filterSchema: BodyAlert;
    @Input() overviewStatus: number | null;

    clientName: string;
    alerts: Alert[];
    pinnedAlerts: Array<Alert> = [];
    //ng-pagination
    page: number = 1;
    itemsPage: number = 15;
    totalItems: number;
    sortParam: SortView = { SortOrder: "", SortType: "" };
    bodySchema: BodyAlert = new BodyAlert();
    bodyPinned: BodyPinned = new BodyPinned();

    //UserId on Backend from token (header)
    //userId: string | undefined = sessionStorage.getItem('userId')?.replace(environment.userDataReplaceId, '');


    constructor(private alertsService: AlertsService,
        public router: Router,
        public mediaQueryControl: MediaQueryControlService,
        private route: ActivatedRoute,
        private gaService: GoogleAnalyticsService) { }

    ngOnInit(): void {
        this.clientName = this.route.snapshot.paramMap.get('clientName') || '';
        //Moved getAlert inside getPinnedItems to ensure available pinnedData
        this.getPinnedItems();

    }

    ngOnChanges(changes: SimpleChanges) {
        var refreshPage = 1;
        if (!this.clientName) this.clientName = this.route.snapshot.paramMap.get('clientName') || '';
        if (this.filterSchema) {
            this.rebuildSchema();
        }
        //Moved getAlert inside getPinnedItems to ensure available pinnedData
        //this.getAlerts(refreshPage);
        this.page = refreshPage;
        this.getPinnedItems();

    }

    rebuildSchema() {
        this.bodySchema = this.filterSchema;
        this.bodyPinned.CardTypeIDs = this.filterSchema.CardTypeIDs;
        this.bodyPinned.CurrencyIDs = this.filterSchema.CurrencyIDs;
        this.bodyPinned.IssuerIDs = this.filterSchema.IssuerIDs;
        this.bodyPinned.MetricPeriod = this.filterSchema.MetricPeriod;
        this.bodyPinned.MetricTypes = this.filterSchema.MetricTypes;
        this.bodyPinned.NetworkIDs = this.filterSchema.NetworkIDs;
        this.bodyPinned.SortView = this.filterSchema.SortView;
        this.bodyPinned.Status = this.filterSchema.Status;

    }

    getAlerts(reqPage: number): void {
        this.page = reqPage;
        this.bodySchema.Page = reqPage;
        this.bodySchema.SortView = this.sortParam;
        this.alertsService.getAlerts(this.clientName, this.bodySchema).subscribe(data => {
            if (data == null || data == undefined) {
                this.alerts = [];
            } else {
                this.alerts = data.response.alerts;
                this.totalItems = data.total;
                for (var i = 0; i < this.alerts.length; i++) {
                    this.alerts[i].pinned = false
                }
                //If not in notifications page just pick 4 elements
                if (!this.router.url.includes('/notifications')) {
                    this.alerts = this.alerts.slice(0, 4);
                }
                this.checkPinned();
            }

        })

    }

    getPinnedItems(): void {
        if (this.router.url.includes('overview')) {
            if (this.overviewStatus) {
                this.bodySchema.Status = this.overviewStatus;
                this.bodyPinned.Status = this.overviewStatus;
            } else {
                this.bodySchema.Status = null;
                this.bodyPinned.Status = null;
            }
        }
        this.alertsService.getPinnedAlerts(this.clientName, this.bodyPinned).subscribe(data => {
            if (!data) {
                this.pinnedAlerts = [];
            } else {
                this.pinnedAlerts = data;
            }
            //Ensuring available pinnedData before showing alertList
            this.getAlerts(this.page);

        })
    }

    checkPinned(): void {
        for (var i = 0; i < this.alerts.length; i++) {
            for (var j = 0; j < this.pinnedAlerts.length; j++) {
                if (this.pinnedAlerts[j].pK_AlertID == this.alerts[i].pK_AlertID) {
                    this.alerts[i].pinned = true;
                }
            }
        }
    }

    pinAlert(alert: Alert): void {

        if (this.pinnedAlerts.some(e => e.pK_AlertID === alert.pK_AlertID)) {
            this.unpinAlert(alert)
        } else {
            this.pinnedAlerts.push(alert);
            const index = this.alerts.indexOf(alert);
            this.alerts[index].pinned = true;
            var bodyUnPin: BodyPin = new BodyPin;
            bodyUnPin.Pin = alert.pK_AlertID
            this.setPinUnin(bodyUnPin);
        }
    }

    unpinAlert(alert: Alert): void {
        const index = this.pinnedAlerts.indexOf(alert);
        this.pinnedAlerts.splice(index, 1);
        this.setPinnedAs(alert.pK_AlertID, false);
        var bodyUnPin: BodyPin = new BodyPin;
        bodyUnPin.Unpin = alert.pK_AlertID
        this.setPinUnin(bodyUnPin);
        this.checkPinned();
    }



    setPinnedAs(alertId: number, state: boolean): void {
        for (var i = 0; i < this.alerts.length; i++) {
            if (this.alerts[i].pK_AlertID == alertId) {
                this.alerts[i].pinned = state;
            }
        }
    }

    setPinUnin(body: BodyPin): void {
        this.alertsService.setPinnedAlerts(this.clientName, body).subscribe(data => {
            this.getAlerts(this.page);
            this.getPinnedItems();
        })
    }

    sort(id: string) {
        switch (id) {
            case "type": this.sortSwitch(SortTypes.type);
                break;
            case "category": this.sortSwitch(SortTypes.category);
                break;
            case "date": this.sortSwitch(SortTypes.date);
                break;
            case "monthly impact": this.sortSwitch(SortTypes.monthlyImpact);
                break;
            case "vs cluster": this.sortSwitch(SortTypes.vsCluster);
                break;
        }
        this.page = 1;
        this.bodyPinned.SortView = this.sortParam;
        this.getPinnedItems();
        this.gaService.contentEventEmitter('sortList', 'By_'+id.replace(' ', '_'));
    }

    private sortSwitch(sortType: string) {
        if (this.sortParam.SortType != sortType) {
            this.sortParam.SortType = sortType;
            this.sortParam.SortOrder = SortOrders.asc;
        } else {
            if (this.sortParam.SortType == sortType) {
                if (this.sortParam.SortOrder == SortOrders.asc) {
                    this.sortParam.SortOrder = SortOrders.desc;
                } else if (this.sortParam.SortOrder == SortOrders.desc) {
                    this.sortParam.SortType = "";
                    this.sortParam.SortOrder = "";
                }
            }
        }

    }

}
