import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Filter } from 'src/app/services/filters/filters-data';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-alert-filter-dropdown',
  templateUrl: './alert-filter-dropdown.component.html',
  styleUrls: ['./alert-filter-dropdown.component.css']
})
export class AlertFilterDropdownComponent implements OnInit {

  @Input() prefixedText: string = 'Filter:';
  @Input() dropdownId: string = '';
  @ViewChild('dropdownCheckbox') dropdownCheckbox: any;

  @Input() filterData: Filter[];
  @Output() filterSelect = new EventEmitter<number[]>();

  labels:string[] = ['Show all'];
  selectionValues:number[];
  dropdownText: string = '';

  constructor(private gaService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    this.selectionValues = [];
    this.dropdownText = this.prefixedText + ' ' + this.filterData[0].description;
  }

  handleSelect(value:Filter):void{
    //Handle selection array
    if(value.id == -1){
        this.selectionValues = [];
    }else{
        var ind = this.selectionValues.indexOf(value.id)
        if(ind == -1){
            //If dropdown is status or date, only one selector is enabled
            if(this.dropdownId == 'status' || this.dropdownId == 'date'){
                this.selectionValues = [value.id];
            }else{
                this.selectionValues.push(value.id);
            }
        }else{
            this.selectionValues.splice(ind, 1);
        }
    }

    //Handle dropdown text
    if(this.selectionValues.length > 1){
        this.dropdownText = this.prefixedText + ' ' + this.selectionValues.length + ' filters';
    }else{
        this.dropdownText = this.prefixedText + ' ' + value.description;
    }

    //Dropdown close & emit value
    this.dropdownCheckbox.nativeElement.checked = false;
    this.filterSelect.emit(this.selectionValues);

    this.gaService.contentEventEmitter('filterSelect', value.description);

  }

  checkActive(id:number):boolean{
      if(this.selectionValues.length > 0){
          return this.selectionValues.includes(id);
      }else if(id == -1){
          return true;
      }else{
        return false;
      }

  }

}
