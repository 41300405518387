import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterPanelService {

  private filtersData = new BehaviorSubject<Object>({});
  filtersDataInfo = this.filtersData.asObservable();

  inputData = new BehaviorSubject<Object>({});
  inputDataInfo = this.inputData.asObservable();

  constructor() { }

  sendInputData(filtersData:any){
    this.inputData.next(filtersData);
  }

  sendFilterData(data:any){
    this.filtersData.next(data);
  }

}