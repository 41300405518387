import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AccountService } from 'src/app/services/account/account.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';

@Component({
    selector: 'app-profile-button',
    templateUrl: './profile-button.component.html',
    styleUrls: ['./profile-button.component.css']
})
export class ProfileButtonComponent implements OnInit {
    @Input() prefixedText: string = '';
    @Input() dropdownId: string = '';
    @Input() values: string[] = ['Logout'];
    @ViewChild('dropdownCheckbox') dropdownCheckbox: any;
    selectedValue: string = '';
    dropdownText: string = '';

    constructor(private accountService: AccountService,
                private gaService: GoogleAnalyticsService) { }

    ngOnInit(): void {
        this.selectedValue = this.values[0];
        this.dropdownText = this.values[0];
    }

    handleValueClick(value: string): void {
        this.selectedValue = value;
        this.dropdownText = value;

        this.dropdownCheckbox.nativeElement.checked = false;
        switch (value) {
            case 'Logout':
                this.logout();
                break;
            default:
                return;
        }
        this.gaService.contentEventEmitter('profileButton', 'Logout_Menu');
    }

    logout() {
        this.accountService.logout();
    }
}
