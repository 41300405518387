import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaQueryControlService } from 'src/app/shared/services/media-query-control.service';
import { Alert } from 'src/app/services/alerts/alert';
import { logos } from 'src/assets/logos';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';

@Component({
    selector: 'li[app-alert-item]',
    templateUrl: './alert-item.component.html',
    styleUrls: ['./alert-item.component.css'],
})
export class AlertItemComponent implements OnInit {
    @Input() alert: Alert;
    @Output() pinClick = new EventEmitter();
    imageSRC:string;
    desktopNetwork:string;
    desktopIssuer:string;
    tabletNetwork:string;
    tabletIssuer:string;
    currentClient = this.route.snapshot.paramMap.get('clientName') || '';

    constructor(
        public mediaQueryControl: MediaQueryControlService,
        private route: ActivatedRoute,
        private gaService: GoogleAnalyticsService) {}

    ngOnInit(): void {
        if (this.alert.issuer){
            this.imageSRC = 'assets/logos/Issuers/'+ this.alert.issuer + ".png"
            if(this.alert.issuer.length>10) {
                this.tabletIssuer=this.alert.issuer.substring(0, 10) + "...";
            } else {
                this.tabletIssuer=this.alert.issuer
            }
            if(this.alert.issuer.length>20) {
                this.desktopIssuer=this.alert.issuer.substring(0, 20) + "...";
            } else {
                this.desktopIssuer=this.alert.issuer
            }
        } else if (this.alert.network){
            this.imageSRC = 'assets/logos/Networks/'+ this.alert.network + ".png"
           if(this.alert.network.length>10){
                this.tabletNetwork=this.alert.network.substring(0, 10) + "...";
           } else {
            this.tabletNetwork=this.alert.network
           }
           if(this.alert.network.length>20){
            this.desktopNetwork=this.alert.network.substring(0, 20) + "...";
            } else {
                this.desktopNetwork=this.alert.network
            }
        } else if(this.alert.currency){
            this.imageSRC = 'assets/logos/Currencies/'+ this.alert.currency + ".png"
        } else if(this.alert.cardType){
            this.imageSRC = 'assets/logos/CardTypes/'+ this.alert.cardType + ".png"
        }
    }

    handlePinClick(): void {
        this.pinClick.emit();
        this.gaService.contentEventEmitter('pinAlertItem', 'id_'+this.alert.pK_AlertID);
    }

}
