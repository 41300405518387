<div class="impact" [ngClass]="{'active': active }">
    <div class="impact__header flex-center">
        <span>{{ impactCard.metric.metricName }}</span>
        <span class="tooltip" [attr.tooltip-text]="tooltipText">
            <img src="assets/icons/info.svg" alt="info icon" />
        </span>
    </div>
    <div class="impact__content flex-center space-between">
        
        <h2 *ngIf="impactCard.clientMetricRate < 2">{{ impactCard.clientMetricRate | datFormat:"metricRateExt" }}</h2>
        <h2 *ngIf="impactCard.clientMetricRate >= 2">{{ impactCard.clientMetricRate | datFormat:"metricRate" }}</h2>
        <div class="impact__content_transaction-profile">
            <div
                [ngClass]="
                    impactCard.clientMetricRate -
                        impactCard.controlGroupMetricRate >
                    0
                        ? 'success'
                        : 'error'
                "
            >
                <span
                    >{{
                        impactCard.clientMetricRate -
                            impactCard.controlGroupMetricRate | datFormat:"metricPcnt"
                    }}</span
                >
                <img
                    *ngIf="
                        impactCard.clientMetricRate -
                            impactCard.controlGroupMetricRate >
                        0
                    "
                    src="assets/icons/arrow-up.svg"
                    alt="up arrow icon"
                />
                <img
                    *ngIf="
                        impactCard.clientMetricRate -
                            impactCard.controlGroupMetricRate <=
                        0
                    "
                    src="assets/icons/arrow-down.svg"
                    alt="down arrow icon"
                />
            </div>
            <div>vs transaction profile</div>
        </div>
    </div>
    <div class="impact__bottom">
        <span>Monthly impact: </span>
        <span [ngClass]="impactCard.impactValueUSD > 0 ? 'success' : 'error'">
            ${{ impactCard.impactValueUSD | datFormat:"impactValueUSD"}}
        </span>
    </div>
</div>
