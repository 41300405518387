import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './features/header/header.component';
import { TopAlertCardComponent } from './features/top-alerts/components/top-alert-card/top-alert-card.component';
import { TopAlertSectionComponent } from './features/top-alerts/components/top-alert-section/top-alert-section.component';
import { OverviewPageComponent } from './pages/overview-page/overview-page.component';
import { MainLayoutComponent } from './features/layouts/components/main-layout/main-layout.component';
import { ImpactCardComponent } from './features/impact/components/impact-card/impact-card.component';
import { ImpactSectionComponent } from './features/impact/components/impact-section/impact-section.component';
import { NavMenuComponent } from './features/nav-menu/nav-menu.component';
import { AlertListComponent } from './features/alerts/components/alert-list/alert-list.component';
import { AlertItemComponent } from './features/alerts/components/alert-item/alert-item.component';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';
import { ImpactChartComponent } from './features/impact/components/impact-chart/impact-chart.component';
import { ImpactChartSectionComponent } from './features/impact/components/impact-chart-section/impact-chart-section.component';
import { AlertSectionComponent } from './features/alerts/components/alert-section/alert-section.component';
import { AlertNotificationsComponent } from './features/alerts/components/alert-notifications/alert-notifications.component';
import { AngularLibrariesModule } from './shared/angular-libraries.module';
import { MediaQueryControlService } from './shared/services/media-query-control.service';
import { MobileMenuComponent } from './features/mobile-menu/mobile-menu.component';
import { FiltersPanelComponent } from './features/filters-panel/filters-panel.component';
import { AlertFilterDropdownComponent } from './features/alerts/components/alert-filter-dropdown/alert-filter-dropdown.component';
import { SharedModule } from './shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { AlertPageComponent } from './pages/alert-page/alert-page.component';
import { NavAlertComponent } from './features/nav-alert/nav-alert.component';
import { AlertDetailsSectionComponent } from './features/alert-details/components/alert-details-section/alert-details-section.component';
import { AlertDetailsCardComponent } from './features/alert-details/components/alert-details-card/alert-details-card.component';
import { AlertDetailsBackToTopButtomComponent } from './features/alert-details/components/alert-details-back-to-top-buttom/alert-details-back-to-top-buttom.component';
import { AlertDetailsDeclineSectionComponent } from './features/alert-details/components/alert-details-decline-section/alert-details-decline-section.component';
import { AlertDetailsDeclineCardComponent } from './features/alert-details/components/alert-details-decline-card/alert-details-decline-card.component';
import { AlertDetailsDeclineChartComponent } from './features/alert-details/components/alert-details-decline-chart/alert-details-decline-chart.component';
import { AlertDetailsDeclineChartSectionComponent } from './features/alert-details/components/alert-details-decline-chart-section/alert-details-decline-chart-section.component';
import { AlertDetailsRecommendationsComponent } from './features/alert-details/components/alert-details-recommendations/alert-details-recommendations.component';
import { AlertDetailsApprovalRateSectionComponent } from './features/alert-details/components/alert-details-approval-rate-section/alert-details-approval-rate-section.component';
import { AlertDetailsApprovalRateChartComponent } from './features/alert-details/components/alert-details-approval-rate-chart/alert-details-approval-rate-chart.component';
import { ProfileButtonComponent } from './features/header/profile-button/profile-button.component';
import { ModalComponent } from './features/modal/modal.component';

/*Pipe formatting*/
import { DatFormatPipe } from './shared/pipes/dat-format.pipe';
import { DatePipe, DecimalPipe } from '@angular/common';

/*API Data Services*/
import { HttpClientModule } from '@angular/common/http';
import {
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalModule,
    MsalRedirectComponent,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
} from '@azure/msal-angular';
import {
    InteractionType,
    IPublicClientApplication,
    PublicClientApplication,
} from '@azure/msal-browser';
import { msalConfig } from './auth-config';

/*Analytics Service*/
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';


const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
    };
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        TopAlertCardComponent,
        TopAlertSectionComponent,
        OverviewPageComponent,
        MainLayoutComponent,
        ImpactCardComponent,
        ImpactSectionComponent,
        NavMenuComponent,
        AlertListComponent,
        AlertItemComponent,
        NotificationsPageComponent,
        ImpactChartComponent,
        ImpactChartSectionComponent,
        AlertSectionComponent,
        AlertNotificationsComponent,
        MobileMenuComponent,
        FiltersPanelComponent,
        AlertPageComponent,
        NavAlertComponent,
        AlertDetailsSectionComponent,
        AlertDetailsCardComponent,
        AlertDetailsBackToTopButtomComponent,
        AlertDetailsDeclineSectionComponent,
        AlertDetailsDeclineCardComponent,
        AlertDetailsDeclineChartComponent,
        AlertDetailsDeclineChartSectionComponent,
        AlertDetailsRecommendationsComponent,
        AlertDetailsApprovalRateSectionComponent,
        AlertDetailsApprovalRateChartComponent,
        DatFormatPipe,
        ProfileButtonComponent,
        ModalComponent,
        AlertFilterDropdownComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        AngularLibrariesModule,
        SharedModule,
        NgxPaginationModule,
        HttpClientModule,
        MsalModule
    ],
    providers: [
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        MediaQueryControlService,
        DecimalPipe,
        DatePipe,
        DatFormatPipe,
        GoogleAnalyticsService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
