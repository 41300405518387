import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { barConfig, nonStackConfig, absSplitConfig } from './chart-config';
import { Chart } from 'chart.js';
import 'hammerjs';
import zoomPlugin from 'chartjs-plugin-zoom';
import { kpiCard } from 'src/app/services/alert-details/alert-detail';


@Component({
  selector: 'app-alert-details-decline-chart',
  templateUrl: './alert-details-decline-chart.component.html',
  styleUrls: ['./alert-details-decline-chart.component.css']
})
export class AlertDetailsDeclineChartComponent
implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() chartType: string = 'bar';
  @Input() show: boolean = false;
  @Input() isSlider:boolean;
  @Input() cardIndex:number;
  @Input() declineChartResponseDetails:any
  @Input() declineChartDetails:any
  @Input() kpiCards:kpiCard[]
  myChart: Chart | undefined;
  legendColours:string[]=['#00aab9','#004f5f','#b8b8b8','#5c5c5c','#333333']
  legendData:any[]=[]
  showLegend:boolean=false

  constructor() {
    Chart.register(zoomPlugin);
  }

  ngAfterViewInit(): void {
     //const config = { ...barConfig };
     const config = { ...nonStackConfig };
      this.myChart = new Chart('chart-canvas', config);
      this.setConfig('groupnonstack');
  }

  ngOnDestroy(): void {
      this.myChart?.destroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['show'] || changes['isSlider']) {
        if(this.show){
            this.setConfig(this.chartType);
        }
        else {
            if(this.isSlider){
                this.setConfig('groupstack');
            } else {
                this.setConfig('groupnonstack');
            }
        }
    }
    if (changes['cardIndex']){

        for(var i=0;i<this.declineChartResponseDetails.alertDeclineDetailsObjectDto.length;i++){
            if(this.kpiCards[this.cardIndex].problemResponseMessage.toLowerCase().replace(/\s+/g, '')
            ==this.declineChartResponseDetails.alertDeclineDetailsObjectDto[i].responseMessage.toLowerCase().replace(/\s+/g, '')){
                this.setConfig('bar',i)
                break
            }
        }
    }

}

  ngOnInit(): void {
    for(var i=0;i<this.declineChartDetails.last4WeeksData.datasets.length;i++){
        var lab:string = this.declineChartDetails.last4WeeksData.datasets[i].label;
        lab = lab.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
        this.legendData.push([lab,this.legendColours[i]]);
    }
  }

  setConfig(chartType: string, barIndex:number=0): void {
      if (this.myChart) {
          switch (chartType) {
                case 'bar':
                    this.myChart.config.type = barConfig.type;
                    barConfig.data.datasets[0].data = this.declineChartResponseDetails.alertDeclineDetailsObjectDto[barIndex].clientSplit
                    barConfig.data.datasets[1].data = this.declineChartResponseDetails.alertDeclineDetailsObjectDto[barIndex].transactionProfileSplit
                    var max1 = Math.max.apply(null, barConfig.data.datasets[0].data);
                      var max2 = Math.max.apply(null, barConfig.data.datasets[1].data);
                      var max = Math.max(max1, max2);
                      var min1 = Math.min.apply(null, barConfig.data.datasets[0].data);
                      var min2 = Math.min.apply(null, barConfig.data.datasets[1].data);
                      var min = Math.min(min1, min2);
                      barConfig.options.scales.y.max = Math.round(max);
                      barConfig.options.scales.y.min = Math.round(min);
                    this.myChart.config.data = barConfig.data;
                    this.myChart.config.options = barConfig.options;
                    this.myChart.config.data.labels=this.declineChartResponseDetails.alertDeclineDetailsObjectDto[barIndex].label
                    this.showLegend=false
                    break;
                case 'groupnonstack':

                    this.myChart.config.type = nonStackConfig.type;
                    this.myChart.config.data.labels=this.declineChartDetails.last7DaysData.label;
                    nonStackConfig.data.datasets[0].data=this.declineChartDetails.last7DaysData.clientSplit;
                    nonStackConfig.data.datasets[0].data=this.declineChartDetails.last7DaysData.transactionProfileSplit;
                    var max1 = Math.max.apply(null,  nonStackConfig.data.datasets[0].data);
                      var max2 = Math.max.apply(null,  nonStackConfig.data.datasets[1].data);
                      var max = Math.max(max1, max2);
                      var min1 = Math.min.apply(null,  nonStackConfig.data.datasets[0].data);
                      var min2 = Math.min.apply(null,  nonStackConfig.data.datasets[1].data);
                      var min = Math.min(min1, min2);
                      nonStackConfig.options.scales.y.max = Math.round(max);
                      nonStackConfig.options.scales.y.min = Math.round(min);
                    this.myChart.config.data = nonStackConfig.data;
                    this.myChart.config.options = nonStackConfig.options;
                    this.showLegend=false
                    break;
                case 'groupstack':
                    this.myChart.config.type = absSplitConfig.type;
                    this.myChart.config.data = absSplitConfig.data;
                    var Max=0
                    var week5:boolean=false

                    for(var i=0;i<this.declineChartDetails.last4WeeksData.datasets.length;i++){
                        this.myChart.config.data.datasets[i]=this.declineChartDetails.last4WeeksData.datasets[i];
                        this.myChart.config.data.datasets[i].backgroundColor=this.legendColours[i]
                        if(this.declineChartDetails.last4WeeksData.datasets[i].data[4]!=0) week5=true //Check if week5 have data

                    }
                    var currentMax=0
                    for(var i=0;i<this.declineChartDetails.last4WeeksData.datasets[0].data.length;i++){
                        for(var j=0;j<this.declineChartDetails.last4WeeksData.datasets.length;j++){
                            currentMax=currentMax+this.declineChartDetails.last4WeeksData.datasets[j].data[i]
                        }
                        if(currentMax>Max) Max=currentMax
                        currentMax=0
                    }

                    absSplitConfig.options.scales.y.max=Max
                    this.myChart.config.options = absSplitConfig.options;
                    this.myChart.config.data.labels=[]
                    for(var i=0;i<this.declineChartDetails.last4WeeksData.datasets[0].data.length;i++){
                        var week='Week '+(i+1)
                        this.myChart.config.data.labels.push(week)
                    }
                    if(!week5) this.myChart.config.data.labels.pop(); //Else remove week5

                    this.showLegend=true
                    break;
                default:
                    this.myChart.config.type = nonStackConfig.type;
                    this.myChart.config.data = nonStackConfig.data;
                    this.myChart.config.options = nonStackConfig.options;
                    this.showLegend=false
          }
          this.myChart.update();
      }
  }

  getMockData(index:number):any{
      var response=Array.from({length: 10}, () => Math.floor(Math.random() * 100));
      if(index==1){
        return [ 8, 22, 80, 47, 50, 79, 67, 42, 78, 56 ]
      } else if (index==2){
          return [ 22, 30, 14, 8, 28, 16, 39, 55, 86, 43 ]
      } else if (index==3){
        return [ 82, 69, 71, 62, 24, 21, 42, 5, 23, 12 ]
      } else if (index==0){
        return [ 70, 89, 48, 98, 41, 69, 76, 64, 42, 49 ]

      }
     // return response
  }
  getMockData2(index:number):any{
    if(index==0){
        return [ 8, 22, 80, 47, 50, 79, 67, 42, 78, 56 ]
      } else if (index==1){
          return [ 22, 30, 14, 8, 28, 16, 39, 55, 86, 43 ]
      } else if (index==2){
        return [ 82, 69, 71, 62, 24, 21, 42, 5, 23, 12 ]
      } else if (index==3){
        return [ 70, 89, 48, 98, 41, 69, 76, 64, 42, 49 ]

      }
}
}
