<div class="impact" [ngClass]="{'active': active }">
    <div class="impact__header">
        <span>Change in {{ kpiCard.problemResponseMessage | lowercase }} in last 7 days</span>
    </div>
    <div class="impact__content">
        <h2>${{-kpiCard.value | number: '2.' }}</h2>

    </div>
    <div class="impact__bottom">
        <span>Value lost vs transaction profile</span>

    </div>
</div>
