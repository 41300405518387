<ng-container
    *ngIf="(mediaQueryControl.layout | async) != 'mobile'; else mobileLayout"
>
    <div class="alert-item">
        <a class="alert-hyperlink" [routerLink]="['/'+currentClient,'alert',alert.pK_AlertID]"></a>
        <div class="pin-bar"></div>
        <!-- TYPE -->
        <div class="type">
            <div>{{ alert.metricType }}</div>
            <div class="tablet">{{ alert.insertedDate | datFormat:"impactDate" }}</div>
                <svg
                    (click)="handlePinClick()"
                    width="9"
                    height="13"
                    viewBox="0 0 9 13"
                >
                    <path
                        d="M2.93755 0.25C2.36737 0.25 1.89586 0.75259 1.89586 1.36036C1.89586 1.78542 2.13237 2.14976 2.46553 2.33625L1.80226 5.0601C1.1095 5.45481 0.59375 6.03383 0.59375 6.77339C0.59375 7.00326 0.768727 7.18977 0.984383 7.18977H4.10944V12.3252C4.10639 12.5453 4.29357 12.7502 4.50008 12.7502C4.70658 12.7502 4.89376 12.5453 4.89071 12.3252V7.18977H8.01577C8.23143 7.18977 8.4064 7.00326 8.4064 6.77339C8.4064 6.03388 7.89065 5.45484 7.19789 5.0601L6.53462 2.33625C6.86778 2.14974 7.10429 1.7854 7.10429 1.36036C7.10429 0.75259 6.63279 0.25 6.06261 0.25H2.93755Z"
                        fill="#0C002E"
                    />
            </svg>
        </div>
        <!-- CATEGORY -->
        <div class="category no_tablet">
            <div class="category__credit-card">
                <img src="{{imageSRC}}"  (error)="imageSRC = 'assets/logos/no-image.png'" alt="credit card logo" />

                <span class="margin-left">{{ desktopNetwork }}</span>
            </div>
            <div class="category__currency margin-left">{{ alert.currency }}</div>
            <div class="category__currency margin-left">{{ desktopIssuer }}</div>
            <div class="category__currency margin-left">{{ alert.cardType }}</div>
        </div>
        <div class="category tablet">
            <div class="category__credit-card">
                <img src="{{imageSRC}}"  (error)="imageSRC = 'assets/logos/no-image.png'" alt="credit card logo" />

                <span class="margin-left">{{ tabletNetwork }}</span>
            </div>
            <div class="category__currency margin-left">{{ alert.currency }}</div>
            <div class="category__currency margin-left">{{ tabletIssuer }}</div>
            <div class="category__currency margin-left">{{ alert.cardType }}</div>
        </div>
        <!-- DATE -->
        <div class="date">{{ alert.insertedDate | datFormat:"impactDate" }}</div>
        <!-- MONTHLY IMPACT -->
        <div class="monthly-impact error">{{ alert.monthlyImpact | datFormat:"monthlyImpactUSD" }}</div>
        <div class="vs-cluster space-between">
            <!-- ERROR -->
            <div class="flex-center error">
                <span>{{ alert.vsCluster | datFormat:"vsCluster" }}</span
                ><img src="assets/icons/arrow-down.svg" alt="" />
            </div>
            <!-- ALERT -->
            <div class="flex-center">
                <img *ngIf="alert.showThresholdAlert" src="assets/icons/alert.svg" alt="" />
                <svg
                    width="10"
                    height="16"
                    viewBox="0 0 10 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.15632 15.2L9.75632 7.6L2.15632 0L0 2.1568L5.44432 7.6L0 13.0432L2.15632 15.2Z"
                        fill="#B8B8B8"
                    />
                </svg>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #mobileLayout>
    <div  class="alert-item" >
        <a class="alert-hyperlink" [routerLink]="['/'+currentClient,'alert',alert.pK_AlertID]"></a>
        <div class="pin-bar"></div>
        <div class="grid flex-column">
            <div class="flex-center space-between">
                <!-- TYPE -->
                <div class="type__mobile">
                    {{ alert.metricType }}

                </div>

                <!-- MONTHLY IMPACT -->
                <div class="monthly-impact error">{{ alert.monthlyImpact | datFormat:"monthlyImpactUSD" }}</div>
                <!-- ERROR -->
                <div class="flex-center error">
                    <span>{{ alert.vsCluster | datFormat:"vsCluster" }}</span
                    ><img src="assets/icons/arrow-down.svg" alt="" />
                </div>
                <!-- ALERT ARROW -->
                <svg
                    width="10"
                    height="16"
                    viewBox="0 0 10 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >

                    <path
                        d="M2.15632 15.2L9.75632 7.6L2.15632 0L0 2.1568L5.44432 7.6L0 13.0432L2.15632 15.2Z"
                        fill="#B8B8B8"
                    />

                </svg>
            </div>
            <div class="flex-center space-between">
                <div class="flex-center">
                    <!-- ALERT -->
                    <div style="margin-right:1em">
                        <svg
                        (click)="handlePinClick()"
                        width="9"
                        height="13"
                        viewBox="0 0 9 13"
                    >
                        <path
                            d="M2.93755 0.25C2.36737 0.25 1.89586 0.75259 1.89586 1.36036C1.89586 1.78542 2.13237 2.14976 2.46553 2.33625L1.80226 5.0601C1.1095 5.45481 0.59375 6.03383 0.59375 6.77339C0.59375 7.00326 0.768727 7.18977 0.984383 7.18977H4.10944V12.3252C4.10639 12.5453 4.29357 12.7502 4.50008 12.7502C4.70658 12.7502 4.89376 12.5453 4.89071 12.3252V7.18977H8.01577C8.23143 7.18977 8.4064 7.00326 8.4064 6.77339C8.4064 6.03388 7.89065 5.45484 7.19789 5.0601L6.53462 2.33625C6.86778 2.14974 7.10429 1.7854 7.10429 1.36036C7.10429 0.75259 6.63279 0.25 6.06261 0.25H2.93755Z"
                            fill="#0C002E"
                        />
                </svg>
                    </div>
                    <div class="alert">
                        <img src="assets/icons/alert.svg" alt="" />
                    </div>
                    <!-- CATEGORY -->
                    <img class="category__image" src="{{imageSRC}}"  (error)="imageSRC = 'assets/logos/no-image.png'" alt="credit card logo" />
                    <div class="category__currency">{{ alert.currency }}</div>
                </div>
                <!-- DATE -->
                <div class="date flex-center flex-end">{{ alert.insertedDate | datFormat:"impactDate" }}</div>
            </div>
        </div>
    </div>
</ng-template>
