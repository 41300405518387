<section class="recommendations-section">
    <div class="recommendations-section-header">
        <div class="recommendations-section-header__info">
            Our recommendations
            <img (click)="openPopup('tooltip', 0)" src="assets/icons/question-white.svg" alt="info icon"/>
            <span #tooltip *ngIf=isOpenTooltip class="tooltip">
                <img class="close-icon" (click)="closePopup('tooltip', 0)" src="assets/icons/close.svg" alt="close icon"/>
                {{tooltipText}}
            </span>
        </div>
        <h3>Below are the main reasons behind the problem and our recommended action:</h3>
    </div>
    <div class="recommendations-section-reasons">
        <div *ngIf="!recommendations">
            <ul>
                <li>
                    <div>
                        <p class="recommendations-section-reasons__pro">
                            <span class="recommendations-section-reasons__element-main">
                                We don't have any recommendations for now.
                            </span>
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <div *ngIf="recommendations">
            <ul>
                <li *ngFor="let recommendation of recommendations; let i = index">
                    <div>
                        <p #pReadmore class="recommendations-section-reasons__pro">
                            <span class="recommendations-section-reasons__element-main">
                                Problem:
                            </span>
                            <span class="recommendations-section-reasons__element-desc">
                                Over the past week, {{recommendation.problemCategory}} cards saw an increase in "{{recommendation.problemResponseMessage}}" response messages relative to transaction profile.
                            </span>
                            <!-- <br *ngIf=isOpenReadmore[i]> -->
                            <span class="readmore-button" (click)="openPopup('readmore', i)">Read more</span>
                            <span #readmore *ngIf=isOpenReadmore[i] class="readmore">
                                <img class="close-icon" (click)="closePopup('readmore', i)" src="assets/icons/close.svg" alt="close icon"/>
                                {{recommendation.responseMessageDescription}} 
                            </span>
                        </p>
                        <p class="recommendations-section-reasons__act">
                            <span class="recommendations-section-reasons__element-main">
                                Action:
                            </span>
                            <span class="recommendations-section-reasons__element-desc">
                                {{recommendation.action}}
                            </span>
                        </p>
                        <p class="recommendations-section-reasons__val">
                            <span class="recommendations-section-reasons__element-main">
                                Value:
                            </span>
                            <span class="recommendations-section-reasons__element-desc">
                                Loss of
                            </span>
                            <span class="recommendations-section-reasons__element-quantity">
                                ${{-recommendation.value  | number: '2.'}}
                            </span>
                            <span class="recommendations-section-reasons__element-desc">
                                of good transactions in the past 7 days
                            </span>
                        </p>
                    </div>
                    <div class="recommendations-section-reasons__function" style="display:none"><button>Export raw data</button></div>
                </li>
            </ul>
        </div>
    </div>
    <div class="recommendations-section-footer">
        <h5>Need help from CMSPI to investigate these issues?</h5>
        <div class="recommendations-section-footer__function">
            <a href="mailto:quantafi_test@cmspi.com?subject=Contact%20us%20-%20Alert%20ID%20{{alertId}}">Contact CMSPI</a>
        </div>
    </div>
</section>