export const environment = {
  production: true,
  subscriptionKey: 'bd7f50be0909445daa70c4d271b975f1',
  idAppRegistrationSPA: '9084db4f-ad81-4f18-be9d-81c6d0efc252', // APP registration inside tenant b2c
  authorityB2C: 'https://login.microsoftonline.com/0b25e227-7b29-4b61-9211-2a2a4cdd388c', // Endpoint of APP registration inside tenant b2c
  frontendRedirectURL: 'https://quantafi-test.cmspi.com', // page to redirect
  authenticationCodeAPIM: 'yky6BhWbct839Aai9IPvaiRoF0tBHGzVDXxtdEm5UHdjCApCZCxNNw',
  sessionStorageTokenKey: 'b2c_1a_signup_signin.0b25e227-7b29-4b61-9211-2a2a4cdd388c-cmspitest.b2clogin.com-idtoken',
  sessionStorageInfo: 'b2c_1a_signup_signin.0b25e227-7b29-4b61-9211-2a2a4cdd388c-cmspitest.b2clogin.com',
  sessionRefreshTokenInfo: 'b2c_1a_signup_signin.0b25e227-7b29-4b61-9211-2a2a4cdd388c-cmspitest.b2clogin.com-refreshtoken',
  userDataReplaceId: '-b2c_1a_signup_signin.0b25e227-7b29-4b61-9211-2a2a4cdd388c',
  b2cDomain: 'cmspitest',
  apiURL: 'https://apim-cmspi-portal-test-001.azure-api.net/api',
  measurementID: 'G-2DSHJ3PWHY' //Google Analytics Measurement ID
};