import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AccountService } from "../account/account.service";
import { UserDataService } from "../user-data/user-data.service";

@Injectable({
    providedIn: "root"
})
export class NoAccessResolver implements Resolve<any> {
    constructor(private router: Router, private userDataService: UserDataService, private accountService: AccountService) { }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        await this.accountService.getAuthenticationFlow();
        var haveClients = await this.haveClients();
        if (haveClients) {
            return true;
        }
        this.router.navigate(['/no-access']);
        return false;
    }

    async haveClients(): Promise<boolean> {
        var result = await (this.userDataService.getUserData()).toPromise();
        if (result) {
            return result.clients.length > 0;
        }
        return false;
    }
}