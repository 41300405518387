import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { kpiCard } from 'src/app/services/alert-details/alert-detail';

@Component({
  selector: 'app-alert-details-decline-card',
  templateUrl: './alert-details-decline-card.component.html',
  styleUrls: ['./alert-details-decline-card.component.css']
})
export class AlertDetailsDeclineCardComponent implements OnInit {
  @Input() approvalRate: number = 73345;
  @Input() transactionProfile: number = 0.3;
  @Input() monthlyImpact: number = 10;
  @Input() tooltipText: string =
      'The value (or number) of all transactions which are approved, divided by the value (or number) of all transaction attempts';
   @Input() kpiCard: kpiCard;
   @Input() position:number;
   @Input() cardIndex:number;
   @Input() show:boolean;
   active:boolean=false
  constructor() {}

  ngOnInit(): void {
      this.kpiCard.problemResponseMessage = this.kpiCard.problemResponseMessage.replace(/([A-Z])/g, ' $1').trim()
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['show'] || changes['cardIndex']) {
        if(this.position==this.cardIndex && this.show){
            this.active=true
        } else {
            this.active=false
        }
    }
}

}
