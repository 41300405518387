import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';
import { BodyEmail } from 'src/app/services/sharing/sharing-email';
import { SharingEmailService } from 'src/app/services/sharing/sharing-email.service';
import { MediaQueryControlService } from 'src/app/shared/services/media-query-control.service';
import { environment } from 'src/environments/environment';
import { ModalService } from '../modal/modal.service';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css'],
})
export class NavMenuComponent implements OnInit {
    email: string;
    responseEmail : string;
    sharingError : string|any = 'Empty';
    modalOpened: boolean = false;
    placeholderText:string='Insert email address here.';

    flags: {
        navbarVisibilityMode: "explicit"|"implicit"
    };


    @Input() activeIndex: number = 0;
    currentClient: string | null;

    constructor(public router: Router,
                public mediaQueryControl: MediaQueryControlService,
                private modalService: ModalService,
                private sharingService: SharingEmailService,
                private route: ActivatedRoute,
                private gaService: GoogleAnalyticsService) {
    }

    ngOnInit(): void {
        this.email = '';
        this.currentClient=this.route.snapshot.paramMap.get('clientName')
    }

    openModal(id: string) {
        this.modalService.open(id);

        var section = '';
        if(this.router.url == "/overview"){
            section = "overview";
        }else if(this.router.url == "/notifications"){
            section = "notifications";
        }
        this.gaService.contentEventEmitter('navAlertShare', section);

    }

    closeModal(id: string) {
        this.modalService.close(id);
        if(id == 'shared-response'){
            this.responseEmail = '';
        }else if(id == 'shared-error'){
            this.sharingError = '';
        }


    }

    sendModal(id: string){
        if(!this.isEmail(this.email)){
            this.email = '';
            this.modalService.close(id);
            this.sharingError = "Not valid email address";
            this.modalService.open('shared-error');
            return;
        }

        var bodyEmail:BodyEmail;
        var section:string = "";
        var clientName:string = this.route.snapshot.paramMap.get('clientName') || ''
        var userB2C:string|any = sessionStorage.getItem('userId')?.replace(environment.userDataReplaceId, '').toUpperCase();
        var alertId:number|null = null;

        if(this.router.url == "/overview"){
            section = "overview";
        }else if(this.router.url == "/notifications"){
            section = "notifications";
        }

        bodyEmail={
            "Url": environment.frontendRedirectURL,
            "DestinationEmail": this.email,
            "Section": section,
            "ClientName": clientName,
            "UserB2CObjectId": userB2C,
            "AlertId": alertId,
        }
        this.shareMail(bodyEmail);
        this.modalService.close(id);
        this.responseEmail = this.email;
        this.email = "";

    }

    shareMail(bodyEmail:BodyEmail){
        this.sharingService.shareEmail(bodyEmail).subscribe(data => {
            if(data.emailSended){
                this.responseEmail = data.destinationEmail;
                this.openModal("shared-response");
            }else{
                this.responseEmail = data.destinationEmail;
                if(data.emailSendError){
                    this.sharingError = data.emailSendError;
                }else{
                    this.sharingError = 'Unknown';
                }

                this.openModal("shared-error");
            }
        })
    }

    isEmail(search:string):boolean{
        var searchFind:boolean;
        var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        searchFind = regexp.test(search);
        return searchFind;
    }
}
