import { Component, HostBinding, OnInit } from '@angular/core';
import { MediaQueryControlService } from 'src/app/shared/services/media-query-control.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit {

  @HostBinding('style.--main-horizontal-padding') mainHorizontalPadding: string|number;

  constructor(mediaQueryControl: MediaQueryControlService) {
    this.changePaddingAccordingToLayout(mediaQueryControl);
  }

  ngOnInit(): void {
  }

  private changePaddingAccordingToLayout(mediaQueryControl: MediaQueryControlService) {
    mediaQueryControl.layout.subscribe(layout => {
      if(layout == "mobile"){
        this.mainHorizontalPadding = "0px";
      }else if(layout == "tablet"){
        this.mainHorizontalPadding = "10px";
      }else{
        this.mainHorizontalPadding = "120px";
      }
      
    });
  }

}
