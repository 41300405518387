import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { alertDetail } from 'src/app/services/alert-details/alert-detail';
import { AlertDetailService } from 'src/app/services/alert-details/alert-detail.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';


@Component({
    selector: 'app-alert-details-approval-rate-section',
    templateUrl: './alert-details-approval-rate-section.component.html',
    styleUrls: ['./alert-details-approval-rate-section.component.css']
})
export class AlertDetailsApprovalRateSectionComponent implements OnInit {

    alertsDetailChart: alertDetail[];
    chartPeriod: number = 3;

    titleLab: string = "Your rate vs expected";
    periodLabs: string[] = ['Last 14 days', 'Last 30 days', 'Last year'];
    periodGA: string[] = ['Last14days', 'Last30days', 'Lastyear'];


    constructor(private alertsDetailsService: AlertDetailService, 
                private route: ActivatedRoute,
                private gaService: GoogleAnalyticsService) { }

    ngOnInit(): void {
        this.getAlertsDetailsChart();
    }

    getAlertsDetailsChart(): void {
        this.alertsDetailsService.getAlertDetailsChart(this.route.snapshot.paramMap.get('id')).subscribe((data: any) => {
            this.alertsDetailChart = data.alerts;
            this.loadTypeLabels();
        })
    }

    setChartPeriod(id:number){
        this.chartPeriod = id;
        this.gaService.contentEventEmitter('detailChartApproval', this.periodGA[id-1]);
    }
    
    loadTypeLabels(){
        var type = this.alertsDetailChart[0].type
        if(type == 1 || type == 2){
            this.periodLabs = ['Last 14 days', 'Last 30 days', 'Last year'];
            this.periodGA = ['Last14days', 'Last30 days', 'LastYear'];
            if(type == 1){
                this.titleLab = "Your gross approval rate vs expected";
            }else if(type == 2){
                this.titleLab = "Your net approval rate vs expected";
            }
        }else if(type == 3 || type == 4){
            this.periodLabs = ['Last 30 days', 'Last 90 days', 'Last year'];
            this.periodGA = ['Last30days', 'Last90days', 'LastYear'];
            if(type == 3){
                this.titleLab = "Your chargeback rate vs expected";
            }else if(type == 4){
                this.titleLab = "Your fraud rate vs expected";
            }
        }

    }

    ngOnDestroy(): void {

    }




}
