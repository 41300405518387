import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { BodyEmail } from './sharing-email';

@Injectable({
  providedIn: 'root'
})
export class SharingEmailService {

  private apiUrl = environment.apiURL;
  private emailUrl = 'emaildetails';

  httpOptions = {
      headers: new HttpHeaders(
                    { 'Content-Type': 'application/json',
                      'Authorization': sessionStorage.getItem('token')!,
                      'Ocp-Apim-Subscription-Key': environment.subscriptionKey }
      ),
      params: { code: environment.authenticationCodeAPIM },
  }

  constructor(
    private http: HttpClient
  ) { }


  shareEmail(data:BodyEmail){
    return this.http.post<any>(`${this.apiUrl}/${this.emailUrl}/`, data, this.httpOptions)
          .pipe(
            tap(_ => console.log('SharingEmailService', 'Email Details')),
            catchError(this.handleError<any>('getAlerts', []))
          );
  }

  /**
     * Handle Http operation that failed.
     * Let the app continue.
     *
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a AlertService message with the MessageService */
  private log(message: string) {
    console.log(`AlertService: ${message}`);
  }

}
