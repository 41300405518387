export const barConf: any = {
    type: 'bar',
    data: {
        datasets: [
            {
                borderColor: 'transparent',
                borderWidth: {
                    right: 2,
                    left: 2,
                },
                barThickness: 20,
                borderRadius: 2,
                backgroundColor: '#00AAB9',
                data: [81, 79],
            },
            {
                borderColor: 'transparent',
                borderWidth: {
                    right: 2,
                    left: 2,
                },
                barThickness: 20,
                borderRadius: 2,
                backgroundColor: '#004F5F',
                data: [85, 78],
            },
        ],
    },
    options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    padding: { bottom: 20 },
                    text: 'Gross approval rate',
                },
                min: 0,
                max: 100,
                grid: {
                    borderWidth: 1,
                    borderColor: 'black',
                },
                ticks: {
                    count: 5,
                    stepSize: 20,
                    callback: function (value: any, index: any, ticks: any) {
                        return value.toString().substring(0, 5) + '%';
                    },
                },
            },
            x: {
                title: {
                    display: true,
                    padding: { top: 10 },
                    text: '2020',
                },
                labels: [
                    'Jan 20',
                    'Feb 20',
                ],
                grid: {
                    display: false,
                    borderWidth: 1,
                    borderColor: 'black',
                },
                offset: true,
            },
        },
    },
};



export const lineConf: any = {
    type: 'line',
    data: {
        datasets: [
            {
                backgroundColor: '#00AAB9',
                borderColor: '#00AAB9',
                borderDash: [8, 5],
                data: [81, 79],
            },
            {
                backgroundColor: '#004F5F',
                borderColor: '#004F5F',
                data: [85, 78],
            },
        ],
    },
    options: {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
            point: {
                pointStyle: 'line',
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    padding: { bottom: 20 },
                    text: 'Gross approval rate',
                },
                min: 0,
                max: 100,
                grid: {
                    borderWidth: 1,
                    borderColor: 'black',
                },
                ticks: {
                    count: 5,
                    stepSize: 20,
                    callback: function (value: any, index: any, ticks: any) {
                        return value.toString().substring(0, 5) + '%';
                    },
                },
            },
            x: {
                title: {
                    display: true,
                    padding: { top: 10 },
                    text: '2020',
                },
                labels: [
                    'Jan 20',
                    'Feb 20',
                ],
                grid: {
                    display: false,
                    borderWidth: 1,
                    borderColor: 'black',
                },
                offset: true,
            },
        },
    },
};

export const mixedConf: any = {
    data: {
        datasets: [
            {
                type: 'line',
                backgroundColor: '#004F5F',
                borderColor: '#004F5F',
                data: [81, 79],
            },
            {
                type: 'bar',
                backgroundColor: '#00AAB9',
                borderColor: '#00AAB9',
                data: [85, 78],
            },
        ],
    },
    options: {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
            point: {
                pointStyle: 'line',
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    padding: { bottom: 20 },
                    text: 'Gross approval rate',
                },
                min: 0,
                max: 100,
                grid: {
                    borderWidth: 1,
                    borderColor: 'black',
                },
                ticks: {
                    count: 5,
                    stepSize: 20,
                    callback: function (value: any, index: any, ticks: any) {
                        return value.toString().substring(0, 5) + '%';
                    },
                },
            },
            x: {
                title: {
                    display: true,
                    padding: { top: 10 },
                    text: '2020',
                },
                labels: [
                    'Jan 20',
                    'Feb 20',
                ],
                grid: {
                    display: false,
                    borderWidth: 1,
                    borderColor: 'black',
                },
                offset: true,
            },
        },
    },

};

export const diffConf: any = {
    data: {
        datasets: [
            {
                type: 'bar',
                backgroundColor: '#004F5F',
                borderColor: '#ec4a36',
                data: [81, 79],
            },
        ],
    },
    options: {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
            point: {
                pointStyle: 'line',
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    padding: { bottom: 20 },
                    text: 'Gross approval rate',
                },
                min: 0,
                max: 50,
                grid: {
                    borderWidth: 1,
                    borderColor: 'black',
                },
                ticks: {
                    count: 5,
                    stepSize: 10,
                    callback: function (value: any, index: any, ticks: any) {
                        return value.toString().substring(0, 6) + '%';
                    },
                },
            },
            x: {
                title: {
                    display: true,
                    padding: { top: 10 },
                    text: '2020',
                },
                labels: [
                    'Jan 20',
                    'Feb 20',
                ],
                grid: {
                    display: false,
                    borderWidth: 1,
                    borderColor: 'black',
                },
                offset: true,
            },
        },
    },
};