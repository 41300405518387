<div class="chart-container">
    <canvas id="chart-canvas"></canvas>
</div>
<div class="chart-legend">
    <div class="chart-legend__stats">
        <div class="chart-legend__stats__row">
            <div class="chart-legend__stats__number">{{chartLegendNumberOfAlerts}}</div>
            <div class="chart-legend__stats__label">Total alerts</div>
        </div>
        <div class="chart-legend__stats__row">
            <div class="chart-legend__stats__number">{{chartLegendTotalImpact | datFormat :"topMonthlyImpactUSD"}}</div>
            <div class="chart-legend__stats__label">{{chartLegendLabel}}</div>
        </div>
    </div>
    <div class="chart-legend__labels">
        <div [style]="'--legend-color: ' + '#00AAB9'">
            Client {{ chartMetricName | datFormat:"fixedText":"impChartLab"}}
        </div>
        <div [style]="'--legend-color: ' + '#004F5F'">
            Transaction profile {{ chartMetricName | datFormat:"fixedText":"impChartLab"}}
        </div>
    </div>
</div>
