import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/services/account/account.service';
import { textInformation } from 'src/app/services/alert-details/alert-detail';
import { AlertDetailService } from 'src/app/services/alert-details/alert-detail.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-alert-page',
  templateUrl: './alert-page.component.html',
  styleUrls: ['./alert-page.component.css']
})
export class AlertPageComponent implements OnInit {

    textInformation: textInformation;


  constructor(
    private route: ActivatedRoute,
    private alertDetailService: AlertDetailService,
    private accountService: AccountService,
    public userDataService: UserDataService,
    private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    await this.accountService.getAuthenticationFlow();
    if (!this.route.snapshot.paramMap.get('clientName')){
        this.getUserData()

    } else {
        this.getTextInformation();
    }
  }

  getTextInformation(): void {
    this.alertDetailService.getTextInformation(this.route.snapshot.paramMap.get('id')).subscribe((data: any) =>{
        if(data.clientName != this.route.snapshot.paramMap.get('clientName')){
            this.router.navigate([this.route.snapshot.paramMap.get('clientName')+'/overview/']);
        }
        this.textInformation = data
    })
}

getUserData(): void {
    this.userDataService.getUserData().subscribe(data => {
        this.router.navigate([data.clients[0].clientName+'/alert/'+this.route.snapshot.paramMap.get('id')]);
    });
}

}
