<section
    *ngIf="show"
    [@fade]
    class="chart-section"
    [style]="'--card-index: ' + cardIndex"
>
    <div class="chart-section__nav flex-center space-between">
        <div class="chart-section__nav__title">
            {{metricLabel}} vs transaction profile
        </div>
        <div class="flex-center space-between">
            <div class="chart-section__nav__buttons_container" [ngClass]="navToggle ? 'hiddenNav' : 'shownNav'">
                <div class="chart-section__nav__buttons">
                    <button
                            (click)="setChartPeriod('1')"
                            [ngClass]="{ active: chartPeriod === '1' }">
                        <!-- Last 14 / 30 days -->
                        {{buttonLabels[0]}}
                    </button>
                    <button class="active"
                            (click)="setChartPeriod('2')"
                            [ngClass]="{ active: chartPeriod === '2' }">
                        <!-- Last 30 / 90 days -->
                        {{buttonLabels[1]}}
                    </button>
                    <button
                            (click)="setChartPeriod('3')"
                            [ngClass]="{ active: chartPeriod === '3' }">
                        <!-- Last year -->
                        {{buttonLabels[2]}}
                    </button>
                </div>
                <div class="chart-section__nav__buttons_row">
                    <div class="chart-section__nav__buttons">
                        <button class="nav__button"
                            (click)="chartModType('line')"
                            [ngClass]="{ active: chartType === 'line' }"
                        >
                        <img src="assets/icons/graph-line.svg" alt="LINE"/></button>
                        <button class="nav__button"
                            (click)="chartModType('bar')"
                            [ngClass]="{ active: chartType === 'bar' }"
                        >
                            <img src="assets/icons/graph-bar.svg" alt="BAR"/>
                        </button>
                        <button class="nav__button"
                            (click)="chartModType('mixed')"
                            [ngClass]="{ active: chartType === 'mixed' }"
                        >
                            <img src="assets/icons/graph-combined.svg" alt="COMB"/>
                        </button>
                    </div>
                    <div class="chart-section__nav__toggle-slider">
                        <label class="chart-section__nav__toggle-control">
                            <input 
                                (click)="chartModType('difference')"
                                id ="diffCheck"
                                type="checkbox"/>
                            <span></span>
                        </label>
                    </div>


                    <div class="chart-section__nav__toggle-label">Show difference</div>
                </div>

            </div>
            <div class="chart-section__nav__options" (click)="toggleOptions()">
                <svg width="14" height="12" viewBox="0 0 14 12">
                    <path
                        d="M1.03542 2.05132H12.964C13.4682 2.05132 13.8598 1.65977 13.8598 1.15554C13.8598 0.651318 13.4682 0.259766 12.964 0.259766H1.03542C0.5312 0.259766 0.139648 0.651318 0.139648 1.15554C0.139648 1.65977 0.5312 2.05132 1.03542 2.05132Z"
                        fill="black"
                    />
                    <path
                        d="M1.03542 6.89604H12.964C13.4682 6.89604 13.8598 6.50449 13.8598 6.00027C13.8598 5.49605 13.4682 5.10449 12.964 5.10449H1.03542C0.5312 5.10449 0.139648 5.49605 0.139648 6.00027C0.139648 6.50449 0.5312 6.89604 1.03542 6.89604Z"
                        fill="black"
                    />
                    <path
                        d="M12.964 11.7398C13.4682 11.7398 13.8598 11.3482 13.8598 10.844C13.8598 10.3398 13.4682 9.94824 12.964 9.94824H1.03542C0.5312 9.94824 0.139648 10.3398 0.139648 10.844C0.139648 11.3482 0.5312 11.7398 1.03542 11.7398H12.964Z"
                        fill="black"
                    />
                </svg>
            </div>
        </div>
    </div>
    <app-impact-chart 
        [chartData]="impactChartData.metrics"
        [chartMetricName]="metricInfo.metricName"
        [chartType]="chartType" 
        [chartPeriod]="chartPeriod"></app-impact-chart>
</section>
