import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ToggleEvent {
  index: number,
  itemId: number,
  active: boolean
}

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.css']
})
export class ToggleButtonComponent implements OnInit {

  @Input() text: string;
  @Input() active: boolean;
  @Input() index: number;
  @Input() itemId: number;  //Filters usage

  @Output() toggled = new EventEmitter<ToggleEvent>();
    
  constructor() { }

  ngOnInit(): void {
  }

  public toggleActive() {
    this.active = !this.active;
    const event = {
      index: this.index,
      itemId: this.itemId, //Filters usage
      active: this.active
    } as ToggleEvent;
    this.toggled.emit(event);
  }

}
