import { Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { recommendation } from 'src/app/services/alert-details/alert-detail';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-alert-details-recommendations',
  templateUrl: './alert-details-recommendations.component.html',
  styleUrls: ['./alert-details-recommendations.component.css']
})
export class AlertDetailsRecommendationsComponent implements OnInit {
  @Input() alertId: string|null = '';
  @Input() tooltipText: string = '';
  @Input() recommendations:recommendation[];

  isOpenTooltip:boolean;
  isOpenReadmore:boolean[];

  @ViewChild('tooltip') spanTooltip:ElementRef;
  @ViewChild('readmore') spanReadmore:ElementRef;
  @ViewChild('pReadmore') divPreadmore:ElementRef;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: { target: any; }): void {
    //if(!this.spanTooltip.nativeElement.contains(event.target)||
    //   !this.spanReadmore.nativeElement.contains(event.target)||
    //   !this.divPreadmore.nativeElement.contains(event.target)){}
    this.isOpenTooltip = false;
    for(let i = 0; i < this.isOpenReadmore.length; i++){
      this.isOpenReadmore[i] = false;
    }

  }

  constructor(private elementRef: ElementRef,
              private gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.isOpenTooltip = false;
    if(this.recommendations){
      this.isOpenReadmore = new Array(this.recommendations.length);
    }

  }

  ngOnChanges(changes:SimpleChanges):void{
    if(this.recommendations){
      this.isOpenReadmore = new Array(this.recommendations.length);
    }
  }

  openPopup(id:string, index:number){
    if(id == 'tooltip') this.isOpenTooltip = true;
    else if(id == 'readmore'){
      this.isOpenReadmore[index] = true;
    }
    this.gaService.contentEventEmitter('tooltip', id);
  }

  closePopup(id:string, index:number){
    console.log(id, index);
    if(id == 'tooltip') this.isOpenTooltip = false;
    else if(id == 'readmore'){
      this.isOpenReadmore[index] = false;
    }
  }


}
