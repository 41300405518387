export const barConfig: any = {
    type: 'bar',
    data: {
        labels: [
            'Jan 20',
            'Feb 20',
            'Mar 20',
            'Apr 20',
            'May 20',
            'Jun 20',
            'Jul 20',
            'Aug 20',
            'Sep 20',
            'Oct 20',
        ],
        datasets: [
            {
                borderColor: 'transparent',
                borderWidth: {
                    right: 2,
                    left: 2,
                },
                barThickness: 20,
                borderRadius: 2,
                data: [85, 78, 67, 72, 59, 44, 61, 73, 86, 91],
                backgroundColor: '#00AAB9',
            },
            {
                borderColor: 'transparent',
                borderWidth: {
                    right: 2,
                    left: 2,
                },
                barThickness: 20,
                borderRadius: 2,
                data: [81, 79, 60, 66, 71, 53, 58, 77, 88, 86],
                backgroundColor: '#004F5F',
            },
        ],
    },
    options: {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
          },
        plugins: {
            legend: {
                display: false,
            },
            zoom: {
                zoom: {
                  wheel: {
                    enabled: true,
                  },
                  pinch: {
                    enabled: true
                  },
                  mode: 'x',
                },
                pan: {
                  enabled: true,
                  mode: 'x',
                },
              },
            },
        scales: {
            y: {
                title: {
                    display: true,
                    padding: { bottom: 20 },
                    text: 'Decline responses',
                },
                min: 0,
                max: 100,
                grid: {
                    borderWidth: 1,
                    borderColor: 'black',
                },
                ticks: {
                    count: 5,
                    stepSize: 20,
                    callback: function (value: any, index: any, ticks: any) {
                        return value + '%';
                    },
                },
            },
            x: {
                offset: true,
                title: {
                    display: true,
                    padding: { top: 10 },
                    text: 'Last 7 days',
                },
                grid: {
                    display: false,
                    borderWidth: 1,
                    borderColor: 'black',
                },
            },
        },
        transitions: {
            zoom: {
              animation: {
                duration: 1000,
                easing: 'easeInBounce'
                }
            },
            pan: {
                animation:{
                    duration: 1000,
                    easing: 'easeInBounce'
                }
            }
        }
    },
};
export const nonStackConfig: any = {
    type: 'bar',
    data: {
        labels: [
            'Call',
            'Information error',
            'Insufficient funds'
        ],
        datasets: [
            {
                label: "Transaction profile gross approval rate ",
                backgroundColor: "#004f5f",
                data: [40,30,50]
            },
            {
              label: "Your gross approval rate",
              backgroundColor: "#00aab9",
              data: [30,70,40]
            }
        ]
    },
    options: {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
          },
        plugins: {
            legend: {
                display: false,
            },
            zoom: {
                zoom: {
                  wheel: {
                    enabled: true,
                  },
                  pinch: {
                    enabled: true
                  },
                  mode: 'x',
                },
                pan: {
                  enabled: true,
                  mode: 'x',
                },
              },
            },
        scales: {
            y: {
                title: {
                    display: true,
                    padding: { bottom: 20 },
                    text: 'Decline responses',
                },
                min: 0,
                max: 100,
                grid: {
                    borderWidth: 1,
                    borderColor: 'black',
                },
                ticks: {
                    count: 5,
                    stepSize: 20,
                    callback: function (value: any, index: any, ticks: any) {
                        return value + '%';
                    },
                },
            },
            x: {
                offset: true,
                title: {
                    display: true,
                    padding: { top: 10 },
                    text: 'Split of declines codes (Last 7 days)',
                },
                grid: {
                    display: false,
                    borderWidth: 1,
                    borderColor: 'black',
                },
            },
        },
        transitions: {
            zoom: {
              animation: {
                duration: 1000,
                easing: 'easeInBounce'
                }
            },
            pan: {
                animation:{
                    duration: 1000,
                    easing: 'easeInBounce'
                }
            }
        }
    },
};

export const absSplitConfigBad: any = {
    type: 'bar',
    data: {
        labels: ['Week 1'],
        datasets: [
          //Week 1
          {
            label: 'Call',
            data:[30],
            backgroundColor: '#00aab9',
            stack: 'Stack 0',
          },
          {
            label: 'Information error',
            data:[37],
            backgroundColor:'#004f5f',
            stack: 'Stack 0',
          },
          {
            label: 'Insufficient funds',
            data:[23],
            backgroundColor:'#b8b8b8',
            stack: 'Stack 0',
          },
          //Week 2
          {
            label: 'Call',
            data:[20],
            backgroundColor: '#00aab9',
            stack: 'Stack 1',
          },
          {
            label: 'Information error',
            data:[37],
            backgroundColor: '#004f5f',
            stack: 'Stack 1',
          },
          {
            label: 'Insufficient funds',
            data:[10],
            backgroundColor: '#b8b8b8',
            stack: 'Stack 1',
          },
          //Week 3
          {
            label: 'Call',
            data:[12],
            backgroundColor:'#00aab9',
            stack: 'Stack 2',
          },
          {
            label: 'Information error',
            data:[56],
            backgroundColor: '#004f5f',
            stack: 'Stack 2',
          },
          {
            label: 'Insufficient funds',
            data:[34],
            backgroundColor: '#b8b8b8',
            stack: 'Stack 2',
          },
          //Week 4
          {
            label: 'Call',
            data:[21],
            backgroundColor:'#00aab9',
            stack: 'Stack 3',
          },
          {
            label: 'Information error',
            data:[17],
            backgroundColor: '#004f5f',
            stack: 'Stack 3',
          },
          {
            label: 'Insufficient funds',
            data:[7],
            backgroundColor: '#b8b8b8',
            stack: 'Stack 3',
          }
        ]
    },
    options: {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
          },
        plugins: {
            legend: {
                display: false,
            },
            zoom: {
                zoom: {
                  wheel: {
                    enabled: true,
                  },
                  pinch: {
                    enabled: true
                  },
                  mode: 'x',
                },
                pan: {
                  enabled: true,
                  mode: 'x',
                },
              },
            },
        scales: {
            y: {
                stacked: true,
                title: {
                    display: true,
                    padding: { bottom: 20 },
                    text: 'No. of decline instances',
                },
                min: 0,
                max: 100,
                grid: {
                    borderWidth: 1,
                    borderColor: 'black',
                },
                ticks: {
                    count: 5,
                    stepSize: 20,
                    callback: function (value: any, index: any, ticks: any) {
                        return value + '%';
                    },
                },
            },
            x: {
                stacked: true,
                offset: true,
                title: {
                    display: true,
                    padding: { top: 10 },
                    text: '2020',
                },
                grid: {
                    display: false,
                    borderWidth: 1,
                    borderColor: 'black',
                },
            },
        },
        transitions: {
            zoom: {
              animation: {
                duration: 1000,
                easing: 'easeInBounce'
                }
            },
            pan: {
                animation:{
                    duration: 1000,
                    easing: 'easeInBounce'
                }
            }
        }
    },
};
export const absSplitConfig: any = {
    type: 'bar',
    data: {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
        datasets: [
          //Week 1
          {
            label: 'Call',
            data:[30, 25, 13, 11],
            backgroundColor: '#00aab9',

          },
          {
            label: 'Information error',
            data:[37, 15, 21, 38],
            backgroundColor:'#004f5f',

          },
          {
            label: 'Insufficient funds',
            data:[23, 10, 37, 4],
            backgroundColor:'#b8b8b8',

          }
        ]
    },
    options: {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
          },
        plugins: {
            legend: {
                display: false,
            },
            zoom: {
                zoom: {
                  wheel: {
                    enabled: true,
                  },
                  pinch: {
                    enabled: true
                  },
                  mode: 'x',
                },
                pan: {
                  enabled: true,
                  mode: 'x',
                },
              },
            },
        scales: {
            y: {
                stacked: true,
                title: {
                    display: true,
                    padding: { bottom: 20 },
                    text: 'No. of decline instances',
                },
                min: 0,
                max: 100,
                grid: {
                    borderWidth: 1,
                    borderColor: 'black',
                },
                ticks: {
                    count: 5,
                    stepSize: 20,
                    callback: function (value: any, index: any, ticks: any) {
                        return Math.round(value/1000) + 'k'
                    },
                },
            },
            x: {
                stacked: true,
                offset: true,
                title: {
                    display: true,
                    padding: { top: 10 },
                    text: 'Split of declines codes (Last 4 weeks)',
                },
                grid: {
                    display: false,
                    borderWidth: 1,
                    borderColor: 'black',
                },
            },
        },
        transitions: {
            zoom: {
              animation: {
                duration: 1000,
                easing: 'easeInBounce'
                }
            },
            pan: {
                animation:{
                    duration: 1000,
                    easing: 'easeInBounce'
                }
            }
        }
    },
};
