<div
    class="mobile-menu__container"
    @fadeSlideInOut
    (@fadeSlideInOut.done)="notifyAnimationDone($event)"
    *ngIf="isVisible">
    <div class="mobile-menu__header">
        <div class="flex-center">
            <img class="logo__picture" src="assets/quantafi-logo.png" alt="CMSPI logo" />
            <div class="logo__separator"></div>
            <!-- <h4 *ngIf="currentUserData" class="title">{{currentClient}}<small class="super">&reg;</small></h4> -->
            <img class="logo__client" src="{{logoSRC}}"  (error)="logoSRC = 'assets/logos/no-image.png'" alt="John Lewis logo" />
        </div>
        <div class="flex-row-end">
            <button class="mobile-menu__close-button" (click)="closeMobileMenu()">
                <img src="assets/icons/times.svg" alt="close icon" />
            </button>
        </div>
    </div>

    <div class="mobile-menu__greeting">
        <h5 class="title flex-center">Welcome,</h5>
        <h3 *ngIf="currentUserData">{{currentUserData.name}} {{currentUserData.surname}}</h3>
    </div>
    <div class="profile__select-client" *ngIf="currentUserData && currentUserData.clients.length > 1">
        <select class="minimal" #mySelect (change)='onOptionsSelected(mySelect.value)'>
            <option *ngFor="let client of currentUserData.clients"
            [value]="client.clientName"
            [selected]="client.clientName == currentClient">{{client.clientName}}</option>
        </select>
    </div>


    <div class="mobile-menu__buttons">
        <div
                class="mobile-menu__box"
                [routerLink]="['/'+currentClient,'overview']"
                (click)="closeMobileMenu()"
            >
                <svg width="26" height="28" viewBox="0 0 26 28">
                    <path
                        d="M25.085 10.3165L14.5699 0.786358C13.6601 -0.0570411 12.3101 -0.0570411 11.399 0.786358L0.883861 10.3165C0.664387 10.5349 0.538086 10.8169 0.538086 11.1294V25.5026C0.538086 26.7842 1.60479 27.8461 2.89222 27.8461H8.29198C9.57941 27.8461 10.6461 26.7842 10.6461 25.5026V18.9409C10.6461 18.847 10.7086 18.7847 10.8031 18.7847H15.1974C15.2919 18.7847 15.3544 18.847 15.3544 18.9409V25.5026C15.3544 26.7842 16.4211 27.8461 17.7085 27.8461H23.107C24.3945 27.8461 25.4612 26.7842 25.4612 25.5026V11.1294C25.4612 10.7852 25.3361 10.5044 25.0847 10.3165H25.085ZM15.1976 16.5976H10.8032C9.51581 16.5976 8.44911 17.6595 8.44911 18.9411V25.5028C8.44911 25.5968 8.38658 25.659 8.29217 25.659H2.89367C2.79925 25.659 2.73672 25.5968 2.73672 25.5028V11.5982L12.8755 2.41241C12.938 2.35016 13.0325 2.35016 13.095 2.41241L23.2338 11.6301V25.5968C23.2019 25.6286 23.1712 25.6591 23.1087 25.6591H17.709C17.6145 25.6591 17.552 25.5968 17.552 25.5028V18.9411C17.552 17.6595 16.4853 16.5977 15.1979 16.5977L15.1976 16.5976Z"
                    />
                </svg>
                <span routerLinkActive="active">Overview</span>
            </div>
            <div
                class="mobile-menu__box"
                [routerLink]="['/'+currentClient,'notifications']"
                (click)="closeMobileMenu()"
            >
                <svg width="24" height="27" viewBox="0 0 24 27">
                    <path
                        d="M12 0.538574C13.7928 0.538574 15.2574 2.00785 15.3407 3.85082C18.4643 5.225 20.569 8.43184 20.569 12.0619V17.0712C21.9699 17.1833 23.0769 18.4029 23.0769 19.8892C23.0769 21.4485 21.8572 22.7156 20.3551 22.7156H3.64495C2.14113 22.7156 0.923096 21.4501 0.923096 19.8892C0.923096 18.4029 2.02848 17.1833 3.43106 17.0712V12.0619C3.43106 8.43027 5.53574 5.22491 8.65933 3.85082C8.7426 2.00785 10.2088 0.538574 12 0.538574ZM10.0194 5.64096C7.36125 6.52764 5.52099 9.10949 5.52099 12.0618V17.2798C5.52099 18.3601 4.67848 19.2366 3.64164 19.2366C3.2955 19.2366 3.01302 19.5305 3.01302 19.8889C3.01302 20.249 3.2955 20.5411 3.64491 20.5411H20.355C20.7045 20.5411 20.9869 20.2473 20.9869 19.8889C20.9869 19.5305 20.7045 19.2366 20.3583 19.2366C19.3198 19.2366 18.479 18.3618 18.479 17.2798V12.0618C18.479 9.10962 16.6388 6.5276 13.9805 5.64096L13.2539 5.39976V4.01372C13.2539 3.29693 12.6922 2.71259 11.9999 2.71259C11.3093 2.71259 10.746 3.29693 10.746 4.01372V5.39976L10.0194 5.64096ZM8.02908 22.7153H10.119C10.119 23.7956 10.9616 24.6721 12 24.6721C13.0385 24.6721 13.881 23.7956 13.881 22.7153H15.971C15.971 24.9965 14.1929 26.8463 12 26.8463C9.80718 26.8463 8.02908 24.9965 8.02908 22.7153Z"
                    />
                </svg>

                <span routerLinkActive="active">Notifications</span>
            </div>
            <div
                class="mobile-menu__box"
                [routerLink]="['/report']"
                (click)="closeMobileMenu()"
                style="display:none"
            >
                <svg width="24" height="28" viewBox="0 0 24 28">
                    <path
                        d="M23.16 6.82248C23.1065 6.68467 23.0264 6.5581 22.9237 6.45125L17.2987 0.82625C17.1919 0.723592 17.0653 0.643438 16.9275 0.59C16.7925 0.532342 16.6477 0.501408 16.5 0.5H4.125C2.26171 0.5 0.75 2.01171 0.75 3.875V24.125C0.75 25.9883 2.26171 27.5 4.125 27.5H19.875C21.7383 27.5 23.25 25.9883 23.25 24.125V7.25C23.2486 7.10234 23.2177 6.9575 23.16 6.8225L23.16 6.82248ZM17.625 4.33625L19.4138 6.12502H17.625V4.33625ZM21 24.1251C21 24.4232 20.8819 24.7101 20.6709 24.921C20.46 25.132 20.1731 25.2501 19.875 25.2501H4.125C3.50342 25.2501 3 24.7467 3 24.1251V3.87508C3 3.2535 3.50342 2.75008 4.125 2.75008H15.375V6.12508C15.375 6.72131 15.6127 7.29367 16.0345 7.71556C16.4564 8.13744 17.0287 8.37508 17.625 8.37508H21L21 24.1251Z"
                    />
                </svg>

                <span routerLinkActive="active">Report Builder</span>
            </div>
            <div
                class="mobile-menu__box"
                (click)="logout()"
            >
                <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.47741 22.7187C9.09754 22.7187 9.60144 23.2235 9.60144 23.8427C9.60144 24.4628 9.09754 24.9667 8.47741 24.9667H3.98316C3.53101 24.9667 3.08961 24.8778 2.67651 24.705C2.27416 24.539 1.91479 24.2987 1.60424 23.994C1.29564 23.6864 1.04954 23.329 0.880611 22.9315C0.701901 22.5165 0.612061 22.077 0.612061 21.6249V3.61915C0.612061 3.1631 0.701906 2.72267 0.878661 2.30762C1.04761 1.90527 1.29174 1.54395 1.59839 1.23535C1.90991 0.926745 2.26929 0.68262 2.67066 0.512695C3.08766 0.33789 3.52906 0.24707 3.98316 0.24707H8.47741C9.09754 0.24707 9.60144 0.75097 9.60144 1.3711C9.60144 1.99317 9.09754 2.49512 8.47741 2.49512H3.98316C3.36304 2.49512 2.85914 2.99902 2.85914 3.61915V21.6249C2.85914 22.2274 3.36304 22.7186 3.98316 22.7186L8.47741 22.7187ZM21.6824 11.6259C21.9881 11.832 22.1834 12.1669 22.1834 12.6064C22.1834 13.0497 21.9881 13.3828 21.6824 13.5888L18.2117 17.0596C17.7302 17.54 17.0076 17.54 16.5271 17.0596C16.0456 16.5781 16.0456 15.8555 16.5271 15.376L18.0906 13.8105H10.1501C9.42741 13.8105 8.94596 13.3301 8.94596 12.6064C8.94596 11.8857 9.42741 11.4043 10.1501 11.4043H18.0906L16.5271 9.84082C16.0456 9.35839 16.0456 8.63672 16.5271 8.15429C17.0076 7.67284 17.7302 7.67284 18.2117 8.15429L21.6824 11.6259Z" fill="white"/>
                </svg>

                <span routerLinkActive="active">Logout</span>
            </div>
    </div>
</div>
