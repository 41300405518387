<div class="alert-card">
    <div class="alert-card__header flex-center">
        <span>{{title}}</span>
    </div>
    <div *ngIf="profileCardData < 0" class="alert-card__content flex-center">
        <h2 class="negative">{{ profileCardData | datFormat:"profileCardData"}}</h2>
        <img
            src="assets/icons/arrow-down.svg"
            alt="down arrow icon"
        />
    </div>
    <div *ngIf="profileCardData >= 0" class="alert-card__content flex-center">
        <h2 class="neutral">{{ profileCardData | datFormat:"profileCardData"}}</h2>

    </div>
</div>
