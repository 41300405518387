import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { InfoDrawerComponent } from './info-drawer/info-drawer.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { LoadingAnimationComponent } from './loading-animation/loading-animation.component';



@NgModule({
  declarations: [
    DropdownButtonComponent,
    ToggleButtonComponent,
    InfoDrawerComponent,
    LoadingAnimationComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DropdownButtonComponent,
    ToggleButtonComponent,
    InfoDrawerComponent,
    LoadingAnimationComponent,
  ]
})
export class SharedModule { }
