import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MetricRate } from 'src/app/services/impact/impact';

@Component({
    selector: 'app-impact-card',
    templateUrl: './impact-card.component.html',
    styleUrls: ['./impact-card.component.css'],
})
export class ImpactCardComponent implements OnInit {

    @Input() impactCard: MetricRate;
    @Input() position:number;
    @Input() cardIndex:number;
    @Input() show:boolean;
    active:boolean=false
    tooltipText: string;
    constructor() {}

    ngOnInit(): void {
        this.tooltipText = this.impactCard.metric.metricDescription;

    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['show'] || changes['cardIndex']) {
            if(this.position==this.cardIndex && this.show){
                this.active=true
            } else {
                this.active=false
            }
        }
    }
}
