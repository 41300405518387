import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { kpiCard } from 'src/app/services/alert-details/alert-detail';

@Component({
  selector: 'app-alert-details-decline-chart-section',
  templateUrl: './alert-details-decline-chart-section.component.html',
  styleUrls: ['./alert-details-decline-chart-section.component.css'],
  animations: [
    trigger('fade', [
        transition(':enter', [
            style({ opacity: 0, transform: 'scaleY(0)' }),
            animate(
                '.3s ease',
                style({ opacity: 1, transform: 'scaleY(1)' })
            ),
        ]),
        transition(':leave', [
            style({ opacity: 1, transform: 'scaleY(1)' }),
            animate(
                '.3s ease',
                style({ opacity: 0, transform: 'scaleY(0)' })
            ),
        ]),
    ]),
],
})
export class AlertDetailsDeclineChartSectionComponent implements OnInit {
  @Input() cardIndex: number = 0;
  @Input() show: boolean = false;
  @Input() qCards: number;
  chartType: string = 'bar';
  isSlider:boolean=false;
  @Input() navTitle:string='% Split of decline codes'
  @Input() declineChartResponseDetails:any
  @Input() declineChartDetails:any
  @Input() kpiCards:kpiCard[]


  constructor() {}

  ngOnInit(): void {   }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['show']){
       this.changeTitle()
    }
}



    fieldsChange(values:any):void {
        this.isSlider=values.currentTarget.checked;
        this.changeTitle()
    }

    changeTitle():void{
        if(this.show){
            this.navTitle='Change in ' + this.kpiCards[this.cardIndex].problemResponseMessage + ' in last 7 days' //Future place to change the title of the kpi cards
          } else {
              if(this.isSlider){
                  this.navTitle='Absolute split of decline codes'
              }else{
                  this.navTitle='% Split of decline codes'
              }
          }
    }
}
