import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Alert, BodyAlert, BodyPin, BodyPinned } from './alert';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AlertsService {

    private apiUrl = environment.apiURL;
    private alertsUrl = 'alertlist';
    private topAlertsUrl = 'topalerts';
    private getPinnedUrl = 'getalertpinneditems'
    private setPinnedUrl = 'putalertpinneditems'

    //private clientId = 'a31e7896-e5f8-b2bc-4dd8-8a40dc653cde' // only for dev porposes

    httpOptions = {
        headers: new HttpHeaders(
                      { 'Content-Type': 'application/json',
                        'Authorization': sessionStorage.getItem('token')!,
                        'Ocp-Apim-Subscription-Key': environment.subscriptionKey }
        )
        //INI - ToDo: remove again after API change
        ,
        params: { code: environment.authenticationCodeAPIM },
        //END - ToDo
    }

    constructor(
      private http: HttpClient
    ) { }

    /** GET alerts from the server */
    getAlerts(clientName:string, body:BodyAlert): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/${this.alertsUrl}/${clientName}`, body, this.httpOptions)
          .pipe(
            tap(_ => console.log('AlertService', 'Alerts list')),
            catchError(this.handleError<any>('getAlerts', []))
          );
    }

    /** GET alert by id. Will 404 if id not found */
    getAlert(id: number): Observable<Alert> {
      const url = `${this.apiUrl}/${this.alertsUrl}/${id}`;
      return this.http.get<Alert>(url).pipe(
        tap(_ => this.log(`fetched alert id=${id}`)),
        catchError(this.handleError<Alert>(`getAlert id=${id}`))
      );
    }

    getTopAlerts(clientName:string): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/${this.topAlertsUrl}/${clientName}`,  this.httpOptions)
          .pipe(
            tap(_ => console.log('AlertService', 'Top Alerts')),
            catchError(this.handleError<any>('getTopAlerts', []))
          );
      }

    getPinnedAlerts(clientName:string, bodyPinned:BodyPinned): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/${this.getPinnedUrl}/${clientName}`, bodyPinned, this.httpOptions)
        .pipe(
        tap(_ => console.log('AlertService', 'get Pinned alerts')),
        catchError(this.handleError<any>('getPinnedAlerts', []))
        );
    }

    setPinnedAlerts(clientName:string, body:BodyPin): Observable<any> {
        body.ClientName = clientName;
        return this.http.put<any>(`${this.apiUrl}/${this.setPinnedUrl}`, body, this.httpOptions)
            .pipe(
            tap(_ => console.log('AlertService', 'set Pinned alerts')),
            catchError(this.handleError<any>('setPinnedAlerts', []))
            );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     *
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);

        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }

    /** Log a AlertService message with the MessageService */
    private log(message: string) {
      console.log(`AlertService: ${message}`);
    }

  }
