<nav>
    <div class="nav-menu">
        <div class="nav-menu__back">
            <a (click)="goBack()">
                <img src="assets/icons/arrow-left.svg" alt="left arrow icon" />
                <span>BACK</span>
            </a>
        </div>
        <div class="nav-menu__date">
            <img src="{{imageSRC}}"  (error)="imageSRC = 'assets/logos/no-image.png'" alt="credit card logo" />
            <span>{{textInformation.alertInsertedDate | datFormat:"navAlertDate"}}</span>
        </div>


        <div class="nav-menu__buttons">
            <button (click)="openModal('shared-modal')">
                <svg width="13" height="15" viewBox="0 0 13 15">
                    <path
                        d="M9.625 9.5C8.99609 9.5 8.42188 9.71875 7.98438 10.0742L5.16797 8.32422C5.22266 8.16016 5.22266 7.96875 5.22266 7.75C5.22266 7.55859 5.22266 7.36719 5.16797 7.20312L7.98438 5.45312C8.42188 5.80859 8.99609 6 9.625 6C11.0742 6 12.25 4.85156 12.25 3.375C12.25 1.92578 11.0742 0.75 9.625 0.75C8.14844 0.75 7 1.92578 7 3.375C7 3.59375 7 3.78516 7.05469 3.94922L4.23828 5.69922C3.80078 5.34375 3.22656 5.125 2.625 5.125C1.14844 5.125 0 6.30078 0 7.75C0 9.22656 1.14844 10.375 2.625 10.375C3.22656 10.375 3.80078 10.1836 4.23828 9.82812L7.05469 11.5781C7 11.7422 7 11.9336 7 12.1523V12.125C7 13.6016 8.14844 14.75 9.625 14.75C11.0742 14.75 12.25 13.6016 12.25 12.125C12.25 10.6758 11.0742 9.5 9.625 9.5Z"
                        fill="#212121"
                    />
                </svg>
            </button>
            <button style="display:none">
                <svg width="13" height="16" viewBox="0 0 13 16">
                    <path
                        d="M7.4375 4.85938V0.875H1.57812C1.16797 0.875 0.875 1.19727 0.875 1.57812V15.1719C0.875 15.582 1.16797 15.875 1.57812 15.875H11.4219C11.8027 15.875 12.125 15.582 12.125 15.1719V5.5625H8.14062C7.73047 5.5625 7.4375 5.26953 7.4375 4.85938ZM9.66406 11.0703L6.85156 13.8828C6.64648 14.0586 6.32422 14.0586 6.11914 13.8828L3.30664 11.0703C3.01367 10.7773 3.21875 10.25 3.62891 10.25H5.5625V7.90625C5.5625 7.67188 5.76758 7.4375 6.03125 7.4375H6.96875C7.20312 7.4375 7.4375 7.67188 7.4375 7.90625V10.25H9.3418C9.75195 10.25 9.95703 10.7773 9.66406 11.0703ZM11.9199 3.95117L9.04883 1.08008C8.90234 0.962891 8.72656 0.875 8.55078 0.875H8.375V4.625H12.125V4.44922C12.125 4.27344 12.0371 4.09766 11.9199 3.95117Z"
                        fill="#212121"
                    />
                </svg>
            </button>
        </div>

    </div>
</nav>

<app-modal id="shared-modal">
    <p class="title">Share this page</p>
    <div class="email-container">
        <p class="email">
            Send this information to:

        </p>
        <input class="e-input" type="email" [(ngModel)]="email" placeholder="{{placeholderText}}" ngModel [email]="true" />
    </div>

    <div class="button-container">
        <button class="send-button" (click)="sendModal('shared-modal');">Confirm</button>
        <button class="dismiss-button" (click)="closeModal('shared-modal');">Dismiss</button>
    </div>
</app-modal>

<app-modal id="shared-response">
    <p class="title">Response</p>
    <div class="email-container">
        <p class="e-info">
            Info was sent to the <span>{{responseEmail}}</span> destination.
        </p>
    </div>
    <div class="button-container">
        <button class="dismiss-button" (click)="closeModal('shared-response');">OK</button>
    </div>
</app-modal>

<app-modal id="shared-error">
    <p class="title">Error</p>
    <div class="email-container">
        <p class="e-info">
            Info was not sent to the <span>{{responseEmail}}</span> destination.
            Error information: <span>{{sharingError}}</span>
        </p>
    </div>
    <div class="button-container">
        <a href="mailto:quantafi_test@cmspi.com?cc=quantafi_test@cmspi.com&bcc=quantafi_test@cmspi.com&subject=Contact%20us%20-%20Sharing%20Error">
            <button class="send-button" (click)="closeModal('shared-error');">Contact CMSPI</button>
        </a>
        <button class="dismiss-button" (click)="closeModal('shared-error');">OK</button>
    </div>
</app-modal>
