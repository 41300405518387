import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Alert } from 'src/app/services/alerts/alert';

@Component({
    selector: 'app-top-alert-card',
    templateUrl: './top-alert-card.component.html',
    styleUrls: ['./top-alert-card.component.css'],
})
export class TopAlertCardComponent implements OnInit {
    @Input() topAlert: Alert;
    imageSRC: string
    currentClient = this.route.snapshot.paramMap.get('clientName') || ''

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        if (this.topAlert.issuer){
             this.imageSRC = 'assets/logos/Issuers/'+ this.topAlert.issuer + ".png"
        } else if(this.topAlert.network){
            this.imageSRC = 'assets/logos/Networks/'+ this.topAlert.network + ".png"
        } else if(this.topAlert.currency){
            this.imageSRC = 'assets/logos/Currencies/'+ this.topAlert.currency + ".png"
        } else if(this.topAlert.cardType){
            this.imageSRC = 'assets/logos/CardTypes/'+ this.topAlert.cardType + ".png"
        }
    }
}
