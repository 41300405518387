<div 
    class="filters-panel__container"
    @fadeSlideInOut 
    (@fadeSlideInOut.done)="notifyAnimationDone($event)"
    *ngIf="isVisible">

    <header class="filters-panel__header">
        <a (click)="cancelFilter()">Cancel</a>
        <h4>Filters</h4>
        <a (click)="applyFilters()">Apply</a>
    </header>

    <main class="filters-panel__main">
        <div class="main__scrollable">
            <!-- TYPE ALERT -->
            <section class="typeAlert" *ngIf="filterData.typeAlert">
                <h5>Type of alert</h5>
                <div class="buttons__container">
                    <app-toggle-button 
                        *ngFor="let item of filterData.typeAlert; let i = index;"
                        [text]="item.description"
                        [index]="i"
                        [itemId]="item.id"
                        [active]="checkActive(item.id, 'typeAlert')"
                        (toggled)="onFilterToggled($event, 'typeAlert')">
                    </app-toggle-button>
                </div>
            </section>
            <!-- SUPPLIER -->
            <section class="network" *ngIf="filterData.network">
                <h5>Network</h5>
                <div class="buttons__container">
                    <app-toggle-button 
                        *ngFor="let item of filterData.network; let i = index;"
                        [text]="item.description"
                        [index]="i"
                        [itemId]="item.id"
                        [active]="checkActive(item.id, 'network')"
                        (toggled)="onFilterToggled($event, 'network')">
                    </app-toggle-button>
                </div>
            </section>
            <!-- CURRENCY -->
            <section class="currency" *ngIf="filterData.currency">
                <h5>Currency</h5>
                <div class="buttons__container">
                    <app-toggle-button 
                        *ngFor="let item of filterData.currency; let i = index;"
                        [text]="item.description"
                        [index]="i"
                        [itemId]="item.id"
                        [active]="checkActive(item.id, 'currency')"
                        (toggled)="onFilterToggled($event, 'currency')">
                    </app-toggle-button>
                </div>
            </section>
            <!-- ISSUER -->
            <section class="issuer" *ngIf="filterData.issuer">
                <h5>Issuer</h5>
                <div class="buttons__container">
                    <app-toggle-button 
                        *ngFor="let item of filterData.issuer; let i = index;"
                        [text]="item.description"
                        [index]="i"
                        [itemId]="item.id"
                        [active]="checkActive(item.id, 'issuer')"
                        (toggled)="onFilterToggled($event, 'issuer')">
                    </app-toggle-button>
                </div>
            </section>
            <!-- CARD TYPE -->
            <section class="cardType" *ngIf="filterData.cardType">
                <h5>Card Type</h5>
                <div class="buttons__container">
                    <span></span>
                    <app-toggle-button 
                        *ngFor="let item of filterData.cardType; let i = index;"
                        [text]="item.description"
                        [index]="i"
                        [itemId]="item.id"
                        [active]="checkActive(item.id, 'cardType')"
                        (toggled)="onFilterToggled($event, 'cardType')">
                    </app-toggle-button>
                </div>
            </section>
            <!-- DATE -->
            <section class="date" *ngIf="filterData.date">
                <h5>Date</h5>
                <div class="buttons__container">
                    <app-toggle-button 
                        *ngFor="let item of filterData.date; let i = index;"
                        [text]="item.description"
                        [index]="i"
                        [itemId]="item.id"
                        [active]="checkActive(item.id, 'date')"
                        (toggled)="onFilterToggled($event, 'date')">
                    </app-toggle-button>
                </div>
            </section>
            <!-- STATUS -->
            <section class="status" *ngIf="filterData.status">
                <h5>Status</h5>
                <div class="buttons__container">
                    <app-toggle-button 
                        *ngFor="let item of filterData.status; let i = index;"
                        [text]="item.description"
                        [index]="i"
                        [itemId]="item.id"
                        [active]="checkActive(item.id, 'status')"
                        (toggled)="onFilterToggled($event, 'status')">
                    </app-toggle-button>
                </div>
            </section>
        </div>
    </main>

    <footer class="filters-panel__footer">
        <button 
            (click)="cancelFilter()"
            class="footer-button filters-panel__cancel-button">
            Cancel
        </button>
        <button
            (click)="applyFilters()" 
            class="footer-button filters-panel__submit-button">
            Apply Filters
        </button>
    </footer>
</div>