import {
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import Chart from 'chart.js/auto';
import { alertDetail } from 'src/app/services/alert-details/alert-detail';
import { lineConfig } from './chart-config';

@Component({
    selector: 'app-alert-details-approval-rate-chart',
    templateUrl: './alert-details-approval-rate-chart.component.html',
    styleUrls: ['./alert-details-approval-rate-chart.component.css']
})
export class AlertDetailsApprovalRateChartComponent
    implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input() chartType: string = 'line';
    @Input() alertsDetailChart: alertDetail[];
    @Input() chartPeriod: number;
    myChart: Chart | undefined;
    currentYear: number = new Date().getFullYear();
    periodLabel: string = '2 weeks';


    constructor() { }

    ngAfterViewInit(): void {
        //const config = { ...lineConfig };
        this.myChart = new Chart('chart-canvas-approval-rate', lineConfig);
        lineConfig.options.scales.x.title.text = this.currentYear;
        this.setConfig(this.chartPeriod);

    }

    ngOnDestroy(): void {
        this.myChart?.destroy();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['chartPeriod']) {
            this.setConfig(this.chartPeriod);
        }
    }

    ngOnInit(): void {
    }

    setConfig(chartPeriod: number): void {
        if (this.myChart) {
            switch (chartPeriod) {
                case 1:
                    var max1 = Math.max.apply(null, this.alertsDetailChart[0].clientData);
                    var max2 = Math.max.apply(null, this.alertsDetailChart[0].profileData);
                    var max = Math.max(max1, max2);
                    var min1 = Math.min.apply(null, this.alertsDetailChart[0].clientData);
                    var min2 = Math.min.apply(null, this.alertsDetailChart[0].profileData);
                    var min = Math.min(min1, min2);
                    lineConfig.options.scales.y.max = max*1.05 > 100 ? 100 : max*1.05;
                    lineConfig.options.scales.y.min = Math.round(min*0.95);

                    lineConfig.data.datasets[0].data = this.alertsDetailChart[0].profileData;
                    lineConfig.data.datasets[1].data = this.alertsDetailChart[0].clientData;
                    lineConfig.options.scales.x.labels = this.alertsDetailChart[0].alertDateLabel;

                    switch (this.alertsDetailChart[0].type) {
                        case 1:
                            lineConfig.options.scales.y.title.text = 'Your gross approval rate';
                            this.periodLabel = '2 weeks'
                            break;
                        case 2:
                            lineConfig.options.scales.y.title.text = 'Your net approval rate';
                            this.periodLabel = '2 weeks'
                            break;
                        case 3:
                            lineConfig.options.scales.y.title.text = 'Your chargeback rate';
                            this.periodLabel = '4 weeks'
                            break;
                        case 4:
                            lineConfig.options.scales.y.title.text = 'Your fraud rate';
                            this.periodLabel = '4 weeks'
                            break;
                    }
                    
                    break;
                case 2:
                    var max1 = Math.max.apply(null, this.alertsDetailChart[1].clientData);
                    var max2 = Math.max.apply(null, this.alertsDetailChart[1].profileData);
                    var max = Math.max(max1, max2);
                    var min1 = Math.min.apply(null, this.alertsDetailChart[1].clientData);
                    var min2 = Math.min.apply(null, this.alertsDetailChart[1].profileData);
                    var min = Math.min(min1, min2);
                    lineConfig.options.scales.y.max = max*1.05 > 100 ? 100 : max*1.05;
                    lineConfig.options.scales.y.min = Math.round(min*0.95);

                    lineConfig.data.datasets[0].data = this.alertsDetailChart[1].profileData;
                    lineConfig.data.datasets[1].data = this.alertsDetailChart[1].clientData;
                    lineConfig.options.scales.x.labels = this.alertsDetailChart[1].alertDateLabel;

                    switch (this.alertsDetailChart[1].type) {
                        case 1:
                            lineConfig.options.scales.y.title.text = 'Your gross approval rate';
                            this.periodLabel = 'month'
                            break;
                        case 2:
                            lineConfig.options.scales.y.title.text = 'Your net approval rate';
                            this.periodLabel = 'month'
                            break;
                        case 3:
                            lineConfig.options.scales.y.title.text = 'Your chargeback rate';
                            this.periodLabel = '3 months'
                            break;
                        case 4:
                            lineConfig.options.scales.y.title.text = 'Your fraud rate';
                            this.periodLabel = '3 months'
                            break;
                    }

                    
                    break;
                case 3:
                    var max1 = Math.max.apply(null, this.alertsDetailChart[2].clientData);
                    var max2 = Math.max.apply(null, this.alertsDetailChart[2].profileData);
                    var max = Math.max(max1, max2);
                    var min1 = Math.min.apply(null, this.alertsDetailChart[2].clientData);
                    var min2 = Math.min.apply(null, this.alertsDetailChart[2].profileData);
                    var min = Math.min(min1, min2);
                    lineConfig.options.scales.y.max = max*1.05 > 100 ? 100 : max*1.05;
                    lineConfig.options.scales.y.min = Math.round(min*0.95);

                    lineConfig.data.datasets[0].data = this.alertsDetailChart[2].profileData;
                    lineConfig.data.datasets[1].data = this.alertsDetailChart[2].clientData;
                    lineConfig.options.scales.x.labels = this.alertsDetailChart[2].alertDateLabel;

                    switch (this.alertsDetailChart[2].type) {
                        case 1:
                            lineConfig.options.scales.y.title.text = 'Your gross approval rate';
                            break;
                        case 2:
                            lineConfig.options.scales.y.title.text = 'Your net approval rate';
                            break;
                        case 3:
                            lineConfig.options.scales.y.title.text = 'Your chargeback rate';
                            break;
                        case 4:
                            lineConfig.options.scales.y.title.text = 'Your fraud rate';
                            break;
                    }

                    this.periodLabel = 'year'
                    break;
                default:
                    var max1 = Math.max.apply(null, this.alertsDetailChart[0].clientData);
                    var max2 = Math.max.apply(null, this.alertsDetailChart[0].profileData);
                    var max = Math.max(max1, max2);
                    var min1 = Math.min.apply(null, this.alertsDetailChart[0].clientData);
                    var min2 = Math.min.apply(null, this.alertsDetailChart[0].profileData);
                    var min = Math.min(min1, min2);
                    lineConfig.options.scales.y.max = max*1.05 > 100 ? 100 : max*1.05;
                    lineConfig.options.scales.y.min = Math.round(min*0.95);

                    lineConfig.data.datasets[0].data = this.alertsDetailChart[0].profileData;
                    lineConfig.data.datasets[1].data = this.alertsDetailChart[0].clientData;
                    lineConfig.options.scales.x.labels = this.alertsDetailChart[0].alertDateLabel;

                    switch (this.alertsDetailChart[0].type) {
                        case 1:
                            lineConfig.options.scales.y.title.text = 'Your gross approval rate';
                            break;
                        case 2:
                            lineConfig.options.scales.y.title.text = 'Your net approval rate';
                            break;
                        case 3:
                            lineConfig.options.scales.y.title.text = 'Your chargeback rate';
                            break;
                        case 4:
                            lineConfig.options.scales.y.title.text = 'Your fraud rate';
                            break;
                    }
            }

            this.myChart.update();
        }
    }

}
