import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';

@Pipe({
  name: 'datFormat'
})
export class DatFormatPipe implements PipeTransform {

  constructor(private dp: DecimalPipe, private fp: DatePipe) { }

  transform(value: any, ...args: any[]): String | any {

    var output: string = "";

    if (value != null) {
      //INIT - Section for text
      if (args[1] == "impChartLab") {
        output = value.replace("Client", "").toLowerCase();
        return output;
      }
      //END - Section for text

      //INIT - Section for values
      if (args[0] == "metricRate") {
        output = this.dp.transform(value, "1.2-2") + '%';
        return output;
      }
      else if (args[0] == "metricRateExt") {
        output = this.dp.transform(value, "1.4-4") + '%';
        return output;
      }
      else if (args[0] == "vsCluster") {
        output = this.dp.transform(value, "1.0-0") + '%';
        return output;
      }
      else if (args[0] == "metricPcnt" || args[0] == "profileCardData") {
        output = this.dp.transform(value, "1.0-2") + '%';
        return output;
      } else if (args[0] == "impactValueUSD") {
        output = (value > 0 ? "+" : "-");
        var val = value > 0 ? value / 1000000 > 1 ? value / 1000000 : value / 1000 : value * -1 / 1000000 > 1 ? value * -1 / 1000000 : value * -1 / 1000;
        output += this.dp.transform(val, "1.0-2");;
        output += value > 0 ? (value / 1000000 > 1 ? " million" : "k") : (value * -1 / 1000000 > 1 ? " million" : "k");
        return output;
      } else if (args[0] == "topMonthlyImpactUSD") {
        var val = value / 1000000 > 1 ? value / 1000000 : value / 1000;
        output = "$";
        output += this.dp.transform(val, "1.0-2");
        output += value > 0 ? (value / 1000000 > 1 ? " million" : "k") : (value * -1 / 1000000 > 1 ? " million" : "k");
        return output;
      } else if (args[0] == "monthlyImpactUSD") {
        output = (value > 0 ? "" : "-");
        var val = value > 0 ? value / 1000000 > 1 ? value / 1000000 : value / 1000 : value * -1 / 1000000 > 1 ? value * -1 / 1000000 : value * -1 / 1000;
        output += this.dp.transform(val, "1.0-2");;
        output += value > 0 ? (value / 1000000 > 1 ? " million" : "k") : (value * -1 / 1000000 > 1 ? " million" : "k");
        return output;
      } else if (args[0] == "impactDate") {
        output = "" + this.fp.transform(value, 'yyyy/MM/dd');
        //Bug#23 - this.fp.transform(value, 'dd/MM/yyyy');
        return output;
      } else if (args[0] == "refreshDate" || args[0] == "navAlertDate") {
        output = "" + this.fp.transform(value, 'yyyy/MM/dd');
        return output;
      }
      //END - Section for values

    }

    return null;
  }

}
