import { Component, HostBinding, HostListener, Inject } from '@angular/core';
import { MediaQueryControlService } from './shared/services/media-query-control.service';
import { Subject } from 'rxjs';
import {
    MsalBroadcastService,
    MsalGuardConfiguration,
    MsalService,
    MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import {
    AuthenticationResult,
    InteractionStatus,
    InteractionType,
    PopupRequest,
    RedirectRequest,
} from '@azure/msal-browser';
import { AccountService } from './services/account/account.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {
    isIframe = false;
    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();
    @HostBinding('style.--h4-font-size') h4FontSize: string;

    constructor(
        mediaQueryControl: MediaQueryControlService,
        @Inject(MSAL_GUARD_CONFIG)
        private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private accountService: AccountService,
        public router:Router
    ) {
        this.getGlobalStylesAccordingToLayout(mediaQueryControl);
    }

    private setupAnalytics(){
        this.router.events.subscribe(event => {
            if(event instanceof NavigationEnd){
                gtag('config', environment.measurementID, 
                      {
                        'page_path': event.urlAfterRedirects
                      }
                     );
             }
          }
       )
    }

    private getGlobalStylesAccordingToLayout(
        mediaQueryControl: MediaQueryControlService
    ) {
        mediaQueryControl.layout.subscribe((layout) => {
            const isMobile = layout == 'mobile';
            this.h4FontSize = isMobile ? '1.15rem' : '1.5rem';
        });
    }

    async ngOnInit(): Promise<void> {
        this.setupAnalytics();
        this.isIframe = window !== window.parent && !window.opener;

        /**
         * You can subscribe to MSAL events as shown below. For more info,
         * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
         */
        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) =>
                        status === InteractionStatus.None
                ),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
            });

        await this.accountService.getAuthenticationFlow();
    }

    setLoginDisplay() {
        this.loginDisplay =
            this.authService.instance.getAllAccounts().length > 0;
    }

    login(userFlowRequest?: RedirectRequest | PopupRequest) {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.authService
                    .loginPopup({
                        ...this.msalGuardConfig.authRequest,
                        ...userFlowRequest,
                    } as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(
                            response.account
                        );
                    });
            } else {
                this.authService
                    .loginPopup(userFlowRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(
                            response.account
                        );
                    });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginRedirect({
                    ...this.msalGuardConfig.authRequest,
                    ...userFlowRequest,
                } as RedirectRequest);
            } else {
                this.authService.loginRedirect(userFlowRequest);
            }
        }
    }

    logout() {
        this.accountService.logout();
    }

    @HostListener('window:storage', ['$event'])
    onStorageChange($event: any): void {
        if ($event.key === 'logout') {
            this.accountService.logoutOtherTabs()
        }
    }

    ngOnDestroy(): void {
        sessionStorage.clear();
        localStorage.clear();
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
