<app-loading-animation  *ngIf="!alertsDetailChart"></app-loading-animation>
<section  *ngIf="alertsDetailChart" class="chart-section">
    <div class="chart-section__nav flex-center space-between">
        <div class="chart-section__nav__title">
            <div>{{titleLab}}</div>
        </div>
        <div class="chart-section__nav__buttons flex-center space-between">
            <div class="chart-section__nav__buttons">
                <button
                (click)="setChartPeriod(1)"
                [ngClass]="{ active: chartPeriod === 1 }">{{periodLabs[0]}}</button>
                <button
                (click)="setChartPeriod(2)"
                [ngClass]="{ active: chartPeriod === 2 }">{{periodLabs[1]}}</button>
                <button
                (click)="setChartPeriod(3)"
                [ngClass]="{ active: chartPeriod === 3 }">{{periodLabs[2]}}</button>
            </div>

            <div class="chart-section__nav__options">
                <svg width="14" height="12" viewBox="0 0 14 12">
                    <path
                        d="M1.03542 2.05132H12.964C13.4682 2.05132 13.8598 1.65977 13.8598 1.15554C13.8598 0.651318 13.4682 0.259766 12.964 0.259766H1.03542C0.5312 0.259766 0.139648 0.651318 0.139648 1.15554C0.139648 1.65977 0.5312 2.05132 1.03542 2.05132Z"
                        fill="black"
                    />
                    <path
                        d="M1.03542 6.89604H12.964C13.4682 6.89604 13.8598 6.50449 13.8598 6.00027C13.8598 5.49605 13.4682 5.10449 12.964 5.10449H1.03542C0.5312 5.10449 0.139648 5.49605 0.139648 6.00027C0.139648 6.50449 0.5312 6.89604 1.03542 6.89604Z"
                        fill="black"
                    />
                    <path
                        d="M12.964 11.7398C13.4682 11.7398 13.8598 11.3482 13.8598 10.844C13.8598 10.3398 13.4682 9.94824 12.964 9.94824H1.03542C0.5312 9.94824 0.139648 10.3398 0.139648 10.844C0.139648 11.3482 0.5312 11.7398 1.03542 11.7398H12.964Z"
                        fill="black"
                    />
                </svg>
            </div>
        </div>
    </div>
    <app-alert-details-approval-rate-chart
        [alertsDetailChart]="alertsDetailChart"
        [chartPeriod]="chartPeriod"
    ></app-alert-details-approval-rate-chart>
</section>
