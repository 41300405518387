import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ImpactCardService {

    private urlApi = environment.apiURL;
    private urlOverallImpact = 'getoverallmonthlyimpact';
    private urlMetricCards = 'metriccards';

    //Only for dev purposes

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('token')!,
            'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
        })
        //INI - ToDo: remove again after API change
        ,
        params: { code: environment.authenticationCodeAPIM },
        //END - ToDo
    };

    constructor(private http: HttpClient) {}

    /** GET Impact Cards from the server */
    getImpactCards(clientName:string): Observable<any> {
        return this.http
            .get<any>(
                `${this.urlApi}/${this.urlMetricCards}/${clientName}`,
                this.httpOptions
            )
            .pipe(
                tap((_) => console.log('ImpactCardService', 'fetched Impact')),
                catchError(this.handleError<any>('getImpactCards', []))
            );
    }

    /** GET OverallImpact from the server */
    getOverallImpact(clientName:string): Observable<any> {
        return this.http
            .get<any>(
                `${this.urlApi}/${this.urlOverallImpact}/${clientName}`,
                this.httpOptions
            )
            .pipe(
                tap((_) => console.log('ImpactCardService', 'fetched OverallImpact')),
                catchError(this.handleError<any>('getOverallImpact', []))
            );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     *
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            console.log(
                'ImpactCardService',
                `${operation} failed: ${error.message}`
            );

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
