import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type Layout = "mobile"|"tablet"|"desktop";
export type Orientation = "portrait"|"landscape";

@Injectable({
  providedIn: 'root'
})
export class MediaQueryControlService {

  private currentLayout = new BehaviorSubject<Layout>("desktop");
  private currentOrientation = new BehaviorSubject<Orientation>("landscape");

  constructor(breakpointObserver: BreakpointObserver) {
    this.observeMobileLayout(breakpointObserver);
    this.observeTabletLayout(breakpointObserver);
    this.observeDesktopLayout(breakpointObserver);
  }

  public get layout() { return this.currentLayout.asObservable(); }
  public get orientation() { return this.currentOrientation.asObservable(); } 

  private setCurrentLayoutAndOrientation(layout: Layout, orientation: Orientation) {
    this.currentLayout.next(layout);
    this.currentOrientation.next(orientation);
  }

  private observeDesktopLayout(breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      Breakpoints.Large,
      Breakpoints.XLarge,
      Breakpoints.WebLandscape,
    ]).subscribe(result => {
      if (result.matches) {
        this.setCurrentLayoutAndOrientation("desktop", "landscape");
        console.log("Changing layout to desktop");
      }
    });
  }

  private observeTabletLayout(breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      Breakpoints.Small,
      Breakpoints.TabletPortrait,
    ]).subscribe(result => {
      if (result.matches) {
        this.setCurrentLayoutAndOrientation("tablet", "portrait");
        console.log("Changing layout to tablet");
      }
    });
  }

  private observeMobileLayout(breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.HandsetPortrait,
    ]).subscribe(result => {
      if (result.matches) {
        this.setCurrentLayoutAndOrientation("mobile", "portrait");
        console.log("Changing layout to mobile");
      }
    });
  }
}
