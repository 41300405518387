<section class="details-decline-section">
    <div class="details-decline-section__title">
        <h1>Alert details of decline responses</h1>
    </div>
    <app-loading-animation *ngIf="!kpiCards && !loaded"></app-loading-animation>
    <div *ngIf="kpiCards==null && loaded" class="no-data"><h1>No data</h1></div>
    <div class="details-decline-section__container" *ngIf="kpiCards">
        <div class="details-decline-section__chartcontent">
            <app-loading-animation *ngIf="!declineChartDetails || !declineChartResponseDetails"></app-loading-animation>
            <app-alert-details-decline-chart-section *ngIf="declineChartDetails && declineChartResponseDetails"
                [show]="isChartOpen"
                [cardIndex]="cardIndex"
                [qCards]="qCards"
                [declineChartResponseDetails]="declineChartResponseDetails"
                [declineChartDetails]="declineChartDetails"
                [kpiCards]="kpiCards"
                ></app-alert-details-decline-chart-section>
        </div>
        <app-loading-animation  *ngIf="!kpiCards"></app-loading-animation>
        <div class="details-decline-section__cardcontent" *ngIf="kpiCards">
            <div class="cardcontent__card" *ngFor="let kpiCard of kpiCards; index as i">
                <app-alert-details-decline-card
                    (click)="onCardClick(i, kpiCard.problemResponseMessage)"
                    tooltipText="The value (or number) of all transactions which are approved, divided by the value (or number) of all transaction attempts"
                    [kpiCard]="kpiCard"
                    [position]="i"
                    [cardIndex]="cardIndex"
                    [show]="isChartOpen"
                ></app-alert-details-decline-card>
            </div>

        </div>

    </div>
</section>
