import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private urlUserData = `${environment.apiURL}/getactiveuser`;

  //ToDO (GoTo app.component.ts --> private userId = sessionStorage.getItem('userId');

  constructor(private http: HttpClient) { }

  /** GET UserData from the server */
  getUserData(): Observable<any> {
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')!
      })
    };

    var userId = sessionStorage.getItem('userId')?.replace(environment.userDataReplaceId, '').toUpperCase();

    console.log('Current userId:', userId);

    return this.http
      .get<any>(
        `${this.urlUserData}/${userId}`,
        httpOptions
      )
      .pipe(
        tap((_) => console.log('UserDataService', 'fetched UserData')),
        catchError(this.handleError<any>('getUserData', []))
      );
  }

  /**
     * Handle Http operation that failed.
     * Let the app continue.
     *
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(
        'ImpactCardService',
        `${operation} failed: ${error.message}`
      );

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
