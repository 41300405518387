import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { ImpactChartService } from 'src/app/services/impact/impact-chart.service';
import { Metric } from 'src/app/services/impact/impact';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';

@Component({
    selector: 'app-impact-chart-section',
    templateUrl: './impact-chart-section.component.html',
    styleUrls: ['./impact-chart-section.component.css'],
    animations: [
        trigger('fade', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scaleY(0)' }),
                animate(
                    '.3s ease',
                    style({ opacity: 1, transform: 'scaleY(1)' })
                ),
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'scaleY(1)' }),
                animate(
                    '.3s ease',
                    style({ opacity: 0, transform: 'scaleY(0)' })
                ),
            ]),
        ]),
    ],
})
export class ImpactChartSectionComponent implements OnInit {
    @Input() cardIndex: number = 0;
    @Input() show: boolean = false;
    @Input() metricInfo: Metric;

    //Dynamic text
    metricLabel:String;
    buttonLabels:String[] = [];

    chartType: string = 'bar';
    chartPeriod: string = '1';

    chkDif:boolean = false;

    impactChartData:JSON | any;

    navToggle = false;

    chartModType(type:string){
        //Enable diff switch
        if(type == 'difference' && this.chartType != 'difference'){
            this.chartType = 'difference';
            this.chkDif = true;
        }
        //Disable diff switch, the default chartType is "bar"
        else if(type == 'difference' && this.chartType == 'difference'){
            this.chkDif = false;
            this.chartType = 'bar';
        }
        //Any other type selected (while diff enabled)
        else if(type != 'difference' && this.chartType == 'difference'){
            this.chkDif = false;
            this.chartType = type;

        }else{
            this.chkDif = false;
            this.chartType = type;
        }
        var dfChkbox:any = document.getElementById("diffCheck")
        if(dfChkbox.checked){
            dfChkbox.checked = this.chkDif;
        }
        this.gaService.contentEventEmitter('impactChart', 'Type_'+type);

    }

    constructor(private impactChartService: ImpactChartService,
                private route: ActivatedRoute,
                private gaService: GoogleAnalyticsService) {}

    ngOnInit(): void {
        //If data already passed from parent to child (this) component
        if(this.metricInfo){
            this.loadText(this.metricInfo.pK_MetricID);
        }else{
            this.loadText(0);
        }
    }

    ngOnChanges(): void {
        this.getImpactChart();

        //If data already passed from parent to child (this) component
        if(this.metricInfo){
            //Temporary fix (was using this.metricInfo.pk_MetricID)
            this.loadText(this.getMetricType(this.metricInfo.metricName));
        }else{
            this.loadText(0);
        }

    }

    getImpactChart():void{
        var client = this.route.snapshot.paramMap.get('clientName')
        if(this.metricInfo){
            //Temporary fix -> need to change METRIC INFO to include ClientName and MetricType (1,2,3,4...) instead metric ID
            this.impactChartService.getChartApiData(client,    //this.metricInfo.fK_ClientID,
                                                    this.getMetricType(this.metricInfo.metricName) //this.metricInfo.pK_MetricID
                                                ).subscribe(data => {
                this.impactChartData = data;
            });
        }
    }

    //Todo: maybe receive label by BBDD??
    //Method for loading chartSection label data
    private loadText(metricId:any):void{

        this.buttonLabels = [];

        //Common
        if(metricId == 0){
            this.metricLabel = 'Default';
        }else{
            this.metricLabel = this.metricInfo.metricName;
        }

        //Empty (no data from parent)
        if(metricId == 0){
            this.buttonLabels.push('Last 14 days', 'Last 30 days', 'Last year');
        }
        //Gross
        if(metricId == 1){
            this.buttonLabels.push('Last 14 days', 'Last 30 days', 'Last year');
        }
        //Net
        else if(metricId == 2){
            this.buttonLabels.push('Last 14 days', 'Last 30 days', 'Last year');
        }
        //Chargeback
        else if(metricId == 3){
            this.buttonLabels.push('Last 30 days', 'Last 90 days', 'Last year');
        }
        //Fraud
        else if(metricId == 4){
            this.buttonLabels.push('Last 30 days', 'Last 90 days', 'Last year');
        }
    }

    //Temporary fix
    getMetricType(metricType:String){
        if(metricType.includes("gross approval rate")){
            return 1;
        }else if(metricType.includes("net approval rate")){
            return 2;
        }else if(metricType.includes("chargeback rate")){
            return 3;
        }else if(metricType.includes("fraud rate")){
            return 4;
        }else return 0;

    }

    toggleOptions(){
        this.navToggle = !this.navToggle;
    }

    setChartPeriod(id:string){
        this.chartPeriod = id;
        this.gaService.contentEventEmitter('impactChart', 'Period_'+id);
    }

}
