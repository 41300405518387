import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-dropdown-button',
    templateUrl: './dropdown-button.component.html',
    styleUrls: ['./dropdown-button.component.css'],
})
export class DropdownButtonComponent implements OnInit {
    @Input() prefixedText: string = 'Status:';
    @Input() dropdownId: string = '';
    @Input() values: string[] = ['All', 'Negative', 'Positive'];
    @ViewChild('dropdownCheckbox') dropdownCheckbox: any;
    @Output() currentValue = new EventEmitter<string>();
    selectedValue: string = '';
    dropdownText: string = '';

    curValues: string[] = [];
    @Output() currentValues = new EventEmitter<string[]>();

    constructor() {}

    ngOnInit(): void {
        this.curValues.push(this.values[0]);
        this.selectedValue = this.values[0];
        this.dropdownText = this.prefixedText + ' ' + this.values[0];
    }

    handleValueClick(value: string): void {
        this.selectedValue = value;
        this.dropdownText = this.prefixedText + ' ' + value;

        this.dropdownCheckbox.nativeElement.checked = false;
        this.updateCurrentSelected(value);
    }

    handleSelectClick(value: string): void{
        if(value !== this.values[0]){
            if(this.curValues.includes(this.values[0])){
                var i = this.curValues.indexOf(this.values[0]);
                this.curValues.splice(i, 1);
            }
            if(this.curValues.includes(value)){
                var i = this.curValues.indexOf(value);
                this.curValues.splice(i, 1);
            }else{
                this.curValues.push(value);
            }

        }else{
            this.curValues = [this.values[0]];
        }

        if(this.curValues.length > 1){
            this.dropdownText = this.prefixedText + ' ' + this.curValues.length + ' filters';
        }else{
            this.dropdownText = this.prefixedText + ' ' + value;
        }

        console.log(this.curValues);

        this.dropdownCheckbox.nativeElement.checked = false;
        this.currentValues.emit(this.curValues);

    }

    updateCurrentSelected(value:string){
        this.currentValue.emit(value);
    }

}
