<div class="chart-detail">
    <div class="chart-detail__container">
        <canvas class="chart-detail__canvas" id="chart-canvas"></canvas>
    </div>
    <div class="chart-legend">
        <div *ngIf="!showLegend" class="chart-legend__labels">
            <div [style]="'--legend-color: ' + '#00AAB9'">
                Your responses
            </div>
            <div [style]="'--legend-color: ' + '#004F5F'">
                Transaction profile responses
            </div>
        </div>
        <div *ngIf="showLegend" class="chart-legend__labels">
            <div *ngFor="let legend of legendData" [style]="{'--legend-color': legend[1]}">
                {{legend[0]}}
            </div>
        </div>
    </div>
</div>
