import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { textInformation } from 'src/app/services/alert-details/alert-detail';
import { MediaQueryControlService } from 'src/app/shared/services/media-query-control.service';
import { ModalService } from '../modal/modal.service';
import { SharingEmailService } from 'src/app/services/sharing/sharing-email.service';
import { BodyEmail } from 'src/app/services/sharing/sharing-email';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-nav-alert',
  templateUrl: './nav-alert.component.html',
  styleUrls: ['./nav-alert.component.css']
})
export class NavAlertComponent implements OnInit {
    @Input() textInformation:textInformation

    imageSRC: string
    email: string;
    responseEmail : string;
    sharingError : string|any = 'Empty';
    modalOpened: boolean = false;
    placeholderText:string='Insert email address here.';

  constructor(private location:Location,
              private router:Router,
              public mediaQueryControl: MediaQueryControlService,
              private modalService: ModalService,
              private sharingService: SharingEmailService,
              private route: ActivatedRoute,
              private gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
      if(this.textInformation.categoryType == 1){
        this.imageSRC = 'assets/logos/Issuers/'+ this.textInformation.categoryName + ".png"
      } else if(this.textInformation.categoryType == 2){
        this.imageSRC = 'assets/logos/Networks/'+ this.textInformation.categoryName + ".png"
      } else if(this.textInformation.categoryType == 3){
        this.imageSRC = 'assets/logos/Currencies/'+ this.textInformation.categoryName + ".png"
      } else if(this.textInformation.categoryType == 4){
        this.imageSRC = 'assets/logos/CardTypes/'+ this.textInformation.categoryName + ".png"
      }


       //else if (this.textInformation.category.split(',')[0].length > 1){
        //this.imageSRC = 'assets/logos/Issuers/'+ this.textInformation.category.split(',')[0] + ".png"
     // }

      this.email = '';
  }

  goBack() {
      this.router.navigate([this.route.snapshot.paramMap.get('clientName') + '/overview'])
  }

  openModal(id: string) {
    this.modalService.open(id);
    var section = '';
    var routeId:string = <string>this.route.snapshot.paramMap.get('id');
    if(this.router.url.includes("alert")){
      var pref = "DetailsID_";
      var alertId = parseInt(routeId);
      section += pref+'_'+alertId;
    }
    this.gaService.contentEventEmitter('navAlertShare', section);
  }

  closeModal(id: string) {
      this.modalService.close(id);
      if(id == 'shared-response'){
          this.responseEmail = '';
      }else if(id == 'shared-error'){
          this.sharingError = '';
      }


  }

  sendModal(id: string){
      if(!this.isEmail(this.email)){
          this.email = '';
          this.modalService.close(id);
          this.sharingError = "Not valid email address";
          this.modalService.open('shared-error');
          return;
      }

      var bodyEmail:BodyEmail;
      var section:string = "";
      var clientName:string = this.route.snapshot.paramMap.get('clientName') || ''

      var userB2C:string|any = sessionStorage.getItem('userId')?.replace(environment.userDataReplaceId, '').toUpperCase();
      var alertId:any = this.route.snapshot.paramMap.get('id');
      if(this.router.url == "/overview"){
          section = "overview";
      }else if(this.router.url == "/notifications"){
          section = "notifications";
      }else if(this.router.url.includes("alert")){
        section = "alert";
        alertId = parseInt(alertId)
      }
      bodyEmail={
          "Url": environment.frontendRedirectURL,
          "DestinationEmail": this.email,
          "Section": section,
          "ClientName": clientName,
          "UserB2CObjectId": userB2C,
          "AlertId": alertId,
      }
      this.shareMail(bodyEmail);
      this.modalService.close(id);
      this.responseEmail = this.email;
      this.email = "";

  }

  shareMail(bodyEmail:BodyEmail){
      this.sharingService.shareEmail(bodyEmail).subscribe(data => {
          if(data.emailSended){
              this.responseEmail = data.destinationEmail;
              this.openModal("shared-response");
          }else{
              this.responseEmail = data.destinationEmail;
              if(data.emailSendError){
                  this.sharingError = data.emailSendError;
              }else{
                  this.sharingError = 'Unknown';
              }
              this.openModal("shared-error");
          }
      })
  }

  isEmail(search:string):boolean{
      var searchFind:boolean;
      var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      searchFind = regexp.test(search);
      return searchFind;
  }

}
