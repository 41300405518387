import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-details-back-to-top-buttom',
  templateUrl: './alert-details-back-to-top-buttom.component.html',
  styleUrls: ['./alert-details-back-to-top-buttom.component.css']
})
export class AlertDetailsBackToTopButtomComponent implements OnInit {
    pageYoffset = 0;
    @HostListener('window:scroll', ['$event']) onScroll(event:any){
      this.pageYoffset = window.pageYOffset;
    }

      constructor(private scroll: ViewportScroller) { }

  ngOnInit(): void {
  }
  scrollToTop(){
    this.scroll.scrollToPosition([0,0]);
  }

}
