<div class="list-header" *ngIf="(mediaQueryControl.layout | async) != 'mobile'">
    <div class="type" (click)="sort('type')">
          <span>Type</span>
          <img *ngIf="sortParam.SortType != 'type'"
              [ngClass]="{ 'sort-default':sortParam.SortType != 'type'}"
              src="assets/icons/sort.svg" 
              alt="sort icon" />
         <img  *ngIf="sortParam.SortType == 'type'"
               [ngClass]="{ 'sort-up':(sortParam.SortOrder == 'ASC'),
                            'sort-down':(sortParam.SortOrder == 'DESC') }"
               src="assets/icons/sort-select.svg" 
               alt="sort select icon"
               
          />
    </div>
    <div class="category" (click)="sort('category')">
        <span>Category</span>
        <img *ngIf="sortParam.SortType != 'category'"
              [ngClass]="{ 'sort-default':sortParam.SortType != 'category'}"
              src="assets/icons/sort.svg" 
              alt="sort icon" />
         <img  *ngIf="sortParam.SortType == 'category'"
               [ngClass]="{ 'sort-up':(sortParam.SortOrder == 'ASC'),
                            'sort-down':(sortParam.SortOrder == 'DESC') }"
               src="assets/icons/sort-select.svg" 
               alt="sort select icon"
               
          />
    </div>
    <div class="date" (click)="sort('date')">
        <span>Date</span>
        <img *ngIf="sortParam.SortType != 'date'"
              [ngClass]="{ 'sort-default':sortParam.SortType != 'date'}"
              src="assets/icons/sort.svg" 
              alt="sort icon" />
         <img  *ngIf="sortParam.SortType == 'date'"
               [ngClass]="{ 'sort-up':(sortParam.SortOrder == 'ASC'),
                            'sort-down':(sortParam.SortOrder == 'DESC') }"
               src="assets/icons/sort-select.svg" 
               alt="sort select icon"
               
          />
    </div>
    <div class="monthly-impact" (click)="sort('monthly impact')">
        <span>Monthly impact</span>
        <img *ngIf="sortParam.SortType != 'monthly impact'"
              class='sort-default'
              src="assets/icons/sort.svg" 
              alt="sort icon" />
         <img  *ngIf="sortParam.SortType == 'monthly impact'"
               [ngClass]="{ 'sort-up':(sortParam.SortOrder == 'ASC'),
                            'sort-down':(sortParam.SortOrder == 'DESC') }"
               src="assets/icons/sort-select.svg" 
               alt="sort select icon"
               
          />
    </div>
    <div class="vs-cluster" (click)="sort('vs cluster')">
        <span>VS Cluster</span>
        <img *ngIf="sortParam.SortType != 'vs cluster'"
              class='sort-default'
              src="assets/icons/sort.svg" 
              alt="sort icon" />
         <img  *ngIf="sortParam.SortType == 'vs cluster'"
               [ngClass]="{ 'sort-up':(sortParam.SortOrder == 'ASC'),
                            'sort-down':(sortParam.SortOrder == 'DESC') }"
               src="assets/icons/sort-select.svg" 
               alt="sort select icon"
               
          />
    </div>
</div>

<ul class="list-body">
    <app-loading-animation *ngIf="!alerts"></app-loading-animation>
    <div>
        <li
            app-alert-item
            class="pinned"
            *ngFor="let alert of pinnedAlerts"
            [alert]="alert"
            (pinClick)="unpinAlert(alert)"
        ></li>
    </div>
    <div class="list-body__separator" *ngIf="pinnedAlerts.length > 0"></div>
    <div>
        <li

            app-alert-item
            *ngFor="let alert of alerts | paginate:
                    { id: 'paginator', itemsPerPage: itemsPage, currentPage: page, totalItems: totalItems }"
            [alert]="alert"
            (pinClick)="pinAlert(alert)"
            [ngClass]="{'ispinned': alert.pinned}"
        ></li>
        <div class="list-body__pagination__controls" *ngIf="router.url.includes('/notifications')">
            <pagination-controls class="list-pagination__controls"
                                 (pageChange)="getAlerts($event)"
                                 previousLabel=""
                                 nextLabel=""
                                 id="paginator">
            </pagination-controls>
        </div>

    </div>
</ul>
