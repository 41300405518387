<div
    *ngIf="open"
    class="overlay"
    [@overlayState]
    (click)="handleCloseDrawer()"
></div>
<div *ngIf="open" class="drawer" [@drawerState]>
    <div class="drawer__header flex-center">
        <span (click)="handleCloseDrawer()">
            <svg width="20" height="16" viewBox="0 0 20 16">
                <path
                    d="M20 6.80562H4.24551L9.05043 1.6723L7.48546 0L0 8L7.48546 16L9.05043 14.3277L4.24551 9.19438H20V6.80562Z"
                    fill="#0C002E"
                />
            </svg>
        </span>
    </div>
    <div class="drawer__body">
        <h4>{{ title }}</h4>
        <section>
            <ng-content></ng-content>
        </section>
    </div>
</div>
