import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FiltersDataService {

  private filtersUrl = `${environment.apiURL}/getfiltersdata`;

  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')!
      }
    )
  }

  constructor(
    private http: HttpClient
  ) { }

  getFilters(currentClient:string) {
    return this.http.get<any>(`${this.filtersUrl}/${currentClient}`, this.httpOptions)
      .pipe(
        tap(_ => console.log('FiltersService', 'Filters list')),
        catchError(this.handleError<any>('getFilters', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a FilterService message with the MessageService */
  private log(message: string) {
    console.log(`FilterService: ${message}`);
  }


}
