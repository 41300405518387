import {
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import Chart from 'chart.js/auto';
import { ImpactChartService } from 'src/app/services/impact/impact-chart.service';
import { barConf, lineConf, mixedConf, diffConf } from './chart-conf';

@Component({
    selector: 'app-impact-chart',
    templateUrl: './impact-chart.component.html',
    styleUrls: ['./impact-chart.component.css'],
})
export class ImpactChartComponent
    implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
    @Input() chartType: string = 'bar';
    @Input() chartMetricName: string;
    @Input() chartPeriod: string = '1';
    @Input() chartData: JSON | any;
    myChart: Chart | any | undefined;

    //API Data parms
    data0: number[];   //client
    data1: number[]; //transaction profile
    dataD: number [] //difference
    labs: String[]; //graphLabels

    chartLegendLabel:string
    chartLegendNumberOfAlerts:number
    chartLegendTotalImpact:number

    constructor(private impactChartService: ImpactChartService) {}

    ngAfterViewInit(): void {
        const config = { ...barConf };
        this.myChart = new Chart('chart-canvas', config);
        this.updateChartData();
    }

    ngOnDestroy(): void {
        this.myChart?.destroy();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['chartData']){
            this.setConfig(this.chartType);
            this.updateChartData();
        }else{
            if (changes['chartType']) {
                this.setConfig(this.chartType);
            }
            if(changes['chartPeriod']){
                this.updateChartData();
            }
        }
    }

    ngOnInit(): void {

    }

    updateChartData(){
        //MOCK DATA
        //var tmpServiceData = this.impactChartService.getImpactChartData(this.chartPeriod);
        //this.data0 = tmpServiceData.datA;
        //this.data1 = tmpServiceData.datB;
        //this.labs = tmpServiceData.labels;
        switch(this.chartPeriod){
            //14 Days - 30 Days
            case '1':   this.data0 = this.chartData[0].clientData;
                        this.data1 = this.chartData[0].profileData;
                        this.dataD = this.chartData[0].diffData;
                        this.labs = this.chartData[0].metricDateLabel;
                        this.chartLegendNumberOfAlerts=this.chartData[0].total
                        this.chartLegendTotalImpact= this.chartData[0].impactValueData.reduce((partialSum:number, a:number) => partialSum + a, 0);
                        this.setLabelConfig("1");
                        break;
            //30 Days - 90 Days
            case '2':   this.data0 = this.chartData[1].clientData;
                        this.data1 = this.chartData[1].profileData;
                        this.dataD = this.chartData[1].diffData;
                        this.labs = this.chartData[1].metricDateLabel;
                        this.chartLegendNumberOfAlerts=this.chartData[1].total
                        this.chartLegendTotalImpact= this.chartData[1].impactValueData.reduce((partialSum:number, a:number) => partialSum + a, 0);
                        this.setLabelConfig("2")
                        break;
            //Last year
            case '3':   this.data0 = this.chartData[2].clientData;
                        this.data1 = this.chartData[2].profileData;
                        this.dataD = this.chartData[2].diffData;
                        this.labs = this.chartData[2].metricDateLabel;
                        this.chartLegendNumberOfAlerts=this.chartData[2].total
                        this.chartLegendTotalImpact= this.chartData[2].impactValueData.reduce((partialSum:number, a:number) => partialSum + a, 0);
                        this.setLabelConfig("3");
                        break;
        }
        this.setConfig(this.chartType);
    }

    setConfig(chartType: string): void {
        if (this.myChart) {
            switch (chartType) {
                case 'line':
                    lineConf.data.datasets[0].data = this.data0;
                    lineConf.data.datasets[1].data = this.data1;
                    lineConf.options.scales.x.labels = this.labs;
                    lineConf.options.scales.y.title.text = this.chartMetricName;
                    var max1 = Math.max.apply(null, this.data0);
                    var max2 = Math.max.apply(null, this.data1);
                    var max = Math.max(max1, max2);
                    var min1 = Math.min.apply(null, this.data0);
                    var min2 = Math.min.apply(null, this.data1);
                    var min = Math.min(min1, min2);
                    lineConf.options.scales.y.max = max*1.05 > 100 ? 100 : max*1.05;
                    lineConf.options.scales.y.min = Math.round(min*0.95);
                    this.myChart.config.type = lineConf.type;
                    this.myChart.config.data = lineConf.data;
                    this.myChart.config.options = lineConf.options;
                    break;
                case 'bar':
                    barConf.data.datasets[0].data = this.data0;
                    barConf.data.datasets[1].data = this.data1;
                    barConf.options.scales.x.labels = this.labs;
                    barConf.options.scales.y.title.text = this.chartMetricName;
                    var max1 = Math.max.apply(null, this.data0);
                    var max2 = Math.max.apply(null, this.data1);
                    var max = Math.max(max1, max2);
                    var min1 = Math.min.apply(null, this.data0);
                    var min2 = Math.min.apply(null, this.data1);
                    var min = Math.min(min1, min2);
                    barConf.options.scales.y.max = max*1.05 > 100 ? 100 : max*1.05;
                    barConf.options.scales.y.min = Math.round(min*0.95);
                    this.myChart.config.type = barConf.type;
                    this.myChart.config.data = barConf.data;
                    this.myChart.config.options = barConf.options;
                    break;
                case 'mixed':
                    mixedConf.data.datasets[0].data = this.data0;
                    mixedConf.data.datasets[1].data = this.data1;
                    mixedConf.options.scales.x.labels = this.labs;
                    mixedConf.options.scales.y.title.text = this.chartMetricName;
                    var max1 = Math.max.apply(null, this.data0);
                    var max2 = Math.max.apply(null, this.data1);
                    var max = Math.max(max1, max2);
                    var min1 = Math.min.apply(null, this.data0);
                    var min2 = Math.min.apply(null, this.data1);
                    var min = Math.min(min1, min2);
                    mixedConf.options.scales.y.max = max*1.05 > 100 ? 100 : max*1.05;
                    mixedConf.options.scales.y.min = Math.round(min*0.95);
                    this.myChart.config.type = mixedConf.type;
                    this.myChart.config.data = mixedConf.data;
                    this.myChart.config.options = mixedConf.options;
                    break;
                case 'difference':
                    this.showDifference();
                    this.myChart.config.type = diffConf.type;
                    this.myChart.config.data = diffConf.data;
                    this.myChart.config.options = diffConf.options;
                    break;
                default:
                    barConf.data.datasets[0].data = this.data0;
                    barConf.data.datasets[1].data = this.data1;
                    barConf.options.scales.x.labels = this.labs;
                    barConf.options.scales.y.title.text = this.chartMetricName;
                    var max1 = Math.max.apply(null, this.data0);
                    var max2 = Math.max.apply(null, this.data1);
                    var max = Math.max(max1, max2);
                    var min1 = Math.min.apply(null, this.data0);
                    var min2 = Math.min.apply(null, this.data1);
                    var min = Math.min(min1, min2);
                    barConf.options.scales.y.max = max*1.05 > 100 ? 100 : max*1.05;
                    barConf.options.scales.y.min = Math.round(min*0.95);
                    this.myChart.config.type = barConf.type;
                    this.myChart.config.data = barConf.data;
                    this.myChart.config.options = barConf.options;
            }
            this.myChart.update();
        }
    }

    showDifference(){
        var diffdata = this.dataD;
        var diffprint:any[] = [];
        var backgroundColor: any[] = [];

        for(let i = 0; i < diffdata.length; i++){
            if(diffdata[i] > 0){
                backgroundColor.push("#00a700")
                diffprint.push(Math.abs(diffdata[i]))
            }else{
                backgroundColor.push("#ec4a36")
                //diffdata[i] = Math.abs(diffdata[i]);
                diffprint.push(Math.abs(diffdata[i]));
            }
        }
        var min = Math.min.apply(null, diffprint);
        var max = Math.max.apply(null, diffprint);
        if(max < min){
            diffConf.options.scales.y.min = Math.round(max);
            diffConf.options.scales.y.max = Math.floor(min);
            
            
        }else{
            diffConf.options.scales.y.min = Math.floor(min);
            diffConf.options.scales.y.max = Math.round(max);
        }
        diffConf.options.scales.x.labels = this.labs;
        diffConf.options.scales.y.title.text = this.chartMetricName;
        diffConf.data.datasets[0].data = diffprint;
        diffConf.data.datasets[0].backgroundColor = backgroundColor;

    }

    setLabelConfig(cPer:string){
            if(this.chartPeriod == "1"){
                if(this.chartData[cPer].type == 1 ||  this.chartData[cPer].type == 2){
                    if(this.myChart){
                        this.myChart.config.options.scales.x.title.text = "Last 14 Days";
                        this.chartLegendLabel="14 Days Impact";
                    }
                }else if( this.chartData[cPer].type == 3 ||  this.chartData[cPer].type == 4){
                    this.myChart.config.options.scales.x.title.text = "Last 30 Days";
                    this.chartLegendLabel="30 Days Impact";
                }
            }else if(this.chartPeriod == "2"){
                if(this.chartData[cPer].type == 1 ||  this.chartData[cPer].type == 2){
                    if(this.myChart) {
                        this.myChart.config.options.scales.x.title.text = "Last 30 Days";
                        this.chartLegendLabel="30 Days Impact";
                    }
                }else if( this.chartData[cPer].type == 3 ||  this.chartData[cPer].type == 4){
                    if(this.myChart){
                        this.myChart.config.options.scales.x.title.text = "Last 90 Days";
                        this.chartLegendLabel="90 Days Impact";

                    }
                }
            }else if(this.chartPeriod == "3"){
                if(this.myChart) this.myChart.config.options.scales.x.title.text = "Last year";
                this.chartLegendLabel="12 Month Impact";
            }

    }

}
