<div
    [@fade]
    class="chart-section"
    [ngClass]="{'active': show }"
    [style]="'--card-selector:' + cardIndex*(100/qCards)"
>
    <div class="chart-section__nav flex-center space-between">
        <div class="chart-section__nav__title">
            {{navTitle}}
        </div>
        <div class="chart-section__nav__buttons_container">
            <div class="chart-section__nav__toggle-slider" >
                <label class="chart-section__nav__toggle-control" >
                    <input
                        id ="diffCheck"
                        type="checkbox"
                        (change)="fieldsChange($event)"
                        />
                    <span *ngIf="!show"></span>
                </label>
            </div>
            <div class="chart-section__nav__toggle-label" *ngIf="!show">Absolute split (Last 4 weeks)</div>
            <div class="chart-section__nav__toggle-label"></div>
            <div class="chart-section__nav__options">
                <svg width="20" height="16" viewBox="0 0 14 12">
                    <path
                        d="M1.03542 2.05132H12.964C13.4682 2.05132 13.8598 1.65977
                           13.8598 1.15554C13.8598 0.651318 13.4682 0.259766 12.964
                           0.259766H1.03542C0.5312 0.259766 0.139648 0.651318 0.139648
                           1.15554C0.139648 1.65977 0.5312 2.05132 1.03542 2.05132Z"
                        fill="black"
                    />
                    <path
                        d="M1.03542 6.89604H12.964C13.4682 6.89604 13.8598 6.50449
                           13.8598 6.00027C13.8598 5.49605 13.4682 5.10449 12.964
                           5.10449H1.03542C0.5312 5.10449 0.139648 5.49605 0.139648
                           6.00027C0.139648 6.50449 0.5312 6.89604 1.03542 6.89604Z"
                        fill="black"
                    />
                    <path
                        d="M12.964 11.7398C13.4682 11.7398 13.8598 11.3482 13.8598
                            10.844C13.8598 10.3398 13.4682 9.94824 12.964 9.94824H1.03542C0.5312
                            9.94824 0.139648 10.3398 0.139648 10.844C0.139648
                            11.3482 0.5312 11.7398 1.03542 11.7398H12.964Z"
                        fill="black"
                    />
                </svg>
            </div>
        </div>
    </div>
    <div class="chart-section__chart">
        <app-alert-details-decline-chart [show]="show"
         [chartType]="chartType"
         [isSlider]="isSlider"
         [cardIndex]="cardIndex"
         [declineChartResponseDetails]="declineChartResponseDetails"
        [declineChartDetails]="declineChartDetails"
        [kpiCards]="kpiCards"
         ></app-alert-details-decline-chart>
    </div>
</div>
