import { Injectable } from '@angular/core';

declare let gtag:Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() {
  }

  public eventEmitter( eventAction: string, 
                        eventName: string, 
                       eventCategory: string|null, 
                       
                       eventLabel: string|null,  
                       eventValue: number|null ) { 
         gtag('event', 
               eventName, 
               { 
                    eventCategory: eventCategory, 
                    eventLabel: eventLabel, 
                    eventAction: eventAction, 
                    eventValue: eventValue
               }
              )
  }

  public contentEventEmitter(contentType:string, itemId:string){
      gtag("event", "select_content", {
        content_type: contentType,
        item_id: itemId
      });
  }

}
